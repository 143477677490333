import React, { createContext, ReactNode, useRef, useCallback } from 'react';
import { ViewerPosition } from '../types';

// Define the context value type
type RealtimePositionContextType = {
  registerCallback: (callback: (position: ViewerPosition) => void) => void;
  unregisterCallback: (callback: (position: ViewerPosition) => void) => void;
  updateRealtimePosition: (position: ViewerPosition) => void;
};

// Create context with a default value of null
export const RealtimePositionContext = createContext<RealtimePositionContextType | undefined>(
  undefined
);

// Provider component to manage shared state
export const RealtimePositionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const callbacksRef = useRef<Set<(position: ViewerPosition) => void>>(new Set());

  // Function to register a callback
  const registerCallback = useCallback((callback: (position: ViewerPosition) => void) => {
    callbacksRef.current.add(callback);
  }, []);

  // Function to unregister a callback
  const unregisterCallback = useCallback((callback: (position: ViewerPosition) => void) => {
    callbacksRef.current.delete(callback);
  }, []);

  // Function to update the viewer position and call all registered callbacks
  const updateRealtimePosition = useCallback((position: ViewerPosition) => {
    callbacksRef.current.forEach(callback => callback(position));
  }, []);

  return (
    <RealtimePositionContext.Provider
      value={{ registerCallback, unregisterCallback, updateRealtimePosition }}>
      {children}
    </RealtimePositionContext.Provider>
  );
};
