import { useCallback, useRef } from 'react';
import { ViewerPosition } from '../types';
import { areViewerPositionsAlmostEqual, normalizeViewerPosition } from '../utils';

export const useKnownPosition = (
  onLookAt?: (viewerPosition: ViewerPosition) => void,
  onUpdate?: (viewerPosition: ViewerPosition) => void,
  logCalls: boolean = false
) => {
  const knownPosition = useRef<ViewerPosition>({ yaw: 0, pitch: 0, hfov: 120 });

  const lookAtCurrentPosition = useCallback(() => {
    // TODO: consider whether we ignore this if mouse is down
    logCalls && console.log('looking at', knownPosition.current.yaw);
    onLookAt && onLookAt(knownPosition.current);
  }, [logCalls, onLookAt]);

  const propagateCurrentPosition = useCallback(() => {
    const startTime = performance.now();
    logCalls && console.log('propagating', knownPosition.current.yaw);
    onUpdate && onUpdate(knownPosition.current);
    const endTime = performance.now();
    const executionTime = endTime - startTime;
    if (executionTime > 100) {
      console.warn(`Warning: onUpdate took more than 100ms to execute.`, executionTime);
    }
  }, [logCalls, onUpdate]);

  const updateKnownPosition = useCallback(
    (newPosition: ViewerPosition, propagate: boolean = true, lookAt: boolean = true) => {
      const normalized: ViewerPosition = normalizeViewerPosition(newPosition);
      if (!areViewerPositionsAlmostEqual(normalized, knownPosition.current)) {
        // IMPORTANT: This should be the only allowed place to update know position
        logCalls && console.log('updated known position', normalized, knownPosition.current);
        knownPosition.current = normalized;
        if (lookAt) lookAtCurrentPosition();
        if (propagate) propagateCurrentPosition();
      }
    },
    [logCalls, lookAtCurrentPosition, propagateCurrentPosition]
  );

  return { knownPosition, updateKnownPosition };
};
