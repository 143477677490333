import Flex from '@react-css/flex';
import React from 'react';
import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { Icon } from '../../../../common/Icon';
import progressCompleteIcon from '../../../../../assets/images/progress_complete_check.svg';
import styled from 'styled-components';

interface ProgressCellProps {
  progress: number;
  total: number | undefined;
  rate: number | undefined;
  units: any | undefined;
  showRate?: boolean;
}
export const ProgressCell = ({
  progress,
  total,
  rate,
  units,
  showRate = true,
}: ProgressCellProps) => {
  if (!total) {
    return <ProgressPrintout>N/A</ProgressPrintout>;
  }

  return (
    <div style={{ minWidth: '150px' }}>
      <Flex>
        <ResponsiveContainer width="90%" height={10}>
          <BarChart
            barSize={4}
            data={[{ value: (progress / total) * 100, incomplete: 100 - (progress / total) * 100 }]}
            layout="vertical">
            <XAxis type="number" hide xAxisId={0} domain={[0, 100]} />
            <YAxis
              yAxisId={0}
              width={0}
              dataKey="name"
              type="category"
              axisLine={false}
              tickLine={false}
            />
            <Bar
              dataKey="value"
              fill={progress === total ? '#10B981' : '#1E40AF'}
              radius={8}
              background={{
                radius: 8,
              }}
            />
          </BarChart>
        </ResponsiveContainer>
        <div style={{ color: progress !== 0 ? '#1E40AF' : '#94A3B8', fontWeight: 'bold' }}>
          {progress === total ? (
            <Icon icon={progressCompleteIcon} size={18} />
          ) : (
            Math.floor((progress / total) * 100) + '%'
          )}
        </div>
      </Flex>
      {showRate && progress !== total && progress !== 0 && !!units() && (
        <ProgressPrintout>
          {rate}
          {units()}
        </ProgressPrintout>
      )}
      {showRate && progress === 0 && <ProgressPrintout>N/A</ProgressPrintout>}
      {progress === total && showRate && (
        <ProgressPrintout style={{ padding: '0 5px' }}>Complete</ProgressPrintout>
      )}
    </div>
  );
};

const ProgressPrintout = styled.div`
  color: #94a3b8;
  font-size: 0.8em;
  padding: 5px;
`;
