import styled from 'styled-components';

export const UserListContainer = styled.div`
  padding: 10px;
  position: relative;
  height: 100%;
`;

export const UserListScrollContainer = styled.div`
  overflow-y: scroll;
  max-height: 200px;
`;

export const UserEmail = styled.span`
  font-size: 0.75em;
  color: #999;
`;

export const SearchBar = styled.input`
  margin-bottom: 1em;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  background: #e8e8e8;
  border: none;
  padding: 5px;
`;

export const CreateButtonContainer = styled.div`
  width: 100%;
  background: #fff;
`;

export const CreateButton = styled.div`
  background-color: #073c7a;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 5px 12px;
  width: auto;
  margin: 10px 0;
  font-size: 0.9em;
`;

export const ChatMenuItem = styled.li`
  font-size: 0.8em;
  cursor: pointer;
  border-bottom: 1px solid transparent;

  &:hover {
    border-bottom: 1px solid #000;
  }
`;

export const InputContainer = styled.div`
  padding: 5px;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

export const ChatInputForm = styled.form`
  width: 95%;
`;

export const ChatInputField = styled.input`
  width: 100%;
  font-size: 14px;
  border: none;
  background: #e8e8e8;
  padding: 7px;
  border-radius: 2px;
`;
