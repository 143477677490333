import { Observation } from "../../../../../api/observations";
import { ProjectTrackerMetricSummary, FireExtinguisherViewpointDistanceSummary, GuardrailObservationSummary, LightingMetricSummary, SafetyMetricSlug } from "../../../../../api/safety";
import { ClutterObservationTable } from "./ClutterObservationTable";
import { FireExtinguishersObservationTable } from "./FireExtinguishersObservationTable";
import { GalleryObservationTable } from "./GalleryObservationTable";
import { GuardrailsObservationTable } from "./GuardrailsObservationTable";
import { LightingObservationTable } from "./LightingObservationTable";
import { WetSurfaceObservationTable } from "./WetSurfaceObservationTable";

interface BaseObservationTableProps {
  selectedMetricCategorySlug: SafetyMetricSlug;
  data: any;
  loadMoreObservations?: () => Promise<any>;
  showMoreObservationsButtonVisible?: boolean;
}

interface PPETable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "ppe_compliance";
  data: Observation[];
  loadMoreObservations: () => Promise<void>;
  showMoreObservationsButtonVisible: boolean;
}

interface HolesTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "holes";
  data: Observation[];
  loadMoreObservations: () => Promise<void>;
  showMoreObservationsButtonVisible: boolean;
}

interface LightingTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "lighting";
  data: LightingMetricSummary;
}

interface ClutterTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "clutter";
  data: ProjectTrackerMetricSummary;
}

interface WetSurfaceTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "wet_surface";
  data: ProjectTrackerMetricSummary;
}

interface GuardrailsTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "guardrails";
  data: GuardrailObservationSummary;
}

interface FireExtinguishersTable extends BaseObservationTableProps {
  selectedMetricCategorySlug: "fire_extinguishers";
  data: FireExtinguisherViewpointDistanceSummary;
}

type ObservationTableProps = PPETable | HolesTable | LightingTable | ClutterTable | WetSurfaceTable | GuardrailsTable | FireExtinguishersTable;

export const ObservationTable = ({
  selectedMetricCategorySlug,
  data,
  loadMoreObservations,
  showMoreObservationsButtonVisible=false,
}: ObservationTableProps) => {
  return (
    <>
      {(selectedMetricCategorySlug === "ppe_compliance" || selectedMetricCategorySlug === "holes") &&
        <GalleryObservationTable
          loadMoreObservations={loadMoreObservations}
          showMoreObservationsButtonVisible={showMoreObservationsButtonVisible}
        />
      }
      {selectedMetricCategorySlug === "lighting" &&
        <LightingObservationTable
          data={data as LightingMetricSummary}
        />
      }
      {selectedMetricCategorySlug === "clutter" &&
        <ClutterObservationTable
          data={data as ProjectTrackerMetricSummary}
        />
      }
      {selectedMetricCategorySlug === 'wet_surface' &&
        <WetSurfaceObservationTable
          data={data as ProjectTrackerMetricSummary}
        />
      }

      {selectedMetricCategorySlug === "guardrails" &&
        <GuardrailsObservationTable
          data={data as GuardrailObservationSummary}
        />
      }
      {selectedMetricCategorySlug === "fire_extinguishers" &&
        <FireExtinguishersObservationTable
          data={data as FireExtinguisherViewpointDistanceSummary}
        />
      }
    </>
  );
}