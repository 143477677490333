import { useState } from "react"
import { Route, Switch } from "react-router-dom"
import { useBuildingContext } from "../../../../../contexts/buildingContext"
import { PATH_STRINGS } from "../../../../../hooks/useGeneratedPaths"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { LoadingIndicator } from "../../../../common/LoadingIndicator"
import { ViewSelector } from "../../../../common/ViewSelector/ViewSelector"
import { BuildingOverview } from "../../../building_page/components/BuildingOverview/BuildingOverview"
import { DeviceSelector } from "./DeviceSelector"
import { IEquipment, ManageEquipmentFloorPlanView } from "./ManageEquipmentFloorPlanView"
import styled from "styled-components"

interface IManageEquipmentProps {
  hoverFloor: string | null;
  setHoverFloor: (floorCode: string | null) => void;
  floorLoading: boolean;
}

export const ManageEquipment = ({
  hoverFloor,
  setHoverFloor,
  floorLoading,
}: IManageEquipmentProps) => {
  const { 
    navigateToManageEquipmentFloor,
   } = useNavigation();

   const {
     state: buildingState
   } = useBuildingContext();

   const [devicesExpanded, setDevicesExpanded] = useState<boolean>(true);

  const onClickBuildingOverviewFloorManageEquipment = (floor: string) => {
    navigateToManageEquipmentFloor(floor);
  }

  const allBuildingEquipment = buildingState.projectData.floors.flatMap((floor: any) => {
    return floor.equipment
  });

  const displayStatusAndFloor = (item: IEquipment) => {
    return (
      <>
        <EquipmentFloorDisplay>{`at ${item.project_floor_name ?? ''}`}</EquipmentFloorDisplay>
        <EquipmentStatusDisplay>{item.status}</EquipmentStatusDisplay>
      </>
    );
  }

  return (
    <Switch>
      <Route
        exact
        path={PATH_STRINGS.adminManageEquipment}
      >
        <BuildingOverview
          hoverFloor={hoverFloor}
          setHoverFloor={setHoverFloor}
          onFloorClicked={onClickBuildingOverviewFloorManageEquipment}
        />
        <div style={{ position: 'absolute', right: 0, top: 0, display: 'flex', flexDirection: 'column' }}>
          <ViewSelector
            title='Devices'
            initialViewSelectorExpanded
            viewingExpanded={devicesExpanded}
            setViewingExpanded={setDevicesExpanded}
          >
            <DeviceSelector
              hideCheckbox
              equipment={allBuildingEquipment}
              generateFarText={displayStatusAndFloor}
            />
          </ViewSelector>
        </div>
      </Route>   
      <Route
        path={PATH_STRINGS.adminManageEquipmentFloor}
      >
        { floorLoading &&
          <LoadingIndicator/>
        }
        { !floorLoading &&
          <ManageEquipmentFloorPlanView/>
        }
      </Route>
    </Switch>
  )
}

const EquipmentFloorDisplay = styled.span`
  font-size: 8px;
  color: #7E858E;
  margin-left: 8px;
`

const EquipmentStatusDisplay = styled.span`
  font-weight: 600;
  position: absolute;
  right: 0px;
  bottom: auto;
`