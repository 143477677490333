import React, { useState } from "react";
import iconPlus from '../../../../assets/images/icon_plus_gray.svg';
import styled from "styled-components";
import { DeleteButton } from "./PointGroupSelector/DeleteButton";

interface IInputCreateNewProps {
  inputRef: React.RefObject<HTMLInputElement>;
  onSubmit: () => void;
  initialPlaceholder: string;
  inputValue: string;
  setInputValue: (newValue: string) => void;
  onClickDelete?: () => void;
}

export const InputCreateNew = ({
  inputRef,
  onSubmit,
  initialPlaceholder,
  inputValue,
  setInputValue,
  onClickDelete,
}: IInputCreateNewProps) => {
  const [placeholder, setPlaceholder] = useState<string>(initialPlaceholder);

  const onPressEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  }

  const onClickPlus = () => {
    if (!!inputValue && !!inputValue.trim()) {
      onSubmit();
    } else {
      inputRef.current?.focus();
    }
  }

  return (
    <InputCreateNewContainer>
      <img 
        alt={initialPlaceholder}
        src={iconPlus} 
        style={{cursor: "pointer", marginRight: '10px', verticalAlign: 'middle'}} 
        onClick={onClickPlus}
      />
      
      <NewGroupInput
        ref={inputRef}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.target.value)}
        onFocus={() => setPlaceholder('')}
        onBlur={() => setPlaceholder(initialPlaceholder)}
        onKeyDown={onPressEnter}
      />

      { !!onClickDelete &&
        <DeleteButton
          onClickDelete={onClickDelete}
        />
      }
    </InputCreateNewContainer>
  )
}

const InputCreateNewContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 14px 0;
  
  &:not(&:last-child) {
    border-bottom: 1px solid #E2E8F0;
  }
`

const NewGroupInput = styled.input`
  border: none;
  &:focus {
    outline: none;
  }
  font-size: 14px;

  &::placeholder {
    color: #212121;
  }
`