import { useCallback, useEffect, useMemo, useState } from "react";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { Item } from "../../../../../api/items";
import { SafetyFloorPlanView } from "../SafetyFloorPlanView";
import { ViewerPosition } from "../../../image_viewer/types";
import { useProjectFloorItemsQuery } from "../../../progress/hooks/progressTrackingQueries";
import styled from "styled-components";
import { ViewSelector } from "../../../../common/ViewSelector/ViewSelector";
import { OptionPicker } from "../../../../common/ViewSelector/components/OptionPicker";
import { Job } from "../../../../../api/jobs";
import { TypeSelector } from "../../../progress/ProgressFloorTracker/ProgressFloorPlanView/ProgressFloorPlanView";
import { CustomTooltipValue } from "../../../progress/components/ProgressCharts/CustomTooltip";
import { GuardrailDesignation, GuardrailObservation } from "../../../../../api/safety";
import { useGuardrailObservationsQuery } from "../../hooks/safetyQueries";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { retrieveObservation } from "../../../../../api/observations";
import axios from "axios";
import { useNavigation } from "../../../../../hooks/useNavigation";

interface SafetyGuardrailsFloorPlanViewProps {
  viewerPosition?: ViewerPosition;
  minimapMode?: boolean;
}

const trackerName = "Guardrails";
const designations = {
  safe: { id: 1, name: "Safe", display_color: "#10B981" },
  unsafe: { id: 2, name: "Unsafe", display_color: "#FB7185" },
}

const urlCreator = window.URL || window.webkitURL;

export const SafetyGuardrailsFloorPlanView = ({
  viewerPosition,
  minimapMode=false,
}: SafetyGuardrailsFloorPlanViewProps) => {
  const {
    navigateToSafetyMetricDetailFloorPoint,
  } = useNavigation();

  const {state: buildingState} = useBuildingContext();
  const {state: safetyState, updateSafety} = useSafetyContext();

  const {observationDatePointImageMap} = safetyState;

  const [tooltipItem, setTooltipItem] = useState<Item | null>(null);
  const [trackerExpanded, setTrackerExpanded] = useState<boolean>(false);
  const [jobTypesExpanded, setJobTypesExpanded] = useState<boolean>(true);
  const [selectedJobTypes, setSelectedJobTypes] = useState<number[]>([1,2,3]);

  const {data: items, isLoading: itemsLoading} = useProjectFloorItemsQuery(trackerName);
  const {data: observations, isLoading: observationsLoading} = useGuardrailObservationsQuery();

  const dataLoaded = !!items && !itemsLoading && !!observations && !observationsLoading;

  const guardrailObservationsFilteredForDate = useMemo(() => {
    if (observations) {
      return observations.filter(metric => observationDatePointImageMap.has(metric.viewpoints_image_id))
    }
    
    return [];
  }, [observations, observationDatePointImageMap]);

  const handleSelectedGuardrailItem = useCallback(async () => {
    let newActiveSafetyObservation = null;
    const selectedGuardrailObservation = guardrailObservationsFilteredForDate.find(observation => observation.item_id === safetyState.selectedItemId);

    if (selectedGuardrailObservation && buildingState.projectId) {
      const observationResponse = await retrieveObservation(buildingState.projectId, selectedGuardrailObservation.observation_id);
      const returnedObservation = observationResponse.data;

      let localAxios = axios.create();
      delete localAxios.defaults.headers.common.Authorization;
      delete localAxios.defaults.headers.common['X-API-KEY'];

      let url = returnedObservation.image.processed_image?.base_image_url;
      if (returnedObservation?.is_manual) {
        // @ts-ignore
        url = returnedObservation?.image.user_image.image_url;
      }

      const blobReponse = await localAxios.get(url, {
        responseType: 'blob'
      });
      let imgUrl = urlCreator.createObjectURL(blobReponse.data);
      returnedObservation.localImage = imgUrl;

      returnedObservation.labels = [
        {id: Math.random(), coordinates: selectedGuardrailObservation.coordinates}
      ]

      newActiveSafetyObservation = returnedObservation;
    }

    updateSafety({
      activeObservation: newActiveSafetyObservation
    })
  }, [buildingState.projectId, guardrailObservationsFilteredForDate, safetyState.selectedItemId, updateSafety]);

  useEffect(() => {
    handleSelectedGuardrailItem();
  }, [handleSelectedGuardrailItem]);

  const itemMetricMap = useMemo(() => {
    const map = new Map<number, GuardrailObservation>();

    guardrailObservationsFilteredForDate.forEach(observation => {
      const itemId = observation.item_id;

      if (!map.has(itemId)) {
        map.set(itemId, observation);
      }
    });

    return map;
  }, [guardrailObservationsFilteredForDate]);

  const viewSelectorTrackerOptions = useMemo(() => {
    return [{
      Id: "guardrails",
      Content: trackerName,
      Options: [],
      OnSelectChild: () => {},
    }];
  }, []);

  const stats = useMemo(() => {
    const counts = {
      safe: 0,
      unsafe: 0,
    };

    if (items) {
      items.forEach(item => {
        const itemObservation = itemMetricMap.get(item.id);
        const designation = itemObservation ? itemObservation.designation : "safe";
        counts[designation] += 1;
      });
    }

    const itemsLength = items ? items.length : 1;

    return {
      safe: Math.round(counts.safe / itemsLength * 100),
      unsafe: Math.round(counts.unsafe / itemsLength * 100),
    };
  }, [items, itemMetricMap]);

  const viewSelectorTrackerJobOptions = useMemo(() => {
    const onCheck = (id: number, checked: boolean) => {
      if (checked) {
        setSelectedJobTypes(prevSelectedTypes => [...prevSelectedTypes, id]);
      } else {
        setSelectedJobTypes(prevSelectedTypes => prevSelectedTypes.filter(typeId => id !== typeId));
      }
    }

    const onClickLabel = (jobTypeId: number) => {
      setSelectedJobTypes([jobTypeId]);
    }

    return Object.entries(designations).map(([slug, designation]) => {
      return ({
        Id: slug,
        Content: (
          <TypeSelector
            key={designation.id}
            name={designation.name}
            displayColor={designation.display_color}
            checked={selectedJobTypes.includes(designation.id)}
            onCheck={checked => onCheck(designation.id, checked)}
            onClickLabel={() => onClickLabel(designation.id)}
            percent={stats[slug as GuardrailDesignation]}
          />
        ),
      })
    });
  }, [selectedJobTypes, stats]);

  const jobs = useMemo(() => {
    if (items) {
      return items.map(item => {
        const itemObservation = itemMetricMap.get(item.id);
        const designation = itemObservation?.designation ? designations[itemObservation.designation] : designations.safe;

        return {
          id: item.id,
          completed_units: itemObservation ? itemObservation.observed_quantity : 0,
          initial_observation_date: itemObservation ? itemObservation.registered_on : null,
          item: item,
          total_units: 1,
          type: {
            project: 0,
            job_type: {
              display_color: designation.display_color,
              display_shape: "line",
              display_order: 0,
              id: 1,
              item_type: 1,
              name: "Guardrails",
              secondary_display_color: null,
              slug: "guardrails",
              tertiary_display_color: null,
              units: "LF"
            }
          },
          registered_on: "",
          last_modified_on: "",
          status: "",
          rate: 0,
        }
      });
    }

    return [];
  }, [items, itemMetricMap]);

  const itemJobMap = useMemo(() => {
    const map = new Map<number, Job>();

    jobs.forEach(job => {
      map.set(job.item.id, job);
    });

    return map;
  }, [jobs]);

  const tooltipJob = useMemo(() => {
    if (tooltipItem) {
      return itemJobMap.get(tooltipItem.id);
    }

    return null;
  }, [itemJobMap, tooltipItem]);

  const TooltipValue = useMemo(() => {
    const color = tooltipJob ? tooltipJob.type.job_type.display_color : "#808080";
    const itemObservation = tooltipItem ? itemMetricMap.get(tooltipItem.id) : null;
    const designation = itemObservation?.designation ? designations[itemObservation.designation] : designations.safe;

    return (
      <CustomTooltipValue
        style={{
          color: color,
        }}
      >
        {designation.name}
      </CustomTooltipValue>
    )
  }, [itemMetricMap, tooltipItem, tooltipJob]);

  const onClickItem = (pointId: string, observationDate?: Date, activeTracker?: string, itemId?: string | number) => {
    if (itemId) {
      const itemObservation = itemMetricMap.get(parseInt(itemId.toString()));

      navigateToSafetyMetricDetailFloorPoint(pointId, activeTracker, observationDate, itemObservation ? itemId : undefined);
    }
  }

  return (
    <>
      <SafetyFloorPlanView
        viewerPosition={viewerPosition}
        jobs={jobs}
        dataLoaded={dataLoaded}
        tooltipItem={tooltipItem}
        setTooltipItem={setTooltipItem}
        selectedJobTypeIds={selectedJobTypes}
        TooltipValue={TooltipValue}
        onClickItem={onClickItem}
      />
      {(!minimapMode) &&
        <TrackerSelectorContainer>
          <ViewSelector
            title="Trackers"
            initialViewSelectorExpanded
            viewingExpanded={trackerExpanded}
            maxHeight="calc(100vh - 210px)"
            setViewingExpanded={setTrackerExpanded}>
            <OptionPicker
              expanded={trackerExpanded}
              setExpanded={setTrackerExpanded}
              selectedItem={viewSelectorTrackerOptions[0]}
              viewingItems={{
                Id: 'root',
                Content: 'Tracker',
                OnSelectChild: option => {},
                Options: viewSelectorTrackerOptions,
              }}
              onSelectOption={(selection: any) => {}}
              showBottomBorder
            />
            <OptionPicker
              hideSelectedOptionDisplay
              expanded={jobTypesExpanded}
              setExpanded={setJobTypesExpanded}
              viewingItems={{
                Id: 'root',
                Content: "Job Types",
                Options: viewSelectorTrackerJobOptions,
              }}
            />
          </ViewSelector>
        </TrackerSelectorContainer>
      }
    </>
  );
}

const TrackerSelectorContainer = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
`;