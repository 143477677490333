import { SplitScreenContainer } from '../styles';
import { ViewerPane } from './ViewerPane/ViewerPane';
import { SplitScreenGutter } from './SplitScreenGutter';
import { useImageViewerContext } from '../imageViewerContext';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import mixpanel from 'mixpanel-browser';
import { ViewerPosition } from '../types';
import {
  applyViewerPositionDelta,
  calculateViewerPositionDelta,
  reverseViewerPositionDelta,
} from '../../../../utils';

const defaultPosition: ViewerPosition = {
  yaw: 0,
  pitch: 0,
  hfov: 120,
};

const zeroPosition: ViewerPosition = {
  yaw: 0,
  pitch: 0,
  hfov: 0,
};

export const SplitScreenManager = () => {
  const { state: buildingState } = useBuildingContext();
  const { state: imageViewerState, updateImageViewer } = useImageViewerContext();
  const pendingMasterSync = useRef<boolean>(false);
  const pendingSubordinateSync = useRef<boolean>(false);
  const [sync, setSync] = useState<boolean>(true);
  const [compass, setCompass] = useState<boolean>(false);
  const [masterPosition, setMasterPosition] = useState<ViewerPosition>(defaultPosition);
  const [subordinatePosition, setSubordinatePosition] = useState<ViewerPosition>(defaultPosition);
  const [positionDelta, setPositionDelta] = useState<ViewerPosition>(zeroPosition);
  const [masterFollow, setMasterFollow] = useState<boolean>(false);
  const [subordinateFollow, setSubordinateFollow] = useState<boolean>(false);

  const handleMasterUpdate = useCallback(
    (newValues: ViewerPosition) => {
      if (sync) {
        pendingMasterSync.current = true;
      } else {
        setPositionDelta(calculateViewerPositionDelta(newValues, subordinatePosition));
      }
      setMasterPosition(newValues);
    },
    [sync, subordinatePosition]
  );

  const handleSubordinateUpdate = useCallback(
    (newValues: ViewerPosition) => {
      if (sync) {
        pendingSubordinateSync.current = true;
      } else {
        setPositionDelta(calculateViewerPositionDelta(masterPosition, newValues));
      }
      setSubordinatePosition(newValues);
    },
    [masterPosition, sync]
  );

  const handleMasterDragging = useCallback(
    (isActive: boolean) => {
      if (sync && isActive) {
        setSubordinateFollow(true);
      } else {
        setSubordinateFollow(false);
      }
    },
    [sync]
  );

  const handleSubordinateDragging = useCallback(
    (isActive: boolean) => {
      if (sync && isActive) {
        setMasterFollow(true);
      } else {
        setMasterFollow(false);
      }
    },
    [sync]
  );

  const masterSyncedTo = useMemo(() => {
    return sync ? 'pnlm-global' : 'image-viewer-master';
  }, [sync]);

  const subordinateSyncedTo = useMemo(() => {
    return sync ? 'pnlm-global' : 'image-viewer-subordinate';
  }, [sync]);

  useEffect(() => {
    if (sync) {
      if (compass) {
        setPositionDelta(zeroPosition);
        pendingMasterSync.current = true;
      }
    }
  }, [sync, compass]);

  useEffect(() => {
    if (pendingMasterSync.current) {
      pendingMasterSync.current = false;
      if (compass) {
        setSubordinatePosition(masterPosition);
      } else {
        setSubordinatePosition(applyViewerPositionDelta(masterPosition, positionDelta));
      }
    }
  }, [compass, masterPosition, positionDelta]);

  useEffect(() => {
    if (pendingSubordinateSync.current) {
      pendingSubordinateSync.current = false;
      if (compass) {
        setMasterPosition(subordinatePosition);
      } else {
        setMasterPosition(reverseViewerPositionDelta(subordinatePosition, positionDelta));
      }
    }
  }, [compass, positionDelta, subordinatePosition]);

  /* Hide Tour hotspots while in split screen, but don't delete them */
  useEffect(() => {
    if (imageViewerState.splitScreen) {
      let hss = Array.from(document.getElementsByClassName('pnlm-TOUR'));
      hss.forEach(hs => ((hs as HTMLElement).style.display = 'none'));
      mixpanel.track('View Split Screen');
    } else {
      let hss = Array.from(document.getElementsByClassName('pnlm-TOUR'));
      hss.forEach(hs => ((hs as HTMLElement).style.display = 'block'));
      updateImageViewer({ pane2Type: 'image' });
    }
  }, [buildingState.tourPoints, imageViewerState.splitScreen, updateImageViewer]);

  useEffect(() => {
    updateImageViewer({ sync: sync });
  }, [sync, updateImageViewer]);

  return (
    <SplitScreenContainer id="pnlm-global" style={{ height: '100%', width: '100%' }}>
      {imageViewerState.splitScreen && (
        <>
          <ViewerPane
            type={'subordinate'}
            isSolo={false}
            viewerPositionDelta={positionDelta}
            viewerPosition={subordinatePosition}
            followRealtime={subordinateFollow}
            onDragging={handleSubordinateDragging}
            onUpdate={handleSubordinateUpdate}
            imageData={imageViewerState.subordinate}
            syncedTo={subordinateSyncedTo}
          />
          <SplitScreenGutter
            sync={sync}
            compass={compass}
            showCompass={false}
            onSyncChange={setSync}
            onCompassChange={setCompass}
          />
        </>
      )}
      <ViewerPane
        type={'master'}
        isSolo={!imageViewerState.splitScreen}
        viewerPosition={masterPosition}
        followRealtime={masterFollow}
        onDragging={handleMasterDragging}
        onUpdate={handleMasterUpdate}
        imageData={imageViewerState.master}
        syncedTo={masterSyncedTo}
      />
    </SplitScreenContainer>
  );
};
