import axios, { AxiosResponse } from 'axios';
import { ProgressSummary } from '../components/views/progress/components/ProgressCharts/ProgressCharts';
import { format } from 'date-fns';
import { Item } from './items';

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

export const listTrackerJobTypes = (
  projectId: string,
  tracker: string
): Promise<AxiosResponse<ProjectJobType[]>> => {
  return axios
    .get(`${BASE_URL}/project/${projectId}/tracker/${tracker}/job-types`)
    .then(r => r.data);
};

export const listJobsForProjectTrackerFloor = async (
  projectId: string,
  activeTracker: string,
  floorCode: string,
  observationDate?: Date | null
): Promise<Job[]> => {
  const res = await axios.get(
    `${BASE_URL}/project/${projectId}/tracker/${activeTracker}/jobs?observation_date=${
      observationDate ? format(observationDate, 'yyyy-MM-dd') : ''
    }&floor=${floorCode}`
  );

  const response = await res.data;
  return response.data;
};

export const getJobsByViewpoint = (projectId: string, tracker: string, viewpoint: number) => {
  return axios
    .get(`${BASE_URL}/project/${projectId}/tracker/${tracker}/jobs?viewpoint=${viewpoint}`)
    .then(r => r.data);
};

export const getProgressSummaryByDate = async (
  projectId: string,
  tracker: string,
  floor_code: string
): Promise<ProgressSummary> => {
  const res = await axios.get(
    `${BASE_URL}/project/${projectId}/tracker/${tracker}/jobs/summary?floor=${floor_code}`
  );
  const response = await res.data;

  return response.data;
};

export interface Job {
  id: number;
  type: ProjectJobType;
  item: Item;
  completed_units: number;
  registered_on: string;
  last_modified_on: string;
  status: string;
  total_units: number;
  rate: number;
  initial_observation_date: string | null;
}

export interface JobType {
  id: number;
  name: string;
  slug: string;
  units: string;
  display_color: string;
  secondary_display_color: string | null;
  tertiary_display_color: string | null;
  display_shape: string;
  display_order: number;
  item_type: number;
}
export interface ProjectJobType {
  job_type: JobType;
  project: number;
}
