import React, { useState, createContext, useCallback } from 'react';
import { ViewersContextType, PannellumViewer } from '../types';

// Define the context for multiple viewers
export const ViewersContext = createContext<ViewersContextType | undefined>(undefined);

interface PannellumViewersProviderProps {
  children: React.ReactNode;
}

export const PannellumViewersProvider: React.FC<PannellumViewersProviderProps> = ({ children }) => {
  const [viewers, setViewers] = useState<Record<string, PannellumViewer | null>>({});

  const setViewer = useCallback((id: string, viewer: PannellumViewer) => {
    setViewers(prevViewers => ({ ...prevViewers, [id]: viewer }));
  }, []);

  return (
    <ViewersContext.Provider value={{ viewers, setViewer }}>{children}</ViewersContext.Provider>
  );
};
