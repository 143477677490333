import { ErrorComponentContainer, ErrorPageMessageContainer, ErrorResetButton } from './styles';
import { useEffect } from 'react';
import { globalErrorHandler } from '../../../globalErrorHandler';

interface IErrorProps {
  error: Error;
  resetErrorBoundary: () => void;
}

export const ComponentErrorFallback = ({ error, resetErrorBoundary }: IErrorProps) => {
  useEffect(() => {
    globalErrorHandler(error);
  });

  return (
    <ErrorComponentContainer>
      <ErrorPageMessageContainer>
        <div>
          <h1 style={{ fontSize: '1.3em' }}>Something went wrong</h1>
          <ErrorResetButton onClick={resetErrorBoundary}>Reload the page</ErrorResetButton>
        </div>
      </ErrorPageMessageContainer>
    </ErrorComponentContainer>
  );
};
