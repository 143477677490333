import React from 'react';
import Flex from '@react-css/flex';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';

import { IChatNotification } from '../types';
import { useGeneratedPaths } from '../../../../hooks/useGeneratedPaths';
import { ChatNotificationItemContainer, UnreadDot } from './styles';
import { getFullName, truncateMessage } from '../../../../utils';
import { InitialsAvatar } from '../../InitialsAvatar';

interface IChatNotificationItemProps {
  notification: IChatNotification;
  lastItem: boolean;
}

export const ChatNotificationItem = ({ notification, lastItem }: IChatNotificationItemProps) => {
  const history = useHistory();
  const { generatePointFullPath } = useGeneratedPaths();

  const onClick = () => {
    history.replace(
      generatePointFullPath(
        notification.room.tag[0].project_id,
        notification.room.tag[0].floor_id,
        notification.room.tag[0].point_id
      ) +
        '?' +
        new URLSearchParams({
          pitch: notification.room.tag[0].pitch.toFixed(3),
          yaw: notification.room.tag[0].yaw.toFixed(3),
          tag: notification.room.tag[0].id,
        })
    );
  };

  return (
    <ChatNotificationItemContainer onClick={onClick} className={lastItem ? 'last-element' : ''}>
      {notification.has_unread && <UnreadDot />}
      <InitialsAvatar user={notification.room.last_message.sender} theme="dark" />
      <div style={{ lineHeight: '1.1em', width: '100%' }}>
        <Flex flexDirection="row" style={{ justifyContent: 'space-between' }}>
          <p style={{ fontSize: '1em', fontWeight: 'bold' }}>
            {getFullName(notification.room.last_message.sender)}
          </p>
          <Moment
            format="MM/DD/YY hh:mm"
            style={{ fontSize: '0.7em' }}
            date={new Date(notification.room.last_message.created_at)}
          />
        </Flex>
        <p style={{ fontSize: '0.8em' }}>
          {truncateMessage(notification.room.name || 'Untitled Chat', 30)}
        </p>
        <p style={{ fontSize: '0.75em', color: '#666' }}>
          {truncateMessage(notification.room.last_message.content, 25)}
        </p>
      </div>
    </ChatNotificationItemContainer>
  );
};
