import { Flex } from '@react-css/flex';
import { useState } from 'react';

import { useUserContext } from '../../../../contexts/userContext';
import { ChatMenu } from './ChatMenu';
import { ChatInputForm, InputContainer, ChatInputField } from './styles';
import mixpanel from 'mixpanel-browser';

interface ChatInputProps {
  viewerId: string;
  sendMessage: any;
}

export const ChatInput = ({ sendMessage, viewerId }: ChatInputProps) => {
  const userState = useUserContext().state;
  const [chatInput, setChatInput] = useState<string>('');

  const onPressEnter = (e: any) => {
    e.preventDefault();
    if (chatInput !== '') {
      let messageData = {
        sender: userState.public_id,
        content_type: 'TEXT',
        content: chatInput,
        event_type: 'chat_message',
      };
      sendMessage(JSON.stringify(messageData));
      setChatInput('');
      mixpanel.track('Send Chat');
    }
  };

  return (
    <InputContainer>
      <Flex flexDirection="row">
        <ChatInputForm onSubmit={onPressEnter}>
          <ChatInputField
            type="text"
            id="chat-input"
            placeholder="Type a message..."
            onChange={e => {
              setChatInput(e.target.value);
            }}
            value={chatInput}
          />
        </ChatInputForm>
        <Flex
          flexDirection="row"
          style={{ alignItems: 'center', width: '5%', justifyContent: 'space-evenly' }}>
          <ChatMenu viewerId={viewerId} />
        </Flex>
      </Flex>
    </InputContainer>
  );
};
