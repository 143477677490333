import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: absolute;
  margin-top: 90px;
  background-color: #f9f9f9;
  min-width: 300px;
  max-height: 40%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.75);
  padding: 12px 16px;
  z-index: 1;
  right: 110px;
  top: -40px;
  border-radius: 2px;
  overflow-y: auto;
`;
