import { useContext } from 'react';
import { RealtimePositionContext } from '../contexts/realtimeContext';

// Custom hook to access the shared state and updater function
export const useRealtimePosition = () => {
  const context = useContext(RealtimePositionContext);

  if (!context) {
    throw new Error('useRealtimePosition must be used within a RealtimePositionProvider');
  }

  return context;
};
