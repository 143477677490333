import { useQuery } from "react-query";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { ProjectJobType, listTrackerJobTypes } from "../../../../api/jobs";
import { Item, fetchItems } from "../../../../api/items";
import { ProjectTrackerObservation, fetchProjectTrackerObservations } from "../../../../api/trackers";
import { ProgressRegion, fetchProgressRegions } from "../../../../api/progressRegions";

export const useProjectJobTypesQuery = (trackerName: string, onSuccess?: (data: ProjectJobType[]) => void) => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;

  return useQuery(
    ['project-job-types', projectId, trackerName],
    async () => {
      const trackerJobTypesResponse = await listTrackerJobTypes(projectId, trackerName);

      return trackerJobTypesResponse.data;
    },
    { enabled: !!projectId, onSuccess: onSuccess }
  );
}

export const useProjectFloorItemsQuery = (trackerName: string, onSuccess?: (data: Item[]) => void) => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;
  const floorId = buildingState.floorId;

  return useQuery(
    ['items', projectId, floorId, trackerName],
    async () => {
      return await fetchItems(projectId, trackerName, {floor: floorId});
    },
    { enabled: !!projectId && !!floorId, onSuccess: onSuccess }
  );
}

export const useProjectFloorObservationsQuery = (trackerName: string, onSuccess?: (data: ProjectTrackerObservation[]) => void) => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;
  const floorId = buildingState.floorId;

  return useQuery(
    ['observations', projectId, floorId, trackerName],
    async () => {
      return await fetchProjectTrackerObservations(projectId, trackerName, {floor: floorId});
    },
    { enabled: !!projectId && !!floorId, onSuccess: onSuccess }
  );
}

export const useProjectFloorProgressRegionsQuery = (onSuccess?: (data: ProgressRegion[]) => void) => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;
  const floorId = buildingState.floorId;

  return useQuery(
    ['regions', projectId, floorId],
    async () => {
      return await fetchProgressRegions(projectId, {floor_code: floorId});
    },
    { enabled: !!projectId && !!floorId, onSuccess: onSuccess }
  );
}

