import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { PATH_STRINGS, useGeneratedPaths } from "../../../../hooks/useGeneratedPaths";

export const useSiteWalkNavigation = () => {
  const {generateSiteWalkConfirmationPath, generateSiteWalkConfirmPointPath} = useGeneratedPaths();
  const history = useHistory();

  const navigateToSiteWalkPage = useCallback(
    () => history.push(PATH_STRINGS.siteWalk),
    [history]
  );

  const navigateToSiteWalkConfirmation = useCallback(
    (siteWalkId: string | number) => history.replace(generateSiteWalkConfirmationPath(siteWalkId)),
    [history, generateSiteWalkConfirmationPath]
  );

  const navigateToSiteWalkConfirmPoint = useCallback(
    (siteWalkId: string | number, pointId: string | number) => history.replace(generateSiteWalkConfirmPointPath(siteWalkId, pointId)),
    [history, generateSiteWalkConfirmPointPath]
  );

  return {
    navigateToSiteWalkPage,
    navigateToSiteWalkConfirmation,
    navigateToSiteWalkConfirmPoint,
  }
}