import axios from 'axios';
import { globalErrorHandler } from '../globalErrorHandler';

const BASE_URL = process.env.REACT_APP_SERVICES_API;

export const getChatNotifications = (userId: string, pageNumber = 1) => {
  return axios
    .get(`${BASE_URL}/chat/user-rooms/${userId}/?page=${pageNumber}`)
    .then(res => {
      return res.data;
    })
    .catch(err => globalErrorHandler(err));
};
