import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getProgressSummaryByDate, ProjectJobType } from '../../../../../api/jobs';
import { ProjectTracker } from '../../../../../api/trackers';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { useProgressContext } from '../../../../../contexts/progressContext';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import { ProgressBarChart } from './ProgressBarChart';
import { ProgressLineChart } from './ProgressLineChart';
import { getPercentOverTime, monthAbbreviations } from '../../ProgressOverview/ProgressOverview';
import { ProgressCompletionDates } from './ProgressCompletionDates';

export const xAxisTickFormatter = (tick: string) => {
  const tickAsDate = new Date(tick);

  return `${monthAbbreviations[tickAsDate.getMonth()]} ${tickAsDate.getDate()}`;
};

interface ProgressUnits {
  total_units: number;
  completed_units: number;
}

export type ProgressSummaryEntry = { date: string } & Record<string, ProgressUnits>;

export interface ProgressSummary {
  initial_observation_date: string | null;
  latest_observation_date: string | null;
  progress_by_date: ProgressSummaryEntry[];
}

interface ProgressChartsProps {
  selectedFloor: string;
  startDate: Date;
  endDate: Date;
}

export const ProgressCharts = ({ selectedFloor, startDate, endDate }: ProgressChartsProps) => {
  const { state: buildingState } = useBuildingContext();
  const { state: progressState } = useProgressContext();
  const [localLoad, setLocalLoad] = useState<boolean>(true);
  const [progressSummary, setProgressSummary] = useState<ProgressSummary>({
    progress_by_date: [],
    initial_observation_date: null,
    latest_observation_date: null,
  });

  const { progress_by_date, initial_observation_date, latest_observation_date } = progressSummary;
  const { activeTracker, trackers } = progressState;

  useEffect(() => {
    setLocalLoad(true);

    getProgressSummaryByDate(buildingState.projectId, activeTracker, selectedFloor)
      .then(returnedSummary => {
        setProgressSummary(returnedSummary);
      })
      .finally(() => {
        setLocalLoad(false);
      });
  }, [buildingState.projectId, activeTracker, selectedFloor]);

  const filteredProgressSummary = useMemo(() => {
    const startDateMidnight = new Date(startDate);
    startDateMidnight.setHours(0, 0, 0, 0);

    const endDateLatest = new Date(endDate);
    endDateLatest.setHours(23, 59, 59, 999);

    return progress_by_date.filter(entry => {
      const entryDate = new Date(entry.date);

      return entryDate >= startDateMidnight && entryDate <= endDateLatest;
    });
  }, [endDate, startDate, progress_by_date]);

  const activeProjectTracker = useMemo(() => {
    return trackers.find((tracker: ProjectTracker) => tracker.type.name === activeTracker);
  }, [activeTracker, trackers]);

  const activeTrackerJobTypes = useMemo(() => {
    if (activeProjectTracker && activeProjectTracker.types) {
      return activeProjectTracker.types.map((type: ProjectJobType) => type.job_type);
    }

    return [];
  }, [activeProjectTracker]);

  const getCurrentProgress = useCallback(() => {
    if (progress_by_date.length > 0) {
      const lastEntry = progress_by_date[progress_by_date.length - 1];
      const { date, ...slugs } = lastEntry;

      const completedUnits = Object.values(slugs).reduce(
        (total, entry) => total + entry.completed_units,
        0
      );
      const totalUnits = Object.values(slugs).reduce(
        (total, entry) => total + entry.total_units,
        0
      );

      return {
        totalUnits,
        completedUnits,
      };
    }

    return null;
  }, [progress_by_date]);

  const getAvgInstallationRate = useCallback(() => {
    const currentProgress = getCurrentProgress();

    if (initial_observation_date && latest_observation_date && currentProgress) {
      return getPercentOverTime(
        currentProgress.totalUnits,
        currentProgress.completedUnits,
        new Date(initial_observation_date),
        new Date(latest_observation_date)
      );
    }

    return null;
  }, [initial_observation_date, latest_observation_date, getCurrentProgress]);

  const observedStartDate = useMemo(() => {
    if (initial_observation_date) {
      const initialObservationDateAsDate = new Date(initial_observation_date);

      return `${initialObservationDateAsDate.getDate()} ${
        monthAbbreviations[initialObservationDateAsDate.getMonth()]
      } ${initialObservationDateAsDate.getFullYear()}`;
    }

    return 'N/A';
  }, [initial_observation_date]);

  return (
    <ProgressChartsContainer>
      <ChartsContainer>
        <ChartAndLabelContainer>
          <ProgressChartsLabel>Daily Progress</ProgressChartsLabel>
          <ChartContainer>
            {localLoad && <LoadingIndicator />}
            {!localLoad && (
              <ProgressBarChart
                progressSummaryByDate={filteredProgressSummary}
                activeTrackerJobTypes={activeTrackerJobTypes}
              />
            )}
          </ChartContainer>
        </ChartAndLabelContainer>
        <ChartAndLabelContainer>
          <ProgressChartsLabel>Cumulative Progress</ProgressChartsLabel>
          <ChartContainer>
            {localLoad && <LoadingIndicator />}
            {!localLoad && (
              <ProgressLineChart
                progressSummaryByDate={filteredProgressSummary}
                activeTrackerJobTypes={activeTrackerJobTypes}
              />
            )}
          </ChartContainer>
        </ChartAndLabelContainer>
      </ChartsContainer>
      <DataSummaryAndLabelContainer>
        <ProgressChartsLabel>Data Summary</ProgressChartsLabel>
        <DataSummaryContainer>
          <DataSummaryEntry label="Observed Start Date" value={observedStartDate} />
          <DataSummarySeparator />

          <DataSummaryEntry
            label="Avg installation rate"
            value={!!getAvgInstallationRate() ? `${getAvgInstallationRate()}% / Day` : 'N/A'}
          />

          {!localLoad && progress_by_date.length > 0 && (
            <>
              <DataSummarySeparator />
              <div>
                <DataSummaryLabel>Estimated completion Date</DataSummaryLabel>
                <ProgressCompletionDates progress_by_date={progress_by_date} />
              </div>
            </>
          )}
        </DataSummaryContainer>
      </DataSummaryAndLabelContainer>
    </ProgressChartsContainer>
  );
};

interface IDataSummaryEntryProps {
  label: string;
  value: string;
}

const DataSummaryEntry = ({ label, value }: IDataSummaryEntryProps) => {
  return (
    <div>
      <DataSummaryLabel>{label}</DataSummaryLabel>
      <DataSummaryValue>{value}</DataSummaryValue>
    </div>
  );
};

const ProgressChartsLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  font-weight: 600;
`;

const ProgressChartsContainer = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
`;

const ChartsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  flex: 1;
`;

const ChartAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

const ChartContainer = styled.div`
  border-radius: 4px;
  height: 100%;
  background-color: #f8fafc;
  flex: 1;
`;

const DataSummaryAndLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const DataSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
  border-radius: 4px;
  padding: 24px;
  gap: 16px;
  flex: 1;
`;

const DataSummaryLabel = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  color: #5c6f8a;
`;

export const DataSummaryValue = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  font-weight: bold;
`;

const DataSummarySeparator = styled.div`
  height: 1px;
  background: #e2e8f0;
`;

const NoDataContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const NoChartData = () => {
  return <NoDataContainer>No data for given date range</NoDataContainer>;
};
