import { FloorPlanView } from "../../../building_page/components/FloorPlanView";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { ViewSelector } from "../../../../common/ViewSelector/ViewSelector";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { IGroup, PointGroupSelector } from "../../../../common/ViewSelector/components/PointGroupSelector/PointGroupSelector";
import styled from "styled-components";
import { PointControls } from "../../../../common/ViewSelector/components/PointControls";
import { createPoint, updatePoint } from "../../../../../api/adminBuildingFetches";
import { IMapPoint, ManagedMapPoint } from "./ManagedMapPoint";
import { useManagePointsContext } from "./ManagePointsContext";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { ProjectFloorSection } from "../../../../../api/types";
import { useFetchProjectFloorSectionsQuery } from "../../hooks/adminBuildingQueries";

const getClickCoordinates = (event: React.MouseEvent) => {
  return {
    x: event.nativeEvent.offsetX,
    y: event.nativeEvent.offsetY,
  };
}

export const ManagePoints = () => {
  const {
    addNotification
  } = useNotifications();

  const {
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    canCreateNewPoints,
    canSelectPoints,
    canDeletePointGroups,
    canCreatePointGroups,
  } = useManagePointsContext();

  const [groupsExpanded, setGroupsExpanded] = useState<boolean>(true);
  const [pointControlsExpanded, setPointControlsExpanded] = useState<boolean>(true);
  const [currentlyDraggingPointId, setCurrentlyDraggingPointId] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const originalDraggingPointCoordinates = useRef<{x: number; y: number} | null>(null);

  const projectFloorSectionsOnSuccess = (data: ProjectFloorSection[]) => {
    updateFloor({
      sections: data
    });
  }

  useFetchProjectFloorSectionsQuery(buildingState.projectId, buildingState.floorId, projectFloorSectionsOnSuccess);

  useEffect(() => {
    updateFloor({
      selectedPoints: new Set()
    });
  }, []);

  const onDoubleClickMap = async (event: React.MouseEvent) => {
    if (!canCreateNewPoints) {
      return;
    }

    const {x, y} = getClickCoordinates(event);

    try {
      const newPoint = await createPoint(buildingState.projectId, buildingState.floorId, x, y);

      updateFloor({
        points: [...buildingState.floorData.points, newPoint]
      });
    } catch (err) {
      console.log('createPoint==>>', err);
    }
  };

  const updatePointState = (pointId: number | null, x: number, y: number) => {
    const floorPoints = buildingState.floorData?.points;

    if (!!floorPoints && pointId !== null) {
      const updatedPoints = floorPoints.map((point: IMapPoint) => {
        if (point.point_id !== pointId) {
          return point;
        } else {
          return {...point, x, y};
        }
      });

      updateFloor({
        points: updatedPoints,
      });
    }
  }

  const onMouseMoveMap = (event: React.MouseEvent) => {
    const {x, y} = getClickCoordinates(event);

    if (isDragging) {
      updatePointState(currentlyDraggingPointId, x, y);
    }
  };

  const onSelectPoint = (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => {
    if (!canSelectPoints) {
      return;
    }

    const clickedPointId = point.point_id;

    const selectedPoints = new Set(buildingState.floorData.selectedPoints);
    const clickedPointSelected = selectedPoints.has(clickedPointId);
    const selectedGroups = new Set(buildingState.floorData.selectedGroups);

    if (clickedPointSelected) {
      selectedPoints.delete(clickedPointId);

      const pointGroupSet = buildingState.floorData.pointMappings.get(clickedPointId);

      if (pointGroupSet) {
        pointGroupSet.forEach((groupId: number) => {
          selectedGroups.delete(groupId)
        });
      }
    } else {
      selectedPoints.add(clickedPointId);
    }

    updateFloor({
      selectedPoints,
      selectedGroups
    });
  };

  const stopEventPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  const onUpdatePointPosition = async (point: IMapPoint) => {
    try {
      const updateData = {
        x: point.x,
        y: point.y,
      };

      await updatePoint(buildingState.projectId, buildingState.floorId, point.point_id, updateData);
      
      addNotification("Point moved successfully", "success");
    } catch {
      addNotification("Error moving point", "error");

      if (originalDraggingPointCoordinates.current !== null) {
        const {
          x: oldX,
          y: oldY
        } = originalDraggingPointCoordinates.current;

        updatePointState(currentlyDraggingPointId, oldX, oldY);
      }
    }
  }

  const onMouseUpPoint = async (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => {
    if (!isDragging) {
      onSelectPoint(e, point)
    } else {
      await onUpdatePointPosition(point);
    }
    
    setCurrentlyDraggingPointId(null);
    setIsDragging(false);
    originalDraggingPointCoordinates.current = null;
  }

  const onMouseDownPoint = (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => {
    if (!point.has_image) {
      stopEventPropagation(e);

      setCurrentlyDraggingPointId(point.point_id);

      originalDraggingPointCoordinates.current = {
        x: parseFloat(point.x),
        y: parseFloat(point.y)
      };
    }
  }

  const onMouseMovePoint = (e: React.MouseEvent<HTMLDivElement>) => {
    if (currentlyDraggingPointId !== null) {
      setIsDragging(true);
    }
  }

  const points = buildingState.floorData.points.map((point: IMapPoint) => (
    <ManagedMapPoint
      key={point.point_id}
      point={point}
      x={parseFloat(point.x)}
      y={parseFloat(point.y)}
      onMouseDown={onMouseDownPoint}
      onMouseMove={onMouseMovePoint}
      onMouseUp={onMouseUpPoint}
    />
  ));

  const zoomIconStyle: CSSProperties = { position: 'absolute', right: '0px', zIndex: 2};
  const zoomInIconStyle: CSSProperties = {...zoomIconStyle, bottom: '55px'};
  const zoomOutIconStyle: CSSProperties = {...zoomIconStyle, bottom: '10px'};
  const selectAllPointsStyle: CSSProperties = {...zoomIconStyle, bottom: '100px'};

  const onToggleGroupSelectorCheckbox = (group: IGroup, checked: boolean, groupPoints: number[]) => {
    const selectedPoints = new Set(buildingState.floorData.selectedPoints);

    groupPoints.forEach((pointId) => {
      checked ? selectedPoints.add(pointId) : selectedPoints.delete(pointId);
    });

    updateFloor({
      selectedPoints
    });
  }

  return (
    <>
      <FloorPlanView
        floorData={buildingState.floorData}
        hideDateRangeSelector
        onDoubleClickMap={onDoubleClickMap}
        onMouseMoveMap={onMouseMoveMap}
        mapViewerChildren={points}
        zoomInIconStyle={zoomInIconStyle}
        zoomOutIconStyle={zoomOutIconStyle}
        selectAllPointsStyle={selectAllPointsStyle}
        showPoints={false}
        showSelectAllPoints={canSelectPoints}
      />
      <ViewSelectorContainer>
        <ViewSelector
          title='Groups'
          initialViewSelectorExpanded
          viewingExpanded={groupsExpanded}
          setViewingExpanded={setGroupsExpanded}
          maxHeight={175}
        >
          <PointGroupSelector
            parentOnToggleCheckbox={onToggleGroupSelectorCheckbox}
            hideDelete={!canDeletePointGroups}
            hideCreateNewGroup={!canCreatePointGroups}
          />
        </ViewSelector>
    
        { canSelectPoints &&
          <ViewSelector
            title='Point Controls'
            initialViewSelectorExpanded
            viewingExpanded={pointControlsExpanded}
            setViewingExpanded={setPointControlsExpanded}
            maxHeight={275}
          >
            <PointControls/>
          </ViewSelector>
        }
      </ViewSelectorContainer>
    </>
  )
}

const ViewSelectorContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;