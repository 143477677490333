import { IconButton } from "../../../IconButton";
import iconTrash from '../../../../../assets/images/icon_trash.svg';

interface IDeleteButtonProps {
  onClickDelete: () => void;
  size?: number;
}

export const DeleteButton = ({
  onClickDelete,
  size,
}: IDeleteButtonProps) => {
  return (
    <IconButton
      icon={iconTrash}
      size={size ?? 12}
      onClick={onClickDelete}
      style={{
        right: '0px',
        bottom: 'auto',
        boxShadow: 'none',
      }}
    />
  )
}