import axios from "axios";

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

type ItemParamNames = "viewpoint" | "floor" | "type";
export type ItemParams = Partial<Record<ItemParamNames, string>>;

export const fetchItems = async (projectId: string, trackerName: string, params?: ItemParams): Promise<Item[]> => {
  const res = await axios.get(
    `${BASE_URL}/project/${projectId}/tracker/${trackerName}/items`,
    {
      params: params
    }
  );
  const response = await res.data;
  return response.data;
}

export interface Item {
  id: number;
  type: {name: string};
  position: {x: number, y: number, order: number}[];
  registered_on: string;
  last_modified_on: string;
  project_tracker: number;
  viewpoint: number;
}