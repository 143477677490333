import { ChatMessage } from './ChatMessage';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useUserContext } from '../../../../contexts/userContext';
import { useTagContext } from '../../../../contexts/tagContext';
import { getFullName } from '../../../../utils';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

const MessageScrollContainer = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  max-height: 195px;
  width: 100%;
  padding: 10px;
`;

export const ChatHistory = (props: { messages: any }) => {
  const { state: userState } = useUserContext();
  const { state: tagState } = useTagContext();

  const renderUserList = (partial: boolean) => {
    if (tagState.tags.current) {
      if (tagState.tags.current.chat_room) {
        let userList = tagState.tags.current.chat_room.users;
        if (partial) return getFullName(userList[0]) + ` & ${userList.length - 1} more...`;
        let userNameList = userList.map((user: any) => getFullName(user));
        return userNameList.join(', ');
      }
    }
    return '';
  };

  const renderMessages = () => {
    return props.messages.map((chat: any, index: number) => {
      if (index === props.messages.length - 1) {
        return (
          <ChatMessage
            message={chat}
            user={userState.public_id}
            isLastMessage={true}
            key={chat.id}
          />
        );
      } else return <ChatMessage message={chat} user={userState.public_id} key={chat.id} />;
    });
  };

  useEffect(() => {
    let lastMessage = document.getElementById('last-message');
    if (lastMessage) lastMessage.scrollIntoView();
  }, [props.messages]);

  return (
    <MessageScrollContainer>
      {tagState.tags.current.chat_room && (
        <p
          style={{ fontSize: '0.75em', textAlign: 'center', color: '#111' }}
          data-tip={renderUserList(false)}>
          {renderUserList(true)}
          <br />
        </p>
      )}
      {renderMessages()}
      <ReactTooltip />
    </MessageScrollContainer>
  );
};
