import { Icon } from '../../../../common/Icon';
import {
  ObservationTableData,
  ObservationTableRow,
  ObservationType,
  ProjectFloor,
  ProjectFloorCode,
  ProjectFloorName,
  ScrollableObservationTableContainer,
  Status,
} from './styles';
import iconMap from '../../../../../assets/images/icon_map_gray.svg';
import iconHouse from '../../../../../assets/images/icon_house.svg';
import { useSafetyContext } from '../../../../../contexts/safetyContext';
import { useNavigation } from '../../../../../hooks/useNavigation';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { ProjectTrackerMetricSummary } from '../../../../../api/safety';
import { useMemo } from 'react';
import { getRedToGreenGradientColor } from '../../SafetyFloorPlanView/SafetyClutterFloorPlanView/SafetyClutterFloorPlanView';
import { wetSurfaceGradient } from '../../SafetyFloorPlanView/SafetyWetSurfaceFloorPlanView/SafetyWetSurfaceFloorPlanView';

export interface WetSurfaceObservationTableData {
  floorName: string;
  floorCode: string;
  signal: number;
  inverse_signal: number;
  total_units: number;
}

interface WetSurfaceObservationTableProps {
  data: ProjectTrackerMetricSummary;
}

export const WetSurfaceObservationTable = ({ data }: WetSurfaceObservationTableProps) => {
  const { navigateToSafety } = useNavigation();
  const { state: BuildingState } = useBuildingContext();
  const { state: safetyState } = useSafetyContext();

  const wetSurfaceMetricSummaryData = useMemo(() => {
    let summaryData: WetSurfaceObservationTableData[] = [];
    const buildingFloors = BuildingState.projectData?.floors;
    const projectId = BuildingState.projectId;

    if (buildingFloors && buildingFloors.length > 0 && data && projectId) {
      const projectWetSurfaceMetricSummary = data.floor_summary[projectId];

      if (projectWetSurfaceMetricSummary) {
        buildingFloors.forEach((floor: any) => {
          const floorCode = floor.floor_code;

          const floorWetSurfaceMetricSummary = projectWetSurfaceMetricSummary[floorCode];

          if (floorWetSurfaceMetricSummary) {
            summaryData.push({
              floorCode: floorCode,
              floorName: floor.name,
              signal: floorWetSurfaceMetricSummary.signal,
              inverse_signal: floorWetSurfaceMetricSummary.inverse_signal,
              total_units: floorWetSurfaceMetricSummary.total_units,
            });
          }
        });
      }
    }

    return summaryData;
  }, [BuildingState.projectData?.floors, BuildingState.projectId, data]);

  return (
    <ScrollableObservationTableContainer>
      <table>
        <tbody>
          {wetSurfaceMetricSummaryData.map(entry => {
            const activeEntry = entry.floorCode === BuildingState.floorId;
            const wetSurfaceScore = entry.inverse_signal / entry.total_units;
            const color = wetSurfaceGradient(entry.signal / entry.total_units);

            return (
              <ObservationTableRow
                key={entry.floorCode}
                active={activeEntry}
                onClick={() =>
                  navigateToSafety(safetyState.selectedMetricCategory, entry.floorCode)
                }>
                <ObservationTableData>
                  <ProjectFloorName>
                    <Icon icon={iconMap} size={18} />
                    <ObservationType>{entry.floorName}</ObservationType>
                  </ProjectFloorName>
                </ObservationTableData>
                <ObservationTableData>
                  <ProjectFloor>
                    <Icon icon={iconHouse} size={12} />
                    <ProjectFloorCode>{entry.floorCode}</ProjectFloorCode>
                  </ProjectFloor>
                </ObservationTableData>
                <ObservationTableData align="right">
                  <Status statusColorHex={color}>{`${(wetSurfaceScore * 100).toFixed(1)}%`}</Status>
                </ObservationTableData>
              </ObservationTableRow>
            );
          })}
        </tbody>
      </table>
    </ScrollableObservationTableContainer>
  );
};
