import { Cell, Label, Pie, PieChart } from 'recharts';
import React from 'react';

interface ProgressCircleProps {
  progress: number;
  color: string;
}
export const ProgressCircle = ({ progress, color }: ProgressCircleProps) => {
  return (
    <PieChart width={70} height={35}>
      <Pie
        cx="75%"
        data={[{ value: progress }, { value: 100 - progress }]}
        dataKey="value"
        innerRadius={10}
        fill="#E2E8F0"
        outerRadius={15}>
        <Label position="outside" style={{ fontSize: '12px' }}>{`${progress}%`}</Label>
        <Cell fill={color} />
      </Pie>
    </PieChart>
  );
};
