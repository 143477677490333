import { useState } from "react";
import { useListRFIsQuery } from "../hooks/procoreQueries";
import { RFIFormButton, RFIFormButtonsContainer, RFIFormDivider, RFIFormRow, RFIFormSelect, RFIFormTitle } from "./styles";
import styled from 'styled-components';
import { useCreateRFITag } from "../../../../api/procore";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { LoadingIndicator } from "../../LoadingIndicator";

interface ILinkExistingRFIFormProps {
  onCloseForm: () => void;
  hydrate: () => Promise<any>;
}

export const LinkExistingRFIForm = ({
  onCloseForm,
  hydrate,
}: ILinkExistingRFIFormProps) => {
  const {addNotification} = useNotifications();
  const [selectedRFIId, setSelectedRFIId] = useState<string>();
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);
  const {data: rfis, isLoading: rfisLoading} = useListRFIsQuery();
  const createRFITag = useCreateRFITag();

  const dataLoaded = !!rfis && !rfisLoading;
  const showLoadingIndicator = !dataLoaded || saveInProgress;

  const onClickLink = async () => {
    if (selectedRFIId) {
      try {
        setSaveInProgress(true);
            
        await createRFITag(selectedRFIId);

        await hydrate();
        onCloseForm();
        addNotification('RFI linked successfully', 'success');
      } catch (err) {
        console.log('CREATE RFI ERR: ', err);
        addNotification('Error linking RFI', 'error');
      } finally {
        setSaveInProgress(false);
      }
    }
  }

  return (
    <>
      <ExistingRFIFormContainer style={{width: '345px'}}>
        <RFIFormTitle>Select Existing Procore RFI</RFIFormTitle>

        <RFIFormDivider/>

        {showLoadingIndicator &&
          <LoadingIndicator
            size={50}
            containerStyle={{backgroundColor: 'transparent'}}
          />
        }
        {!showLoadingIndicator &&
          <RFIFormRow>
            <RFIFormSelect
              required
              hideEmptyOption
              label="RFI"
              value={selectedRFIId}
              onChange={newVal => setSelectedRFIId(newVal)}
              options={rfis ?? []}
              mapOptionValue={option => option.id.toString()}
              mapOptionLabel={option => `#${option.number} - ${option.subject}`}
              style={{width: '345px'}}
            />
          </RFIFormRow>
        }
        </ExistingRFIFormContainer>
      <RFIFormButtonsContainer>
        <RFIFormButton
          onClick={onCloseForm}
        >
          Cancel
        </RFIFormButton>
        <RFIFormButton
          primary
          disabled={!selectedRFIId || saveInProgress}
          onClick={onClickLink}
        >
          Link
        </RFIFormButton>
      </RFIFormButtonsContainer>
    </>
  )
}

const ExistingRFIFormContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`