import { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { ViewersContext } from '../contexts/pannellumContext';

export const usePannellumViewer = (viewerId: string) => {
  const context = useContext(ViewersContext);
  if (!context) {
    throw new Error('usePannellumViewer must be used within a PannellumViewersProvider');
  }

  const { viewers, setViewer } = context;

  const [loaded, setLoaded] = useState(false);

  const viewer = useMemo(() => {
    return viewers && viewers[viewerId];
  }, [viewers, viewerId]);

  const createViewer = useCallback(
    (element: HTMLDivElement) => {
      const pannellumViewer = (window as any).pannellum.viewer(
        element,
        {
          default: {
            compass: false,
            friction: 0.8,
            mouseZoom: true,
            showZoomCtrl: false,
            showFullscreenCtrl: false,
            autoLoad: true,
            disableKeyboardCtrl: true,
          },
          syncedTo: 'pnlm-global',
          scenes: {},
        },
        2000
      );
      setViewer(viewerId, pannellumViewer);
      return pannellumViewer;
    },
    [setViewer, viewerId]
  );

  const cleanTemporaryTags = useCallback(() => {
    if (viewer) {
      let hotSpots = viewer.getConfig().hotSpots;
      hotSpots.forEach((hs: any) => {
        if (hs.div.classList.contains('pnlm-tmp')) {
          viewer.removeHotSpot(hs.id);
        }
      });
    }
  }, [viewer]);

  const removeHotSpot = useCallback(
    tagId => {
      cleanTemporaryTags();
      viewer?.removeHotSpot(tagId, 'home');
    },
    [viewer, cleanTemporaryTags]
  );

  const addHotSpot = useCallback(
    (
      coordinates: [number, number],
      type: string,
      clickHandler: (event: Event, args: any) => void,
      contextMenuHandler: (event: MouseEvent, args: any) => void,
      tagId: number,
      angleOffset: number,
      extras?: any
    ) => {
      if (!viewer) throw Error('Missing Pannellum Viewer');
      const tagAlreadyPlaced = viewer.getConfig().hotSpots?.find((item: any) => item.id === tagId);
      const tagNeedsUpdated =
        extras?.tagsToUpdate && extras.tagsToUpdate.find((itemId: number) => itemId === tagId);

      if (tagAlreadyPlaced && !tagNeedsUpdated) {
        return;
      }

      if (tagNeedsUpdated) {
        removeHotSpot(tagId);
      }

      const hotSpot: any = {
        pitch: coordinates[0],
        yaw: coordinates[1] - angleOffset,
        type: type,
        clickHandlerFunc: clickHandler,
        contextMenuHandler: contextMenuHandler,
        contextMenuHandlerArgs: { tagId: tagId },
        id: tagId,
        text: extras?.text || null,
        scale: type === 'TOUR',
        scaleY: extras?.scaleY || 1,
        scaleX: extras?.scaleX || 1,
        cssClass: `oco-ignore pnlm-hotspot pnlm-sprite pnlm-${type} ${
          tagId < 0 ? 'pnlm-tmp' : ''
        } pnlm-id_${tagId} ${extras?.distance ? 'd-' + extras.distance : ''} ${
          extras?.disabled ? 'pnlm-disabled' : ''
        }`,
        clickHandlerArgs: {
          pitch: coordinates[0],
          yaw: coordinates[1] - angleOffset,
          type: type,
          tagId: tagId,
          extras: { ...extras },
        },
        createTooltipArgs: extras,
      };

      if (type === 'TOUR') {
        hotSpot.createTooltipFunc = (div: HTMLElement, args: any) => {
          const span = div.ownerDocument.createElement('span');
          span.style.transform = `scaleX(${args?.scaleX || 1}) scaleY(${args?.scaleY || 1})`;
          span.className = 'pnlm-tour-spot';
          div.appendChild(span);
        };
      }

      return viewer.addHotSpot(hotSpot, 'home');
    },
    [viewer, removeHotSpot]
  );

  useEffect(() => {
    if (viewer) {
      viewer.on('load', () => {
        setLoaded(true);
      });
      viewer.on('scenechange', () => {
        setLoaded(false);
      });
    }
  }, [viewer]);

  return {
    viewer,
    loaded,
    createViewer,
    addHotSpot,
    removeHotSpot,
    cleanTemporaryTags,
  };
};
