import { useEffect, useState } from 'react';
import { SafetyTag } from './SafetyTag/SafetyTag';
import { useTagContext } from '../../../contexts/tagContext';
import { useImageViewerContext } from '../../views/image_viewer/imageViewerContext';
import { ViewerPosition } from '../../views/image_viewer/types';
import { usePannellumViewer } from '../../../hooks/usePannellum';

interface SafetyTagManagerProps {
  tag: any;
  viewerId: string;
  hydrate: any;
  viewerPosition: ViewerPosition;
}

export const SafetyTagManager = ({
  tag,
  viewerId,
  hydrate,
  viewerPosition,
}: SafetyTagManagerProps) => {
  const { state: ImageViewerState } = useImageViewerContext();
  const [transform, setTransform] = useState<string>('');
  const { state: tagState } = useTagContext();
  const { viewer } = usePannellumViewer(viewerId);
  const currentTag = useTagContext().state.tags.current;

  const renderDialogue = () => {
    let dom = document.getElementById('tag-container');
    if (dom && viewer) {
      let ctya = currentTag.yaw,
        canvasPitch = viewerPosition.pitch,
        canvasYaw =
          viewerPosition.yaw + (currentTag.observation ? ImageViewerState.master.data.angle : 0),
        canvasHfov = viewerPosition.hfov,
        hsPitchSin = Math.sin((currentTag.pitch * Math.PI) / 180),
        hsPitchCos = Math.cos((currentTag.pitch * Math.PI) / 180),
        configPitchSin = Math.sin((canvasPitch * Math.PI) / 180),
        configPitchCos = Math.cos((canvasPitch * Math.PI) / 180),
        yawCos = Math.cos(((-ctya + canvasYaw) * Math.PI) / 180);
      let z = hsPitchSin * configPitchSin + hsPitchCos * yawCos * configPitchCos;
      let yawSin = Math.sin(((-ctya + canvasYaw) * Math.PI) / 180);
      let hfovTan = Math.tan((canvasHfov * Math.PI) / 360);
      let canvas = viewer.getRenderer().getCanvas();
      let canvasWidth = canvas.clientWidth;
      let canvasHeight = canvas.clientHeight;
      let coord = [
        ((-canvasWidth / hfovTan) * yawSin * hsPitchCos) / z / 2,
        ((-canvasWidth / hfovTan) *
          (hsPitchSin * configPitchCos - hsPitchCos * yawCos * configPitchSin)) /
          z /
          2,
      ];
      coord[0] += (canvasWidth - dom.offsetWidth + 300) / 2;
      coord[1] += (canvasHeight - dom.offsetHeight - 560) / 2;
      let t = 'translate(' + coord[0] + 'px, ' + coord[1] + 'px) translateZ(9999px)';
      setTransform(t);
    }
  };

  useEffect(() => {
    if (tagState.displayDialogue) {
      renderDialogue();
    }
  });

  return (
    <>
      {tagState.displayDialogue && currentTag.type === 'SAFETY' && (
        <SafetyTag safetyTag={currentTag} transform={transform} hydrate={hydrate} />
      )}
    </>
  );
};
