import axios from 'axios';
import moment from 'moment';
import { getNewPointId, INoGoZone } from '../components/views/admin_building_page/components/ManageNoGoZones/ManageNoGoZones';
import { convertScheduleDate, getNextScan, getTimePieces } from '../components/views/admin_building_page/components/ManageSchedules/ScheduleHelperFunctions';
import { ISchedule, IScheduleDashboardEntry } from '../components/views/admin_building_page/components/ManageSchedules/SchedulesDashboard';
import { IMapPoint } from '../components/views/admin_building_page/components/ManagePoints/ManagedMapPoint';

const API_BASE = process.env.REACT_APP_NEXTERA_API;

const fetchFloorGroups = async (projectId: string, floorId: string) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/groups`
    );
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errFoorGroups==>>', err);
  }
}

const fetchFloorMappings = async (projectId: string, floorId: string) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/mappings`
    );
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errFoorMappings==>>', err);
  }
}

const fetchFloorSections = async (projectId: string, floorId: string) => {
  try {
    const res = await axios.get(
      `${API_BASE}/project/${projectId}/floor/${floorId}/sections`
    );
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('errFoorSections==>>', err);
  }
}

const updatePointActiveness = async (projectId: string, floorId: string, pointId: string, active: boolean) => {
  try {
    const res = await axios.patch(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/point/${pointId}`, {
        is_active: active
      }
    );
    const response = await res.data;

    return response.data;
  } catch (err) {
    console.log('updatePointActiveness==>>', err);
  }
}

const addPointToGroup = async (projectId: string, floorId: string, pointId: string, groupId: string) => {
  try {
    const res = await axios.post(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/mapping`, {
        point_id: pointId, 
        group_id: groupId
      }
    );
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('addPointToGroup==>>', err);
  }
}

const createPoint = async (projectId: string, floorId: string, x: number, y: number) => {
  try {
    const res = await axios.post(
      `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/point`, {
        x: x.toFixed(3),
        y: y.toFixed(3),
      }
    );
    const response = await res.data;
    return response.data;
  } catch (err) {
    console.log('createPoint==>>', err);
  }
}

const updatePoint = async (projectId: string, floorId: string, pointId: string | number, data: Partial<IMapPoint>) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/point/${pointId}`,
    data
  );

  const response = await res.data;
  return response.data;
}

const deletePoint = async (projectId: string, floorId: string, pointId: string) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/point/${pointId}`
  );
  const response = await res.data;
  return response.data;
}

const createGroup = async (projectId: string, floorId: string, groupName: string) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/group`, {
      name: groupName
    }
  );
  const response = await res.data;
  return response.data;
};

const deleteGroup = async (projectId: string, floorId: string, groupId: number) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/floor/${floorId}/viewpoints/group/${groupId}`
  );
  const response = await res.data;
  return response.data;
}

const formatZonePointsForSubmit = (zone: INoGoZone) => {
  const mappedPoints = zone.points.map(point => {
    const pointId = !!point.id ? point.id : getNewPointId();

    return `${point.x},${point.y},${pointId}`;
  });

  return mappedPoints.join(' ');
}

const formatReturnedZone = (zone: any) => {
  zone.points = zone.points.split(' ');
  zone.points = zone.points.map((point: any) => {
    const coordinates = point.split(',');
    const x = parseInt(coordinates[0]);
    const y = parseInt(coordinates[1]);
    const id = coordinates[2];

    return {
      id,
      zone_id: zone.id,
      x,
      y,
    }
  });

  return zone;
}

const fetchFloorNoGoZones = async (projectId: string, floorId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zones`
  );
  const response = await res.data;

  response.data.forEach((zone: any) =>{
    zone = formatReturnedZone(zone);
  })

  return response.data;
}

const createNoGoZone = async (projectId: string, floorId: string, zone: INoGoZone) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zone`,
    {
      name: zone.name,
      points: formatZonePointsForSubmit(zone)
    }
  );
  const response = await res.data;
  return formatReturnedZone(response.data);
}

const updateNoGoZone = async (projectId: string, floorId: string, zone: INoGoZone) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zone/${zone.id}`,
    {
      name: zone.name,
      points: formatZonePointsForSubmit(zone)
    }
  );
  const response = await res.data;
  return formatReturnedZone(response.data);
}

const updateNoGoZoneActiveness = async (projectId: string, floorId: string, zoneId: number, isActive: boolean) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zone/${zoneId}`,
    {
      is_active: isActive
    }
  );
  const response = await res.data;
  return formatReturnedZone(response.data);
}

const updateNoGoZoneName = async (projectId: string, floorId: string, zoneId: number, newName: string) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zone/${zoneId}`,
    {
      name: newName
    }
  );
  const response = await res.data;
  return formatReturnedZone(response.data);
}

const deleteNoGoZone = async (projectId: string, floorId: string, zoneId: number) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/floor/${floorId}/no-go-zone/${zoneId}`
  );
  const response = await res.data;
  return formatReturnedZone(response.data);
}

const fetchSchedules = async (projectId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/schedules`
  );
  const response = await res.data;

  const today = new Date();

  response.data.forEach((schedule: IScheduleDashboardEntry) => {
    const timePieces = getTimePieces(schedule.time);
    const converted_time = new Date(today);
    converted_time.setHours(timePieces[0], timePieces[1], 0)
    
    schedule.frequencyFormatted = (schedule.frequency as any).capitalize();

    schedule.converted_time = converted_time;
    schedule.formatted_time = moment(schedule.time, "HH:mm:ss").format("h:mm A");

    const { nextScan, nextScanFormatted, isExpired, lessThan24HoursAway } = getNextScan(schedule.frequency, schedule.start_date, schedule.till_date, schedule.time);

    schedule.nextScan = nextScan;
    schedule.nextScanFormatted = nextScanFormatted;
    schedule.nextScanExpired = isExpired;
    schedule.nextScanLessThan24HoursAway = lessThan24HoursAway;
  });

  return response.data;
}

const fetchSchedule = async (projectId: string, scheduleId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/schedule/${scheduleId}`
  );
  const response = await res.data;

  console.log(response.data);

  const timePieces = getTimePieces(response.data.time);

  response.data.start_date = convertScheduleDate(response.data.start_date);
  response.data.start_date.setHours(timePieces[0], timePieces[1], 0);
  
  response.data.till_date = convertScheduleDate(response.data.till_date);
  response.data.till_date.setHours(timePieces[0], timePieces[1], 0);

  return response.data;
}

const fetchSchedulePoints = async (projectId: string, scheduleId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/schedule/${scheduleId}/points`
  );
  const response = await res.data;
  return response.data;
}

const createNewSchedule = async (projectId: string, formData: ISchedule) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/schedule`,
    formData
  );
  const response = await res.data;

  response.data.start_date = convertScheduleDate(response.data.start_date);
  response.data.till_date = convertScheduleDate(response.data.till_date);

  return response.data;
}

const saveSchedule = async (projectId: string, formData: ISchedule) => {
  const res = await axios.patch(
    `${API_BASE}/project/${projectId}/schedule/${formData.id}`,
    formData
  );
  const response = await res.data;

  response.data.start_date = convertScheduleDate(response.data.start_date);
  response.data.till_date = convertScheduleDate(response.data.till_date);
  
  return response.data;
}

const createSchedulePointMapping = async (projectId: string, scheduleId: string, pointId: number) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/schedule/${scheduleId}/point`, {
      point_id: pointId
    }
  );
  const response = await res.data;
  return response.data;
}

const deleteSchedulePointMapping = async (projectId: string, scheduleId: string, pointId: number) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/schedule/${scheduleId}/point/${pointId}`
  );
  const response = await res.data;
  return response.data;
}

const deleteSchedule = async (projectId: string, scheduleId: number | string) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/schedule/${scheduleId}`
  );
  const response = await res.data;
  return response.data;
}

const fetchFloorEquipment = async (projectId: string, floorId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/floor/${floorId}/equipment`
  );
  const response = await res.data;
  return response.data;
}

const fetchProjectUsers = async (projectId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/workers`
  );
  const response = await res.data;
  return response.data;
}

const fetchProjectRoles = async (projectId: string) => {
  const res = await axios.get(
    `${API_BASE}/project/${projectId}/roles`
  );
  const response = await res.data;
  return response.data;
}

const addProjectUserRoleMapping = async (projectId: string, userId: string, roleId: number) => {
  const res = await axios.post(
    `${API_BASE}/project/${projectId}/worker/${userId}/role`, 
    {
      role_id: roleId
    }
  );
  const response = await res.data;
  return response.data;
}

const deleteProjectUserRoleMapping = async (projectId: string, userId: string, roleId: number) => {
  const res = await axios.delete(
    `${API_BASE}/project/${projectId}/worker/${userId}/role/${roleId}`
  );
  const response = await res.data;
  return response.data;
}

export {
  fetchFloorGroups,
  fetchFloorMappings,
  fetchFloorSections,
  updatePointActiveness,
  addPointToGroup,
  createPoint,
  updatePoint,
  deletePoint,
  createGroup,
  deleteGroup,
  fetchFloorNoGoZones,
  createNoGoZone,
  updateNoGoZone,
  updateNoGoZoneActiveness,
  updateNoGoZoneName,
  deleteNoGoZone,
  fetchSchedules,
  fetchSchedule,
  fetchSchedulePoints,
  createNewSchedule,
  saveSchedule,
  createSchedulePointMapping,
  deleteSchedulePointMapping,
  deleteSchedule,
  fetchFloorEquipment,
  fetchProjectUsers,
  fetchProjectRoles,
  addProjectUserRoleMapping,
  deleteProjectUserRoleMapping,
};