import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import Flex from '@react-css/flex';
import axios from 'axios';
import styled from 'styled-components';
import { Facebook } from 'react-spinners-css';

import { useBuildingContext } from '../../../../contexts/buildingContext';
import { listObservations, listObservationStats, Observation } from '../../../../api/observations';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { QuickDateSelector } from '../../../common/QuickDateSelector/QuickDateSelector';
import { DetailPane } from './DetailPane/DetailPane';
import { SafetyCircle } from './SafetyCircle/SafetyCircle';
import { GalleryTile } from './GalleryTile/GalleryTile';
import { useSafetyContext } from '../../../../contexts/safetyContext';
import { Icon } from '../../../common/Icon';
import iconFilterBlue from '../../../../assets/images/icon_filter_blue.svg';
import iconFilterWhite from '../../../../assets/images/icon_filter_white.svg';

import { SafetyViewModeMenu } from '../SafetyViewModeMenu/SafetyViewModeMenu';
import { ViewerPosition } from '../../image_viewer/types';
import { SeverityFilter } from './Filters/SeverityFilter';
import { FloorFilter } from './Filters/FloorFilter';
import { CategoryFilter } from './Filters/CategoryFilter';

let observationCancelTokenSource = axios.CancelToken.source();

// Move these to data folder
const TABS = [
  { text: 'New', field: 'claimed', value: false },
  { text: 'Approved', field: 'claimed', value: true },
  { text: 'Archived', field: 'archived', value: true },
  { text: 'Manual', field: 'is_manual', value: true },
];

interface SafetyGalleryFilterProps {
  excludeFilterOptions?: string[];
  galleryMainContainerHeight?: string;
  showSafetyViewModeMenu?: boolean;
  showSafetyCircle?: boolean;
}

export const SafetyGallery = ({
  excludeFilterOptions = [],
  galleryMainContainerHeight = '72vh',
  showSafetyViewModeMenu = false,
  showSafetyCircle = false,
}: SafetyGalleryFilterProps) => {
  const { state: BuildingState } = useBuildingContext();
  const { state: SafetyState, updateSafety } = useSafetyContext();
  const [viewerPosition, setViewerPosition] = useState<ViewerPosition>({
    yaw: 0,
    pitch: 0,
    hfov: 120,
  });
  const [localLoad, setLocalLoad] = useState<boolean>(true);
  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [dateRange, setDateRange] = useState<any>();
  const [nextPage, setNextPage] = useState<string | null>();
  const [stats, setStats] = useState({
    total: 0,
    claimed: 0,
    unclaimed: 0,
    safe: 0,
    unsafe: 0,
    archived: 0,
    severity: {
      low: 0,
      medium: 0,
      high: 0,
      life_threat: 0,
    },
  });

  const filterContainerRef = useRef<HTMLDivElement>(null);

  const filterObservations = async () => {
    let listFilters: any = {
      start: dateRange?.startDate.toISOString() || '2000-01-01T00:00:00Z',
      end: dateRange?.endDate.toISOString() || '2000-01-01T00:00:00Z',
      limit: 10,
      claimed: false,
      has_labels: true,
      ...filters,
    };

    if (!listFilters.archived) listFilters.archived = false;
    let queryString = new URLSearchParams(listFilters);
    let data = await listObservations(
      BuildingState.projectId,
      queryString,
      observationCancelTokenSource
    );
    setNextPage(data.meta.next);
    updateSafety({ observations: data.data, activeObservation: data.data[0] });
    setLocalLoad(false);
  };

  const loadMoreObservations = () => {
    if (nextPage) {
      setLocalLoad(true);
      axios
        .get(nextPage)
        .then(d => d.data)
        .then(data => {
          setNextPage(data.meta.next);
          updateSafety({ observations: SafetyState.observations.concat(data.data) });
          setLocalLoad(false);
        });
    }
  };

  const filterStats = useCallback(() => {
    let listFilters: any = {
      start: dateRange?.startDate.toISOString(),
      end: dateRange?.endDate.toISOString(),
      claimed: false,
      has_labels: true,
      ...filters,
    };

    if (!listFilters.archived) listFilters.archived = false;

    listObservationStats(BuildingState.projectId, listFilters).then(d => {
      setStats(d);
    });
  }, [BuildingState.projectId, dateRange?.endDate, dateRange?.startDate, filters]);

  const handleSeverityChange = useCallback(newSeverity => {
    setFilters(current => ({ ...current, severity: newSeverity }));
  }, []);

  const handleFloorChange = useCallback(newFloor => {
    setFilters(current => ({ ...current, floor: newFloor }));
  }, []);

  const handleCategoryChange = useCallback(newCategory => {
    setFilters(current => ({ ...current, type_id: newCategory }));
  }, []);

  useEffect(() => {
    return () => {
      observationCancelTokenSource.cancel();
      observationCancelTokenSource = axios.CancelToken.source();
    };
  }, []);

  useEffect(() => {
    if (dateRange) {
      filterStats();
    }
  }, [dateRange, filterStats]);

  /*
  Load more observations when saving and archiving
   */
  useEffect(() => {
    if (SafetyState.observations.length < 6 && !localLoad) {
      loadMoreObservations();
    }
  }, [SafetyState.observations]);

  /*
  Load new observations when filter selected
   */
  useMemo(() => {
    setLocalLoad(true);
    filterObservations();
  }, [filters, dateRange]);

  const filteredObservations = useMemo(() => {
    const safetyObservations = [...SafetyState.observations];
    let filteredObservations: any[] = [];

    if (safetyObservations) {
      const seenIds = new Set();

      safetyObservations.forEach((observation: Observation) => {
        if (!seenIds.has(observation.id)) {
          seenIds.add(observation.id);
          filteredObservations.push(observation);
        }
      });
    }

    return filteredObservations;
  }, [SafetyState.observations]);

  return (
    <SafetyGalleryContainer>
      <DateProgressRow>
        <DateProgressCol ref={filterContainerRef}>
          <Flex>
            <FloorFilter onChange={handleFloorChange} />
            <SeverityFilter onChange={handleSeverityChange} />
            <CategoryFilter onChange={handleCategoryChange} />
            <QuickDateSelector onChange={setDateRange} styles={{ height: '50px' }} />
            <UnreadBox>
              <span>{stats.unclaimed}</span>
              <span style={{ color: '#ccc', fontSize: '0.6em' }}>New</span>
            </UnreadBox>
            {showSafetyCircle && (
              <SafetyCircle
                stats={[
                  { value: stats.safe, label: 'safe', color: '#76C085' },
                  { value: stats.unsafe, label: 'unsafe', color: '#F8CE65' },
                ]}
              />
            )}
          </Flex>
        </DateProgressCol>
        {showSafetyViewModeMenu && (
          <>
            <SafetyViewModeMenu containerStyle={{ alignItems: 'center' }} />
            <div style={{ width: `${filterContainerRef.current?.clientWidth ?? 450}px` }} />
          </>
        )}
      </DateProgressRow>
      <GalleryContainer>
        <GalleryMainContainer height={galleryMainContainerHeight}>
          <GalleryTilesContainer>
            {localLoad && <LoadingIndicator />}
            {filteredObservations.map((observation: Observation) => (
              <GalleryTile
                key={observation.id}
                observation={observation}
                active={SafetyState.activeObservation?.id === observation.id}
                onClick={() => updateSafety({ activeObservation: observation })}
              />
            ))}
            {!localLoad && !filteredObservations.length && (
              <div style={{ width: '100%', textAlign: 'center', marginTop: '2em' }}>
                <h1>No Observations matching criteria</h1>
              </div>
            )}
            {nextPage && (
              <LoadMoreButton onClick={loadMoreObservations}>
                {localLoad && <Facebook color="#073C7A" style={{ margin: '0 5px' }} size={14} />}{' '}
                Load More
              </LoadMoreButton>
            )}
          </GalleryTilesContainer>
          <GalleryDetailsContainer>
            {!localLoad && SafetyState.activeObservation ? (
              <DetailPane
                viewerId="safety"
                observation={SafetyState.activeObservation}
                viewerPosition={viewerPosition}
                onChangeViewerPosition={setViewerPosition}
                // showApproveButton
                showArchiveButton
              />
            ) : (
              localLoad && <LoadingIndicator />
            )}
          </GalleryDetailsContainer>
        </GalleryMainContainer>
      </GalleryContainer>
    </SafetyGalleryContainer>
  );
};

const SafetyGalleryContainer = styled.div`
  width: 100%;
  //max-width: 1700px;
  margin: 1em auto 0 auto;
  overflow-y: hidden;
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
`;
SafetyGalleryContainer.displayName = 'SafetyGalleryContainer';

const DateProgressRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 50px;
  margin-top: 1.5em;
`;
DateProgressRow.displayName = 'DateProgressRow';

const FilterButton = styled.div<any>`
  display: flex;
  align-items: center;
  background: ${(props: any) => (props.active ? '#073c7a' : '#ffffff')};
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #f4f5f9;
`;
FilterButton.displayName = 'FilterButton';

const UnreadBox = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: ${(props: any) => (props.active ? '#073c7a' : '#ffffff')};
  color: #073c7a;
  font-size: 0.8em;
  padding: 0 15px;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  border: 1px solid #f4f5f9;
`;
UnreadBox.displayName = 'UnreadBox';

const DateProgressCol = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
DateProgressCol.displayName = 'DateProgressCol';

const GalleryContainer = styled.div`
  margin-top: 1.5em;
`;
GalleryContainer.displayName = 'GalleryContainer';

const GalleryMainContainer = styled.div<{ height?: string }>`
  display: flex;
  justify-content: stretch;
  gap: 10px;
  height: ${props => props.height};
`;
GalleryMainContainer.displayName = 'GalleryMainContainer';

const GalleryTilesContainer = styled.div`
  gap: 10px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content; start;
  overflow-y: auto;
  width: 450px;
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    margin: 10px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
    margin: 10px 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }

`;
GalleryTilesContainer.displayName = 'GalleryTilesContainer';

const GalleryDetailsContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    margin: 10px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
    margin: 10px 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
`;
GalleryDetailsContainer.displayName = 'GalleryDetailsContainer';

const LoadMoreButton = styled.div`
  border-radius: 2px;
  border: 1px solid #073c7a;
  color: #073c7a;
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
LoadMoreButton.displayName = 'LoadMoreButton';
