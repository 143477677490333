import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useBuildingContext } from '../../../../../contexts/buildingContext';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#073c7a' : '#F4F5F9',
    boxShadow: 'none',
    minHeight: '50px',
    minWidth: 150,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#073c7a',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '6px',
  }),
};

interface FloorFilterProps {
  onChange?: (severity: string) => void;
}

export const FloorFilter = ({ onChange }: FloorFilterProps) => {
  const { state: buildingState } = useBuildingContext();

  const handleChange = useCallback(
    newValue => {
      onChange && onChange(newValue.value);
    },
    [onChange]
  );

  const defaultOption = useMemo(() => {
    return { value: '', label: 'Any Floor' };
  }, []);

  const options = useMemo(() => {
    return [
      defaultOption,
      ...buildingState.projectData.floors.map((floor: any) => ({
        value: floor.floor_code,
        label: floor.name,
      })),
    ];
  }, [buildingState.projectData.floors, defaultOption]);

  return (
    <Select
      onChange={handleChange}
      options={options}
      defaultValue={defaultOption}
      styles={customStyles}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#073c7a',
        },
      })}
    />
  );
};
