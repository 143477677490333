import { useCallback, useMemo, useState } from "react";
import { Table } from "../../common/Table/Table";
import { SiteWalkUploadData } from "./SiteWalkVideoUpload";
import { Column } from "react-table";
import styled from "styled-components";
import { Sitewalk } from "../../../api/sitewalk";
import { AddProjectButton } from "../buildings_page/components/AddProjectButton";
import iconClose from '../../../assets/images/icon_close.svg';
import { CreateNewSiteWalkModal } from "./CreateNewSiteWalkModal";

interface IUnassociatedVideosTableProps {
  uploadData: SiteWalkUploadData[];
  siteWalksPendingUpload: Sitewalk[];
  onSelectSiteWalk: (data: SiteWalkUploadData, walk: Sitewalk) => void;
  onClickUpload: (data: SiteWalkUploadData) => void;
  onRemoveSiteWalk: (data: SiteWalkUploadData) => void;
  onCreateNewSiteWalk: (data: Sitewalk) => void;
}

export const UnassociatedVideosTable = ({
  uploadData,
  siteWalksPendingUpload,
  onSelectSiteWalk,
  onClickUpload,
  onRemoveSiteWalk,
  onCreateNewSiteWalk,
}: IUnassociatedVideosTableProps) => {
  console.log(uploadData);

  const [selectedWalks, setSelectedWalks] = useState(new Set<number>());
  const [walksSentToUpload, setWalksSentToUpload] = useState(new Set<number>());
  const [creatingNewWalk, setCreatingNewWalk] = useState<boolean>(false);

  const data = useMemo(() => {
    return uploadData;
  }, [uploadData]);

  const formatWalkDate = (walkDate?: string) => {
    if (walkDate) {
      const walkDateObject = new Date(walkDate);
      const dateString = walkDateObject.toLocaleDateString();
      const timeString = walkDateObject.toLocaleTimeString().replace(/:\d\d\s/, ' ');
      
      return dateString + ' ' + timeString;
    }

    return '';
  }

  const generateWalkTitle = useCallback((walk: Sitewalk) => {
    return `${walk.project.name} - ${walk.project_floor.floor_code} - ${walk.group_name} - ${formatWalkDate(walk.taken_on)}`
  }, []);

  const renderSiteWalkSelect = useCallback((uploadData: SiteWalkUploadData) => {
    const currentSelection = uploadData.siteWalk?.id;

    return (
      <SiteWalkSelect
        key={uploadData.id}
        value={currentSelection}
        onChange={e => {
          const selectedWalk = siteWalksPendingUpload.filter(pendingWalk => pendingWalk.id === parseInt(e.target.value))[0];

          onSelectSiteWalk(uploadData, selectedWalk);

          const updatedSelectedWalks = new Set(selectedWalks);
          updatedSelectedWalks.add(selectedWalk.id);

          if (currentSelection) {
            updatedSelectedWalks.delete(currentSelection);
          }

          setSelectedWalks(updatedSelectedWalks);
        }}
      >
        <option key='empty' selected disabled hidden></option>
        {siteWalksPendingUpload.map(pendingWalk => {
          const walkIsSelected = selectedWalks.has(pendingWalk.id);
          const walkSentToUpload = walksSentToUpload.has(pendingWalk.id);

          if ((walkIsSelected || walkSentToUpload) && currentSelection !== pendingWalk.id) {
            return <></>;
          }

          return (
            <option
              key={pendingWalk.id}
              value={pendingWalk.id}
            >
              {generateWalkTitle(pendingWalk)}
            </option>
          )
        })}
      </SiteWalkSelect>
    )
  }, [siteWalksPendingUpload, onSelectSiteWalk, selectedWalks, walksSentToUpload, generateWalkTitle]);

  const renderUploadButton = useCallback((walk: SiteWalkUploadData) => {
    const validForSubmit = !!walk.siteWalk;

    const handleClick = () => {
      onClickUpload(walk);
      
      const updatedSelectedWalks = new Set(selectedWalks);
      selectedWalks.delete((walk.siteWalk?.id) as number);
      setSelectedWalks(updatedSelectedWalks);

      const updatedWalksSentToUpload = new Set(walksSentToUpload);
      updatedWalksSentToUpload.add((walk.siteWalk?.id) as number);
      setWalksSentToUpload(updatedWalksSentToUpload);
    }

    return (
      <AddProjectButton
        text="Upload"
        onClick={handleClick}
        buttonStyle={{
          backgroundColor: validForSubmit ? '#073C7A' : '#073C7A80',
          borderColor: validForSubmit ? '#073C7A' : '#073C7A80',
          cursor: validForSubmit ? 'pointer' : 'default',
          color: 'white'
        }}
      />
    )
  }, [onClickUpload, selectedWalks, walksSentToUpload]);

  const renderRemoveButton = useCallback((walk: SiteWalkUploadData) => {
    return (
      <CloseIconButtonContainer>
        <CloseIconButton
          onClick={() => onRemoveSiteWalk(walk)}
        >
          <img
            src={iconClose}
            alt=""
            style={{height: '12px', width: 'auto'}}
          />
        </CloseIconButton>
      </CloseIconButtonContainer>
    )
  }, [onRemoveSiteWalk]);

  const columns = useMemo((): Column<SiteWalkUploadData>[] => [
    { Header: 'File Name', accessor: walk => walk.videoFile.name },
    { Header: 'Select Site Walk', accessor: renderSiteWalkSelect},
    { Header: 'Upload', accessor: renderUploadButton},
    { Header: 'Remove', accessor: renderRemoveButton}
  ], [renderRemoveButton, renderSiteWalkSelect, renderUploadButton]);

  const onClickCreateNewWalk = () => {
    setCreatingNewWalk(true);
  }

  return (
    <SiteWalkVideoUploadTableContainer>
      <CreateNewWalkButtonContainer>
        <AddProjectButton
          text="Create New Site Walk"
          onClick={onClickCreateNewWalk}
        />
      </CreateNewWalkButtonContainer>
      <Table
        data={data}
        columns={columns}
        headerStyles={{
          padding: '5px 40px'
        }}
      />
      {creatingNewWalk &&
        <CreateNewSiteWalkModal
          isOpen={creatingNewWalk}
          setIsOpen={setCreatingNewWalk}
          onCreateNewSiteWalk={onCreateNewSiteWalk}
        />
      }
    </SiteWalkVideoUploadTableContainer>
  )
}

const SiteWalkVideoUploadTableContainer = styled.div`
  position: relative;
  overflow: auto;
  white-space: nowrap;
`;

const CreateNewWalkButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
`;

const SiteWalkSelect = styled.select`
  padding: 14px;
`;

const CloseIconButton = styled.div`
  background-color: #00000077;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;

const CloseIconButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;