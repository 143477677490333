import { useMsal } from "@azure/msal-react";
import { useCallback, useRef } from "react";
import { loginRequest } from "../msAuthConfig";
import { TEMP_LOG_FUNCTION, formatTokenResponseData, msTokenGenerate } from "../api/auth";
import { useHandleDbTokenResponse } from "./useHandleDbTokenResponse";
import { useHistory } from "react-router-dom";

export const useAcquireMsalToken = () => {
  const { instance } = useMsal();
  const activeAccount = instance.getActiveAccount();
  const msalTokenAquisitionAttempted = useRef<boolean>(false);
  const handleTokenResponse = useHandleDbTokenResponse();

  const history = useHistory();

  const generateTokenFromMsToken = useCallback(async (token: string, redirectOnFailure?: boolean) => {
    try {
      const tokenResponse = await msTokenGenerate(token);
      handleTokenResponse(formatTokenResponseData(tokenResponse));
    } catch (e) {
      if (redirectOnFailure) {
        history.push("/login", {errorMessage: "Unable to authenticate"});
      }
    }
  }, [handleTokenResponse, history]);

  return useCallback(async (redirectOnFailure?: boolean) => {
    if (instance) {
      await instance.initialize();

      if (activeAccount && !msalTokenAquisitionAttempted.current) {
        msalTokenAquisitionAttempted.current = true;
        instance.acquireTokenSilent(loginRequest).then((response) => {
          msalTokenAquisitionAttempted.current = true;
          if (response && response.accessToken) {
            console.log("Acquire token silent success", response);
            TEMP_LOG_FUNCTION(`Acquire token silent success: ${JSON.stringify(response)}`);
            generateTokenFromMsToken(response.accessToken, redirectOnFailure);
          } else {
            msalTokenAquisitionAttempted.current = false;
          }
        }).catch((e: any) => {
          console.error("Acquire token silent failed", e);
          TEMP_LOG_FUNCTION(`Aquire token silent failed: ${JSON.stringify(e)}`);
          msalTokenAquisitionAttempted.current = false;
        });
      }
    } else {
      console.error("Msal instance not found");
      TEMP_LOG_FUNCTION("Msal instance not found");
      throw new Error("Msal instance not found");
    }
  }, [generateTokenFromMsToken, instance, activeAccount]);
}