import { IconButton } from "../../../common/IconButton";
import iconHexagon from '../../../../assets/images/icon_hexagon.svg';
import iconHexagonFilled from '../../../../assets/images/icon_hexagon_filled.svg';
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { IMapPoint } from "../../admin_building_page/components/ManagePoints/ManagedMapPoint";

interface ISelectAllPointsButtonProps {
  size: number;
  style?: React.CSSProperties;
}

export const SelectAllPointsButton = ({
  size,
  style
}: ISelectAllPointsButtonProps) => {
  const { 
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const allPointsSelected = buildingState.floorData.points.length === buildingState.floorData.selectedPoints.size;
  const icon = allPointsSelected ? iconHexagonFilled : iconHexagon;

  const onClickSelectAllPoints = () => {
    let updatedSelectedPoints = new Set<number>();

    if (!allPointsSelected) {
      const allPointIds = buildingState.floorData.points.map((point: IMapPoint) => point.point_id);

      updatedSelectedPoints = new Set(allPointIds);
    }

    updateFloor({
      selectedPoints: updatedSelectedPoints
    })
  }

  return (
    <IconButton
      icon={icon}
      size={size}
      onClick={onClickSelectAllPoints}
      style={style}
    />
  )
}