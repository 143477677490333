import styled from "styled-components";
import { useBuildingContext } from "../../../../../../../contexts/buildingContext";

export const ScheduleControlsStatistics = () => {
  const {
    state: buildingState
  } = useBuildingContext();

  const getNumberOfFloors = () => {
    let numFloors = 0;

    buildingState.scheduleMappings.forEach((pointSet: Set<number>) => {
      if (pointSet.size > 0) {
        numFloors++;
      }
    });

    return numFloors;
  }

  const euclideanDistance = (a: any, b: any) => {
    const p = a.x - b.x;
    const q = a.y - b.y;
    return Math.round(Math.sqrt(p*p + q*q) * 100) / 100;
  }

  const heuristicCalculator = (points: {x: number, y: number}[]) => {
    let area = 0;
    let perimeter = 0;

    for (let i=0; i<points.length; i++) {
      const currentPoint = points[i];
      const nextPoint = points[(i + 1) % points.length];

      area += (currentPoint.x * nextPoint.y - currentPoint.y * nextPoint.x);
      perimeter += euclideanDistance(points[1], nextPoint)
    }

    area = Math.round(area * 1000 / 2) / 1000;

    return { area, perimeter };
  }

  const getMissionTime = () => {
    let totalMins = 0;
    const numDistinctFloors = getNumberOfFloors();

    let hours = 0;
    let minutes = 0;

    if (numDistinctFloors === 0) {
      return { hours, minutes };
    }

    buildingState.scheduleMappings.forEach((points: Set<number>, floorCode: string) => {
      const floorNumPoints = points.size;
      let floorMins = 0;

      if (floorNumPoints > 0 && floorNumPoints <= 2) {
        floorMins = 10 + floorNumPoints;
      } else if (floorNumPoints > 2) {
        const convexHullCalculator = buildingState.convexHullCalculatorMappings.get(floorCode);
        const points = convexHullCalculator.getHull();
        const { area, perimeter } = heuristicCalculator(points);

        floorMins = 10 + (floorNumPoints * 1.1) + (area / (perimeter * 0.3 * 60));
      }

      totalMins += floorMins;
    });

    totalMins += ((numDistinctFloors - 1) * 30);

    hours = Math.floor(totalMins / 60);
    minutes = Math.round(totalMins % 60);

    return { hours, minutes };
  }

  const generateMissionTimeString = () => {
    const { hours, minutes } = getMissionTime();

    if (hours === 0) {
      return `${minutes} mins`
    } else {
      return `${hours} hrs ${minutes} mins`;
    }
  }

  const numFloors = getNumberOfFloors();
  const numViewpoints = buildingState.floorData.selectedPoints.size;

  return (
    <Statistics>
      <div>{`${numViewpoints} Viewpoint${numViewpoints !== 1 ? 's' : ''}`}</div>
      <div>{generateMissionTimeString()}</div>
      <div>{`${numFloors} Floor${numFloors !== 1 ? 's' : ''}`}</div>
    </Statistics>
  )
}

const Statistics = styled.div`
  margin-top: 10px;
  color: #A7AABD;
  font-size: 12px;
  line-height: 15.3px;
`;