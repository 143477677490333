import React, { useState } from 'react';
import styled from 'styled-components';

export const MenuItemLi = styled.li`
    &:hover{
        background-color: #073C7A;
        color: #fff;
    }
    line-height: 1.5em;
    font-size: 0.9em;
    padding 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

interface IMenuItemProps {
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
  onClickCapture?: () => void;
  className?: string;
  icon?: string;
  hoverIcon?: string;
  style?: React.CSSProperties;
}

export const MenuItem = ({
  children,
  onClick,
  onClickCapture,
  className,
  icon,
  hoverIcon,
  style,
}: React.PropsWithChildren<IMenuItemProps>) => {
  const [hovering, setHovering] = useState<boolean>(false);

  return (
    <MenuItemLi
      onClick={onClick}
      onClickCapture={onClickCapture}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      className={className}
      style={style}
    >
      {icon &&
        <img
          src={hovering ? hoverIcon : icon}
          style={{
            marginRight: '12px',
            width: '14px'
          }}
          alt="menu item icon"
        />
      }
      {children}
    </MenuItemLi>
  )
}

export const ContextMenuContainer = styled.div<{width?: number}>`
  position: fixed;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  padding: 5px;
  width: ${props => props.width ?? 212}px;
  border: none;
  border-radius: 2px;
  font-size: 16px;
  color: #212121;
`;
