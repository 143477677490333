interface StringMap {
  [key: string]: any;
}
export const SEVERITY_COLOR_MAP: StringMap = {
  SAFE: '#76C085',
  SEVERITY_LOW: '#F8CE65',
  SEVERITY_MEDIUM: '#EF7E46',
  SEVERITY_HIGH: '#FF0000',
  SEVERITY_LIFE_THREAT: '#6B53FD',
};

export const simple_color_map: StringMap = {
  safe: '#76C085',
  low: '#F7CD65',
  medium: '#EF7E46',
  high: '#FF5252',
  life_threat: '#6365D9',
};
