import classNames from 'classnames';
import React from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { MapPointContainer, MapPointMainCircle } from '../../../../common/MapViewer';
import { IMapPoint } from '../ManagePoints/ManagedMapPoint';
import { useManageSchedulesContext } from './ManageSchedulesContext';

interface ISchedulePointProps {
  x: number;
  y: number;
  point: IMapPoint;
  onClick: (point: IMapPoint) => void;
  disabled?: boolean;
}

export const SchedulePoint = ({
  point,
  x,
  y,
  onClick,
  disabled,
}: ISchedulePointProps) => {
  const {
    state: buildingState
  } = useBuildingContext();

  const {
    canCreateSchedulePointMapping,
    canDeleteSchedulePointMapping,
  } = useManageSchedulesContext();

  const pointIsSelected: boolean = buildingState.floorData.selectedPoints.has(point.point_id);
  const inCurrentGroup: boolean = buildingState.floorData.groupPoints.has(point.point_id);

  return (
    <MapPointContainer
      className={`map-point mp-${point.point_id}`}
      x={x}
      y={y}
      isActive={canCreateSchedulePointMapping || canDeleteSchedulePointMapping}
      onClick={() => onClick(point)}
    >
      <MapPointMainCircle
        className={classNames({
          back_pink:  pointIsSelected && !disabled,
          back_pink_empty: !pointIsSelected && !disabled,
          back_gray: pointIsSelected && disabled,
          back_gray_empty: !pointIsSelected && disabled,
          back_blue: inCurrentGroup,
        })}
      />
    </MapPointContainer>
  )
}