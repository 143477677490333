import React from "react";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { blue, gray, pink } from "../../../../../styles/colors";
import { BoundaryPoint } from "./BoundaryPoint";
import { GhostPoint } from "./GhostPoint";
import { IBoundaryPoint, INoGoZone } from "./ManageNoGoZones";
import { useNoGoZoneContext } from "./NoGoZoneContext";

interface INoGoZoneProps {
  zone: INoGoZone;
  getTouchXAndY: (e: React.TouchEvent) => number[];
}

export const NoGoZone = ({
  zone,
  getTouchXAndY,
}: INoGoZoneProps) => {
  const {
    updateFloor,
    state: buildingState,
  } = useBuildingContext();

  const { selectedPoints, selectedNoGoZones } = buildingState.floorData;

  const {
    setCurrentDraggingZoneId,
    setDragStartX,
    setDragStartY,
    zoneDraggingOccurred,
    hoverNoGoZone,
    canEditZones,
  } = useNoGoZoneContext();

  const currentlyHovering: boolean = hoverNoGoZone === zone.id;
  const inSelectedZones: boolean = selectedNoGoZones.has(zone.id);

  const color: string = (currentlyHovering || inSelectedZones) ? blue : zone.is_active ? pink : gray;
  
  const points: JSX.Element[] = zone.points.map((point: IBoundaryPoint, i: number, arr: IBoundaryPoint[]) => (
    <React.Fragment key={point.id}>
      <BoundaryPoint
        key={i}
        point={point}
        zone={zone}
      />
      { canEditZones &&
        <GhostPoint
          beforePoint={point}
          afterPoint={arr[(i+1) % arr.length]}
          zone={zone}
        />
      }
    </React.Fragment>
  ));

  points.push(
    <BoundaryPoint
      key={zone.points.length}
      point={zone.points[0]}
      zone={zone}
    />
  )

  const onDragZoneStart = (e: React.FormEvent, x: number, y: number) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!canEditZones) {
      return;
    }

    setCurrentDraggingZoneId(zone.id);
    setDragStartX(x);
    setDragStartY(y);
  }

  const onMouseDown = (e: React.MouseEvent<SVGPolygonElement>) => {
    onDragZoneStart(e, e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  }

  const onTouchStart = (e: React.TouchEvent<SVGPolygonElement>) => {
    const [x,y] = getTouchXAndY(e);

    onDragZoneStart(e, x, y);
  }

  const onToggleZonePoints = () => {
    const updatedSelectedPoints = new Set(selectedPoints);

    const everyPointSelected: boolean = zone.points.every((point: IBoundaryPoint) => updatedSelectedPoints.has(point.id));

    const clickedZone = buildingState.floorData.noGoZones.get(zone.id);

    clickedZone.points.forEach((point: IBoundaryPoint) => {
      if (everyPointSelected) {
        updatedSelectedPoints.delete(point.id);
      } else {
        updatedSelectedPoints.add(point.id);
      }
    });

    const updatedSelectedNoGoZones = new Set(selectedNoGoZones);

    if (everyPointSelected) {
      updatedSelectedNoGoZones.delete(clickedZone.id);
    } else {
      updatedSelectedNoGoZones.add(clickedZone.id);
    }

    updateFloor({
      selectedPoints: updatedSelectedPoints,
      selectedNoGoZones: updatedSelectedNoGoZones
    })
  }

  const onDragEnd = () => {
    if (!canEditZones) {
      return;
    }

    if (!zoneDraggingOccurred) {
      onToggleZonePoints();
    }

    setCurrentDraggingZoneId(null);
  }

  const onDoubleClick = (e: React.MouseEvent<SVGPolygonElement>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  let fillOpacity = zone.drawing ? 0.1 : 0.6;

  return (
    <>
      <polygon 
        fill={color}
        stroke={color} 
        fillOpacity={fillOpacity} 
        points={`${zone.points.map((point: IBoundaryPoint) => `${point.x},${point.y} `)}`}
        onMouseUp={onDragEnd}
        onMouseDown={onMouseDown}
        onDoubleClick={onDoubleClick}
        onTouchStart={onTouchStart}
        onTouchEnd={onDragEnd}
        cursor={canEditZones ? 'pointer' : 'auto'}
      />
      {points}
    </>
  )
}