import { MapPointArrow, MapPointContainer, MapPointMainCircle, MapPointMainHighlighted, MapViewer } from "../../../common/MapViewer";
import { AddProjectButton } from "../../buildings_page/components/AddProjectButton";
import { useCallback, useMemo, useRef, useState } from "react";
import { Point } from "../../../../api/types";
import classNames from 'classnames';
import styled from "styled-components";
import { SiteWalkPoint } from "../../../../api/sitewalk";
import { LoadingIndicator } from "../../../common/LoadingIndicator";
import { useImageViewerContext } from "../../image_viewer/imageViewerContext";

const doubleClickWaitTime = 250;

interface SiteWalkFloorPlanViewProps {
  projectFloor: any;
  siteWalkPoints: SiteWalkPoint[];
  selectedPoints: SiteWalkPoint[];
  pointId: string;
  onClickPoint: (point: SiteWalkPoint[]) => void;
  onDoubleClickPoint: (point: SiteWalkPoint[]) => void;
  onClickApproveAllPoints: () => void;
  onClickRejectAllPoints: () => void;
  pointReviewInProgress: boolean;
  rotation?: number;
}

export const SiteWalkFloorPlanView = ({
  projectFloor,
  siteWalkPoints,
  selectedPoints,
  pointId,
  onClickPoint,
  onDoubleClickPoint,
  onClickApproveAllPoints,
  onClickRejectAllPoints,
  pointReviewInProgress,
  rotation,
}: SiteWalkFloorPlanViewProps) => {
  const {state: ImageViewerState} = useImageViewerContext();

  const [loadedImage, setLoadedImage] = useState<string>('');
  const clickInProgress = useRef<boolean>(false);
  const lastClick = useRef<number>(0);

  const selectedSiteWalkViewPoints = useMemo(() => {
    const pointSet = new Set<number>();

    selectedPoints.forEach(point => pointSet.add(point.viewpoints_image.sub_point_id));

    return pointSet;
  }, [selectedPoints]);

  const siteWalkImagesMap = useMemo(() => {
    const imagesMap = new Map<number, SiteWalkPoint[]>();

    siteWalkPoints.forEach(point => {
      const viewpointSubId = point.viewpoints_image.sub_point_id;
      const currentViewpointImages = imagesMap.get(viewpointSubId) ?? [];
      currentViewpointImages.push(point);

      imagesMap.set(viewpointSubId, currentViewpointImages);
    });

    return imagesMap;
  }, [siteWalkPoints]);

  const onClickMapPointContainer = useCallback((point: SiteWalkPoint[]) => {
    lastClick.current = new Date().getTime();

    if (clickInProgress.current) {
      return;
    }

    clickInProgress.current = true;

    setTimeout(() => {
      const currentClick = new Date().getTime();
      const isDoubleClick = currentClick - lastClick.current <= doubleClickWaitTime;

      if (isDoubleClick) {
        onDoubleClickPoint(point);
      } else {
        onClickPoint(point);
      }

      clickInProgress.current = false;
    }, doubleClickWaitTime);
  }, [onClickPoint, onDoubleClickPoint]);

  const SiteWalkPointCircles = useMemo(() => {
    if (projectFloor.points) {
      
      return projectFloor.points.map((point: Point) => {
        const pointSelected = selectedSiteWalkViewPoints.has(parseInt(point.point_id));
        const siteWalkPointImages = siteWalkImagesMap.get(parseInt(point.point_id)) ?? [];
        const pointHasImages = siteWalkPointImages.length > 0;
        const currentlyDisplayedImage = siteWalkPointImages.find(point => point.viewpoints_image.id === ImageViewerState?.master?.data?.id);

        let pointIsReviewed = false;
        let pointIsApproved = false;

        if (siteWalkPointImages.length === 1) {
          pointIsReviewed = siteWalkPointImages[0].viewpoints_image.is_reviewed;
          pointIsApproved = siteWalkPointImages[0].viewpoints_image.is_approved;
        } else if (currentlyDisplayedImage) {
          pointIsReviewed = currentlyDisplayedImage.viewpoints_image.is_reviewed;
          pointIsApproved = currentlyDisplayedImage.viewpoints_image.is_approved;
        }

        const isActivePoint = point.point_id.toString() === pointId;

        const mainCircleProps: any = {}

        if (siteWalkPointImages.length <= 1 || currentlyDisplayedImage) {
          const showGreen = pointHasImages && pointIsReviewed && pointIsApproved;
          const showRed = pointHasImages && pointIsReviewed && !pointIsApproved;
          const showYellow = pointHasImages && !pointIsReviewed;

          mainCircleProps.className=classNames({
            back_red: pointSelected && showRed,
            back_red_empty: !pointSelected && showRed,
            back_green: pointSelected && showGreen,
            back_green_empty: !pointSelected && showGreen,
            border_white: isActivePoint && pointSelected,
            back_yellow: pointSelected && showYellow,
            back_yellow_empty: !pointSelected && showYellow,
          });
        } else {
          const showGreen = siteWalkPointImages.some(point => point.viewpoints_image.is_reviewed && point.viewpoints_image.is_approved);
          const showRed = siteWalkPointImages.some(point => point.viewpoints_image.is_reviewed && !point.viewpoints_image.is_approved);
          const showYellow = siteWalkPointImages.some(point => !point.viewpoints_image.is_reviewed);

          const backgroundColors = [...showGreen ? ['green'] : [], ...showRed ? ['red'] : [], ...showYellow ? ['yellow'] : []];

          mainCircleProps.backgroundColors = pointSelected ? backgroundColors : undefined;
          mainCircleProps.borderColors = !pointSelected ? backgroundColors : undefined;
        }

        return (
          <MapPointContainer
            key={point.point_id}
            className={`map-point mp-${point.point_id}`}
            x={point.x}
            y={point.y}
            isActive={pointHasImages}
            onClick={() => {
              if (pointHasImages) {
                onClickMapPointContainer(siteWalkPointImages);
              }
            }}
          >
            {(isActivePoint && rotation !== undefined) &&
              <MapPointArrow
                rotation={rotation}
              />
            }
            <MapPointMainCircle
              {...mainCircleProps}
            />
            
            {isActivePoint &&
              <MapPointMainHighlighted/>
            }
          </MapPointContainer>
        )
      });
    }

    return [];
  }, [ImageViewerState?.master?.data?.id, onClickMapPointContainer, pointId, projectFloor.points, rotation, selectedSiteWalkViewPoints, siteWalkImagesMap]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '0px',
        bottom: '0px',
        right: '0px',
        left: '0px',
        zIndex: 0,
      }}>
      <MapViewer
        image={projectFloor.imageUrl}
        setLoadedImg={setLoadedImage}
        selectedDateRange={null}
        imageLoaded={loadedImage === projectFloor.imageUrl}
        points={[]}
      >
        {SiteWalkPointCircles}
      </MapViewer>
      <ButtonContainer
        splitScreenView={!!pointId}
      >
        {!pointReviewInProgress &&
          <>
            {selectedPoints.length > 0 &&
              <>
                <AddProjectButton
                  text={`Approve ${selectedPoints.length} Point${selectedPoints.length !== 1 ? 's' : ''}`}
                  buttonStyle={{background: '#073c7a', color: 'white'}}
                  onClick={onClickApproveAllPoints}
                />
                <AddProjectButton
                  text={`Reject ${selectedPoints.length} Point${selectedPoints.length !== 1 ? 's' : ''}`}
                  buttonStyle={{color: '#ff5252', border: '1px solid #ff5252'}}
                  onClick={onClickRejectAllPoints}
                />
              </>
            }
            {selectedPoints.length === 0 &&
              <>No Points Selected</>
            }
          </>
        }
        {pointReviewInProgress &&
          <LoadingIndicator/>
        }
      </ButtonContainer>
    </div>
  )
}

const ButtonContainer = styled.div<{splitScreenView?: boolean}>`
  position: absolute;
  bottom: 16px;
  left: 0;
  right: ${props => props.splitScreenView ? 'calc(50% + 60px)' : 0};
  width: fit-content;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 5px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  background: #ffffff;
  padding: 15px;
  z-index: 2;
`;