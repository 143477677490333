import { useSafetyContext } from '../../../../../contexts/safetyContext';
import { DetailPane, DetailPaneProps } from '../../SafetyGallery/DetailPane/DetailPane';

type ObservationViewProps = Omit<DetailPaneProps, 'initialShowMap' | 'splitCoordinates'>;

export const ObservationView = (props: ObservationViewProps) => {
  const { state: SafetyState } = useSafetyContext();

  return (
    <DetailPane
      initialShowMap={false}
      splitCoordinates={
        SafetyState.selectedMetricCategory ? coordinates => coordinates.split(', ') : undefined
      }
      {...props}
    />
  );
};
