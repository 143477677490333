import styled from 'styled-components';

export const Dropdown = styled.div`
  position: absolute;
  margin-top: 90px;
  background-color: #f9f9f9;
  min-width: 300px;
  max-height: 40%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 110px;
  top: -40px;
  border-radius: 2px;
  overflow-y: auto;
`;

export const UnreadDot = styled.span`
  height: 7px;
  width: 7px;
  background-color: #e00;
  display: inline-block;
  border-radius: 50%;
  flex-shrink: 0;
`;

export const ChatNotificationItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  padding 15px 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
 
 &:hover {
  background-color: #e8e8e8;
  border-radius: 5px;
 }
 &.last-element {
   border-bottom: none;
 }
`;

export const LoadMore = styled.div`
  font-size: 0.8em;
  color: #666;
  text-align: center;
`;

export const NoChats = styled.h3`
  font-size: 0.8em;
  color: #666;
  text-align: center;
`;
