import React, { createContext, useCallback, useContext, useReducer } from 'react';

const initialState = {
  projectId: '',
  floorId: '',
  pointId: '',
  scheduleId: '',
  fullscreen: false,
  dateRange: null,
  projectData: {},
  floorData: {
    groups: [],
    sections: [],
    pointMappings: new Map<number, Set<number>>(),
    selectedGroups: new Set<number>(),
    selectedPoints: new Set<number>(),
    groupPoints: new Set<number>(),
    noGoZones: new Map(),
    visibleNoGoZones: new Set(),
    selectedNoGoZones: new Set(),
    visibleEquipment: new Set(),
    hoverEquipment: null,
  },
  floorImagesLoading: true,
  pointData: {},
  imageData: {},
  tourPoints: [],
  schedules: [],
  scheduleData: null,
  scheduleMappings: new Map(),
  convexHullCalculatorMappings: new Map(),
  users: [],
  projectRoles: [],
};

const BuildingContext = createContext<any>(null);

const UPDATE_BUILDING = 'UPDATE_BUILDING';
const UPDATE_FLOOR = 'UPDATE_FLOOR';
const UPDATE_POINT = 'UPDATE_POINT';
const UPDATE_IMAGE = 'UPDATE_IMAGE';
const UPDATE_TOUR = 'UPDATE_TOUR';
const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';

const buildingReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_BUILDING:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_FLOOR:
      return {
        ...state,
        floorData: {
          ...state.floorData,
          ...action.payload.item,
        },
      };
    case UPDATE_POINT:
      return {
        ...state,
        pointData: {
          ...state.pointData,
          ...action.payload.item,
        },
      };
    case UPDATE_IMAGE:
      return {
        ...state,
        imageData: {
          ...state.imageData,
          ...action.payload.item,
        },
      };
    case UPDATE_TOUR:
      return {
        ...state,
        tourPoints: [...action.payload.item],
      };
    case UPDATE_SCHEDULE:
      return {
        ...state,
        scheduleData: {
          ...state.scheduleData,
          ...action.payload.item,
        },
      };
    default:
      return state;
  }
};

export const BuildingProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(buildingReducer, initialState);
  return <BuildingContext.Provider value={[state, dispatch]}>{children}</BuildingContext.Provider>;
};

export const useBuildingContext = () => {
  const [state, dispatch] = useContext(BuildingContext);

  const updateBuilding = useCallback((item: any) => {
    dispatch({
      type: UPDATE_BUILDING,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateFloor = useCallback((item: any) => {
    dispatch({
      type: UPDATE_FLOOR,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updatePoint = (item: any) => {
    dispatch({
      type: UPDATE_POINT,
      payload: {
        item,
      },
    });
  };

  const updateImage = useCallback((item: any) => {
    if (item === null) {
      dispatch({
        type: UPDATE_BUILDING,
        payload: {
          item: {
            imageData: null,
          },
        },
      });
    } else {
      dispatch({
        type: UPDATE_IMAGE,
        payload: {
          item,
        },
      });
    }
  }, [dispatch]);

  const updateTour = (item: any) => {
    dispatch({
      type: UPDATE_TOUR,
      payload: {
        item,
      },
    });
  };

  const updateSchedule = (item: any) => {
    dispatch({
      type: UPDATE_SCHEDULE,
      payload: {
        item,
      },
    });
  };

  return {
    updateBuilding,
    updateFloor,
    updatePoint,
    updateImage,
    updateTour,
    updateSchedule,
    state,
  };
};
