import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';

export const PageViewHandler = () => {
  const location = useLocation();

  const logPageView = () => {
    mixpanel.track('Page View', {
      path: location.pathname,
      search: location.search,
    });
  };

  useEffect(() => {
    logPageView();
  }, [location]);
  return <> </>;
};
