import axios from 'axios';
import { useEffect, useState } from 'react';

const BASE_URL = process.env.REACT_APP_SERVICES_API;

const VERSION = '7.99';
const SCRIPT_ID = 'autodeskScript';

const AUTODESK_URL = 'https://developer.api.autodesk.com/modelderivative';
const STYLE_URL = `${AUTODESK_URL}/v2/viewers/${VERSION}/style.min.css`;
const SCRIPT_URL = `${AUTODESK_URL}/v2/viewers/${VERSION}/viewer3D.min.js`;

export const useAutodeskScript = () => {
  const [loaded, setLoaded] = useState(false);
  const [autodeskModule, setAutodeskModule] = useState<any>(null);

  const requireAutodesk = () => {
    const Autodesk = (window as any).Autodesk;
    if (Autodesk === undefined) {
      throw Error('Autodesk global symbol did not load properly');
    }
    return Autodesk;
  };

  useEffect(() => {
    if (document.getElementById(SCRIPT_ID)) {
      setLoaded(true);
    } else {
      const css = document.createElement('link');
      css.rel = 'stylesheet';
      css.href = STYLE_URL;

      const tag = document.createElement('script');
      tag.id = SCRIPT_ID;
      tag.src = SCRIPT_URL;
      tag.async = true;
      tag.addEventListener('load', () => setLoaded(true));

      document.body.appendChild(css);
      document.body.appendChild(tag);
    }
  }, []);

  useEffect(() => {
    if (loaded && !autodeskModule) {
      const Autodesk = requireAutodesk();

      const options = {
        env: 'AutodeskProduction2',
        api: 'streamingV2',
        getAccessToken: (onTokenReady: any) => {
          axios
            .get(`${BASE_URL}/core/adsk-token/`)
            .then(r => r.data)
            .then(data => {
              var timeInSeconds = 3600; // Use value provided by Forge Authentication (OAuth) API
              onTokenReady(data.access_token, timeInSeconds);
            });
        },
      };

      Autodesk.Viewing.Initializer(options, async () => {
        // Dynamically import the extension after Autodesk is initialized
        const { PanoramaExtension } = await import('../forgeviewer/PanoramaExtension');

        // Register custom extension here
        Autodesk.Viewing.theExtensionManager.registerExtension(
          'PanoramaExtension',
          PanoramaExtension
        );

        setAutodeskModule(Autodesk);
      });

      return () => {
        if (autodeskModule) {
          Autodesk.Viewing.theExtensionManager.unregisterExtension('PanoramaExtension');
          Autodesk.Viewing.shutdown();
        }
      };
    }
  }, [loaded, autodeskModule]);

  return { loaded, autodeskModule };
};
