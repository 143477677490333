import { useState, useCallback, useRef } from 'react';

const useToggleOnEsc = () => {
  const [toggleActive, setToggleActive] = useState(false);
  const escCountRef = useRef(0);
  const timeoutRef = useRef<number | null>(null);

  const handleToggleKey = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') {
      escCountRef.current += 1;

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        escCountRef.current = 0;
      }, 1000); // 1 second timeout to reset the count

      if (escCountRef.current === 5) {
        setToggleActive(prevState => !prevState);
        escCountRef.current = 0;
      }
    }
  }, []);

  return { toggleActive, handleToggleKey };
};

export default useToggleOnEsc;
