import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ImageViewerContextMenu } from './components/ContextMenu/ImageViewerContextMenu';
import { TagManager } from './components/TagManager';
import { TourManager } from './components/TourManager';
import { Pannellum } from '../../../../third_party/Pannellum';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { MinimapPopup } from './components/MinimapPopup';
import { MinimapViewer } from '../../../../common/MapViewer';
import { ImageViewerSettings } from './components/ImageViewerSettings/ImageViewerSettings';
import { useImageViewerContext } from '../../imageViewerContext';
import { XRayView } from './components/XRayView/XRayView';
import { ErrorBoundary } from 'react-error-boundary';
import { ComponentErrorFallback } from '../../../../common/Errors/ComponentErrorFallback';
import { fetchImage } from '../../../../../api/buildingFetches';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import html2canvas from 'html2canvas';
import mixpanel from 'mixpanel-browser';
import { ProcoreAuthProvider } from '../../../../../contexts/procoreAuthContext';
import { ViewerPosition } from '../../types';
import { DebugPanel } from '../DebugPannel';
import useToggleOnEsc from '../../../../../hooks/useToggleOnEsc';
import { PannellumViewer } from '../../../../../types';
import * as THREE from 'three';
import { usePannellumViewer } from '../../../../../hooks/usePannellum';

let urlCreator = window.URL || window.webkitURL;

interface ViewerPaneProps {
  type: string;
  viewerPosition: ViewerPosition;
  viewerPositionDelta?: ViewerPosition;
  imageData: any;
  isSolo: boolean;
  followRealtime: boolean;
  syncedTo?: string;
  onDragging: (isActive: boolean) => void;
  onUpdate?: (newValues: ViewerPosition) => void;
}
export const ViewerPane = ({
  type,
  viewerPosition,
  viewerPositionDelta,
  imageData,
  isSolo,
  followRealtime,
  syncedTo,
  onDragging,
  onUpdate,
}: ViewerPaneProps) => {
  const { state: buildingState } = useBuildingContext();
  const {
    state: imageViewerState,
    updateImageViewer,
    updateMaster,
    updatePane2,
  } = useImageViewerContext();
  const [loading, setLoading] = useState<boolean>(false);
  const mapRef = useRef<MinimapViewer | null>(null);
  const mapPopupRef = useRef<any>(null);
  const { toggleActive, handleToggleKey } = useToggleOnEsc();

  const isMaster = useMemo(() => type === 'master', [type]);

  const viewerId = useMemo(() => {
    return isMaster ? 'master' : 'subordinate';
  }, [isMaster]);

  const { viewer } = usePannellumViewer(viewerId);

  const imageViewerStateDetail = useMemo(() => {
    return imageViewerState[type];
  }, [imageViewerState, type]);

  const onImageLoadError = useCallback(
    async (err: any) => {
      if (err.includes('blob:')) {
        if (imageViewerStateDetail.data.processed_image_url) {
          let imageBlob = await fetchImage(imageViewerStateDetail.data.processed_image_url);
          if (imageBlob) {
            isMaster
              ? updateMaster({ image: urlCreator.createObjectURL(imageBlob) })
              : updatePane2({ image: urlCreator.createObjectURL(imageBlob) });
          } else {
            console.error('Could not load blob!');
          }
        } else {
          console.error('Missing processed image URL!');
        }
      } else {
        throw Error(err);
      }
    },
    [imageViewerStateDetail, isMaster, updateMaster, updatePane2]
  );

  const handleZoomChanged = useCallback(
    (level: number) => {
      onUpdate && onUpdate({ ...viewerPosition, hfov: level });
    },
    [onUpdate, viewerPosition]
  );

  const handleTakeScreenshot = useCallback(
    (callback: (blob: Blob) => void) => {
      takeScreenShot(mapPopupRef, viewer, callback);
    },
    [viewer]
  );

  useEffect(() => {
    if (isMaster) {
      updateMaster({ ...buildingState.imageData });
    }
  }, [viewer, buildingState.imageData, isMaster, updateMaster]);

  useEffect(() => {
    setLoading(false);
    if (isMaster) {
      updateMaster({ ...buildingState.imageData });
    } else {
      updatePane2({ ...buildingState.imageData, angleDelta: 0 });
    }
    ValidateBlob(buildingState.imageData.image, isValid => {
      if (isValid) {
        setLoading(false);
      } else {
        console.log('invalid blob detected');
        throw Error('Invalid blob URL');
      }
    });
  }, [buildingState.imageData, isMaster, updateMaster, updatePane2]);

  const focusedClassName = useMemo(() => {
    return imageViewerState.focused === type && imageViewerState.splitScreen
      ? 'viewer-focused'
      : 'viewer';
  }, [imageViewerState.focused, imageViewerState.splitScreen, type]);

  if (!imageViewerState['master'].data || (!isMaster && !viewerPosition)) return <></>;

  if (loading)
    return (
      <div style={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <LoadingIndicator />
      </div>
    );

  return (
    <ErrorBoundary
      FallbackComponent={ComponentErrorFallback}
      onReset={() => {
        window.location.reload();
      }}>
      <div
        style={{ height: '100%', width: '100%' }}
        className={`${focusedClassName}`}
        onKeyDown={handleToggleKey}
        onClick={() => updateImageViewer({ focused: type })}
        id={'image-viewer-' + type}>
        {toggleActive && <DebugPanel viewerPosition={viewerPosition} imageData={imageData} />}
        {isMaster && viewer && (
          <ProcoreAuthProvider>
            <ImageViewerContextMenu viewerId={viewerId} onTakeScreenshot={handleTakeScreenshot} />
            <TagManager
              viewerId={viewerId}
              onTakeScreenshot={handleTakeScreenshot}
              viewerPosition={viewerPosition}
              angleOffset={imageViewerState[type].data?.angle || 0}
            />
            <TourManager
              viewerId={viewerId}
              height={imageViewerState[type].data.height}
              trueX={imageViewerState[type].data.true_x}
              trueY={imageViewerState[type].data.true_y}
            />
          </ProcoreAuthProvider>
        )}
        {!isMaster && imageViewerState.pane2Type === 'forge' ? (
          <XRayView
            viewerPosition={viewerPosition}
            viewerPositionDelta={viewerPositionDelta}
            followRealtime={followRealtime}
            onDragging={onDragging}
            onUpdate={onUpdate}
          />
        ) : (
          <Pannellum
            image={imageViewerState[type].image}
            viewerId={isMaster ? 'master' : 'subordinate'}
            isSolo={isSolo}
            viewerPosition={viewerPosition}
            viewerPositionDelta={viewerPositionDelta}
            followRealtime={followRealtime}
            onDragging={onDragging}
            onUpdate={onUpdate}
            angleOffset={imageViewerState[type].data?.angle || 0}
            onError={onImageLoadError}
            syncedTo={syncedTo}
          />
        )}
        <MinimapPopup
          ref={mapPopupRef}
          mapRef={mapRef}
          viewerPosition={viewerPosition}
          rightPosition={isMaster ? '40px' : window.innerWidth / 2 + 40}
          type={type}
        />
        {isMaster && (
          <ImageViewerSettings
            viewerPosition={viewerPosition}
            zoomLevel={viewerPosition.hfov}
            zoomMin={50}
            zoomMax={120}
            zoomStep={10}
            onZoomChanged={handleZoomChanged}
            onTakeScreenshot={handleTakeScreenshot}
          />
        )}
      </div>
    </ErrorBoundary>
  );
};

function ValidateBlob(blobUrl: string, callback: (bool: boolean) => void) {
  var xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (this.readyState === 4) {
      if (
        this.status === 200 ||
        (this.response && this.response.type && this.response.type === 'image/jpeg')
      ) {
        callback(true);
      } else {
        callback(false);
      }
    }
  };
  xhr.open('GET', blobUrl);
  xhr.responseType = 'blob';
  xhr.send();
}

const loadImage = async (image: string) => {
  return new Promise<HTMLImageElement>(resolve => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.src = image;
  });
};

export const takeScreenShot = async (
  mapPopupRef: React.RefObject<HTMLDivElement>,
  viewer: PannellumViewer | null,
  callback: (blob: Blob) => any
) => {
  if (mapPopupRef.current && viewer) {
    const viewerImageData = viewer
      .getRenderer()
      .render(
        THREE.MathUtils.degToRad(viewer.getPitch()),
        THREE.MathUtils.degToRad(viewer.getYaw()),
        THREE.MathUtils.degToRad(viewer.getHfov()),
        { returnImage: true }
      );
    const canvas = document.createElement('canvas') as HTMLCanvasElement;
    const context = canvas.getContext('2d')!;
    const viewerImage = await loadImage(viewerImageData);
    canvas.width = viewerImage.width;
    canvas.height = viewerImage.height;
    context.drawImage(viewerImage, 0, 0, canvas.width, canvas.height);
    const canvasPopup = await html2canvas(mapPopupRef.current, {
      ignoreElements: el => el.classList.contains('ignore-html2canvas'),
    });
    context.drawImage(canvasPopup, canvas.width - canvasPopup.width - 10, 10);
    canvas.toBlob(blob => {
      blob && callback(blob);
    });
    mixpanel.track('Download Screenshot');
  }
};
