import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { mapCircleIconStyle, PointControlButton, PointControlContainer, PointControlSpan, TrashIconButton } from "../../../../common/ViewSelector/components/PointControls";
import iconTrash from '../../../../../assets/images/icon_trash.svg';
import { IBoundaryPoint, INoGoZone } from "./ManageNoGoZones";
import { MapPointMainCircle } from "../../../../common/MapViewer";
import classNames from "classnames";
import { deleteNoGoZone, updateNoGoZone, updateNoGoZoneActiveness } from "../../../../../api/adminBuildingFetches";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { fetchFloors } from "../../../../../api/buildingFetches";
import { useNoGoZoneContext } from "./NoGoZoneContext";

export const NoGoZonePointControls = () => {
  const {
    updateBuilding,
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    canEditZones,
    canDeleteZones,
  } = useNoGoZoneContext();

  const { addNotification } = useNotifications();

  const { selectedPoints, selectedNoGoZones, noGoZones } = buildingState.floorData;

  const noPointsSelected: boolean = selectedPoints.size === 0;
  const noZonesSelected: boolean = selectedNoGoZones.size === 0;

  const deleteSelectedPoints = () => {
    const updatedNoGoZones = new Map<number, INoGoZone>(noGoZones);
    const promiseArr: Promise<any>[] = [];
    let successfullyDeleted = 0;
    let errorDeleting = 0;

    noGoZones.forEach((zone: INoGoZone) => {
      const originalPoints = [...zone.points];
      const updatedPoints = zone.points.filter((point: IBoundaryPoint) => !selectedPoints.has(point.id));
      const numPointsToDelete = originalPoints.length - updatedPoints.length;

      if (updatedPoints.length === 0) {
        const deleteZonePromise = deleteNoGoZone(buildingState.projectId, buildingState.floorId, zone.id).then(() => {
          updatedNoGoZones.delete(zone.id);
          successfullyDeleted += numPointsToDelete;
        }).catch(err => {
          console.log('deleteSelectedPoints==>>', err);
          errorDeleting += numPointsToDelete;
        });

        promiseArr.push(deleteZonePromise);
      } else if (updatedPoints.length < originalPoints.length) {
        zone.points = updatedPoints;

        const updateZonePromise = updateNoGoZone(buildingState.projectId, buildingState.floorId, zone).then((returnedZone: INoGoZone) => {
          updatedNoGoZones.set(zone.id, returnedZone);
          successfullyDeleted += numPointsToDelete;
        })
        .catch(err => {
          console.log('deleteSelectedPoints==>>', err);
          errorDeleting += numPointsToDelete;
          zone.points = originalPoints;
        });

        promiseArr.push(updateZonePromise);
      }
    });

    Promise.allSettled(promiseArr).then(async () => {
      const updatedFloors = await fetchFloors(buildingState.projectId);

      updateFloor({
        noGoZones: updatedNoGoZones,
        selectedPoints: new Set(),
        selectedNoGoZones: new Set(),
      });

      updateBuilding({ 
        projectData: {
          ...buildingState.projectData,
          floors: updatedFloors,
        }
      });

      if (successfullyDeleted > 0) {
        const pointsWording  = successfullyDeleted !== 1 ? 'points' : 'point';
        addNotification(`${successfullyDeleted} ${pointsWording} successfully deleted.`, 'success');
      }

      if (errorDeleting > 0) {
        const pointsWording  = successfullyDeleted !== 1 ? 'points' : 'point';
        addNotification(`Error deleting ${errorDeleting} ${pointsWording}`, 'error');
      }
    });
  }

  const toggleZoneActivation = (isActive: boolean) => {
    const updatedNoGoZones = new Map(noGoZones);
    const toggledZoneIds = new Set();
    const activationSuccessWording: string = isActive ? 'Activated' : 'Deactivated';
    const activationDeletedWording: string = isActive ? 'Activating' : 'Deactivating';

    const updateActivenessPromises = Array.from(selectedNoGoZones as Set<number>).map(async (zoneId: number) => {
      try {
        const updatedZone = await updateNoGoZoneActiveness(buildingState.projectId, buildingState.floorId, zoneId, isActive);

        updatedNoGoZones.set(updatedZone.id, updatedZone);
        toggledZoneIds.add(updatedZone.id);

        return updatedZone;
      } catch (err) {
        console.log('updateNoGoZoneActiveness==>>', err);
      }
    });

    Promise.allSettled(updateActivenessPromises).then(() => {
      if (toggledZoneIds.size > 0) {
        updateFloor({
          selectedPoints: new Set(),
          selectedNoGoZones: new Set(),
          noGoZones: updatedNoGoZones
        });

        const zonesWording: string = toggledZoneIds.size !== 1 ? 'Zones' : 'Zone';
        
        addNotification(`${toggledZoneIds.size} ${zonesWording} ${activationSuccessWording} Successfully.`, 'success');
      }

      const numErrors: number = selectedNoGoZones.size - toggledZoneIds.size;

      if (numErrors > 0) {
        const zonesWording: string = numErrors !== 1 ? 'Zones' : 'Zone';

        addNotification(`Error ${activationDeletedWording} ${numErrors} ${zonesWording}`, 'error');
      }
    });
  }

  return (
    <PointControlContainer>
      { canDeleteZones &&
        <PointControlButton
          disabled={noPointsSelected}
          onClick={() => {
            if (!noPointsSelected) {
              deleteSelectedPoints();
            }
          }}
        >
          <TrashIconButton
            src={iconTrash}
            disabled={noPointsSelected}
            style={{margin: '0px 7px 0px 5px'}}
          />
          <PointControlSpan>Delete Selected Points</PointControlSpan>
        </PointControlButton>
      }

      { canEditZones &&
        <PointControlButton
          onClick={() => {
            if (!noZonesSelected) {
              toggleZoneActivation(true);
            }
          }}
          disabled={noZonesSelected}
        >
          <MapPointMainCircle
            style={{...mapCircleIconStyle, margin: '0px 10px 0px 8px'}}
            className={classNames({
              back_pink: true
            })}
          />
          <PointControlSpan>{`Activate Zone${selectedNoGoZones.size > 1 ? 's' : ''}`}</PointControlSpan>
        </PointControlButton>
      }

      { canEditZones &&
        <PointControlButton
          onClick={() => {
            if (!noZonesSelected) {
              toggleZoneActivation(false);
            }
          }}
          disabled={noZonesSelected}
        >
          <MapPointMainCircle
            style={{...mapCircleIconStyle, margin: '0px 10px 0px 8px'}}
            className={classNames({
              back_gray: true
            })}
          />
          <PointControlSpan>{`Deactivate Zone${selectedNoGoZones.size > 1 ? 's' : ''}`}</PointControlSpan>
        </PointControlButton>
      }
    </PointControlContainer>
  )
}