import { Property } from 'csstype';
import React from 'react';

export const Text = (props: {
  size: number;
  bold?: boolean;
  color?: string;
  marginTop?: number;
  marginBottom?: number;
  marginRight?: number;
  marginLeft?: number;
  align?: Property.TextAlign;
  className?: string;
  children: any;
}) => (
  <div
    className={props.className}
    style={{
      textAlign: props.align,
      fontSize: `${props.size}px`,
      fontWeight: props.bold ? 700 : undefined,
      color: props.color,
      marginTop: props.marginTop != null ? `${props.marginTop}px` : undefined,
      marginBottom: props.marginBottom != null ? `${props.marginBottom}px` : undefined,
      marginRight: props.marginRight != null ? `${props.marginRight}px` : undefined,
      marginLeft: props.marginLeft != null ? `${props.marginLeft}px` : undefined,
    }}>
    {props.children}
  </div>
);
