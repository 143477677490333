import { useBuildingContext } from "../../../../../../../contexts/buildingContext";
import styled from "styled-components";
import { Frequency } from "../ScheduleControls";
import moment from "moment";
import { getFinalScan, getNextScan } from "../../ScheduleHelperFunctions";

export const ScheduleControlsScanInformation = () => {
  const {
    state: buildingState,
  } = useBuildingContext();

  const { scheduleData } = buildingState;
  const { start_date, till_date, time, frequency } = scheduleData;
  const { nextScanFormatted } = getNextScan(frequency, start_date, till_date, time);

  const displayScanPattern = (): string => {
    let scanPattern = '';

    const formattedStartDate = moment(start_date).format('YYYY-MM-DD');

    const finalScan = getFinalScan(frequency, start_date, till_date, time);
    const formattedFinalScan = moment(finalScan).format('YYYY-MM-DD');

    switch (frequency) {
      case Frequency.once:
        scanPattern = `Scans once on ${moment(start_date).format('YYYY-MM-DD [at] h:mm A')}`

        break;
      case Frequency.weekly:
        const dayOfWeek = start_date.toLocaleDateString('en', { weekday: 'long' });

        scanPattern = `Scans every ${dayOfWeek} from ${formattedStartDate} to ${formattedFinalScan}`;

        break;
      case Frequency.monthly:
        const date = start_date.getDate();
        const ordinal: string = date === 1 ? 'st' : date === 2 ? 'nd' : date === 3 ? 'rd' : 'th';

        scanPattern = `Scans on the ${date}${ordinal} of every month from ${formattedStartDate} to ${formattedFinalScan}`;
    }

    return scanPattern;
  }

  return (
    <ScanInformationContainer>
      <div style={{marginBottom: '12px'}}>
        {`Next Scan: ${nextScanFormatted}`}
      </div>
      <div>
        {`Scan Pattern: ${displayScanPattern()}`}
      </div>
    </ScanInformationContainer>
  )
}

const ScanInformationContainer = styled.div`
  margin-top: 10px;
  font-size: 12px;
  color: #A7AABD;
`;