import { useState } from 'react';
import styled from 'styled-components';
import { ContentContainer, SectionHeader } from '../ViewSelector';
import { IconButtonExpand } from './IconButtonExpand';
import { SelectItem } from './SelectItem';

export interface IViewSelectorOption {
  Id: string;
  Content: string | JSX.Element;
  Disclaimer?: string;
  SubTitle?: string;
  OnSelectChild?: (selectedChild: IViewSelectorOption) => void;
  OnHoverChild?: (hoverOption: IViewSelectorOption | null) => void;
  Options?: IViewSelectorOption[];
}

export const OptionPicker = (props: {
  expanded: boolean;
  setExpanded: (newValue: boolean) => void;
  viewingItems: IViewSelectorOption;
  selectedItem?: IViewSelectorOption;
  hoverItem?: IViewSelectorOption | null;
  onSelectOption?: (option: IViewSelectorOption) => void;
  onHover?: (option: IViewSelectorOption | null) => void;
  hideSelectedOptionDisplay?: boolean;
  showBottomBorder?: boolean;
}) => {
  const [childExpanded, setChildExpanded] = useState<boolean>(true);
  const [childSelectedItem, setChildSelectedItem] = useState<IViewSelectorOption | undefined>();
  const [childHoverOption, setChildHoverOption] = useState<IViewSelectorOption | null>(null);

  const hasChildren = !!props.selectedItem && !!props.selectedItem.Options?.length;

  const onClickExpand = (newValue: boolean) => {
    props.setExpanded(newValue);
    setChildExpanded(false);
  };

  const onSelectItem = (option: IViewSelectorOption) => {
    if (props.onSelectOption) {
      props.onSelectOption(option);
      setChildSelectedItem(undefined);
      setChildHoverOption(null);
    }
  };

  const onSelectChild = (option: IViewSelectorOption) => {    
    setChildSelectedItem(option);

    if (props.selectedItem?.OnSelectChild) {
      props.selectedItem.OnSelectChild(option);
    }
  };

  const onHover = (option: IViewSelectorOption | null) => {
    if (props.onHover) {
      props.onHover(option);
    }
  };

  const onHoverChild = (option: IViewSelectorOption | null) => {
    setChildHoverOption(option);

    if (props.selectedItem?.OnHoverChild) {
      props.selectedItem.OnHoverChild(option);
    }
  };

  const sectionHeader = (
    <SectionHeader>
      {props.viewingItems.SubTitle ?? props.viewingItems.Content}
    </SectionHeader>
  )

  return (
    <>
      <OptionContainer
        showBottomBorder={props.showBottomBorder || hasChildren}
      >
        {!props.hideSelectedOptionDisplay &&
          <>{sectionHeader}</>
        }

        <HeaderContainer>
          {props.hideSelectedOptionDisplay &&
            <>{sectionHeader}</>
          }
          {!props.hideSelectedOptionDisplay && 
            <SelectedOption>
              {props.selectedItem ? props.selectedItem.Content : 'Select'}
            </SelectedOption>
          }

          <IconButtonExpand
            expanded={props.expanded}
            onClick={onClickExpand}
            style={{ display: 'flex' }}
            size={20}
          />
        </HeaderContainer>

        <ContentContainer aria-expanded={props.expanded}>
          { props.viewingItems.Options && props.viewingItems.Options.map((option, i) => (
            <SelectItem
              key={i}
              content={option.Content}
              disclaimer={option.Disclaimer}
              onClick={
                !!props.onSelectOption ?
                () => onSelectItem(option) :
                undefined
              }
              onMouseEnter={() => onHover(option)}
              onMouseLeave={() => onHover(null)}
              selected={props.selectedItem?.Id === option.Id || props.hoverItem?.Id === option.Id}
              style={{border: '1px solid #E8E8E8', borderRadius: '4px'}}
            />
          ))
          }
        </ContentContainer>
      </OptionContainer>

      {props.selectedItem && props.selectedItem.Options && props.selectedItem.Options.length > 0 && (
        <OptionPicker
          expanded={childExpanded}
          setExpanded={setChildExpanded}
          viewingItems={props.selectedItem}
          selectedItem={childSelectedItem}
          hoverItem={childHoverOption}
          onSelectOption={onSelectChild}
          onHover={onHoverChild}
        />
      )}
    </>
  );
};

const SelectedOption = styled.span`
  font-size: 14px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const OptionContainer = styled.div<{showBottomBorder?: boolean}>`
  padding: 16px 8px 8px 8px;
  border-radius: 4px;

  ${props => props.showBottomBorder && `
    border-bottom: 1px solid #e8e8e8;
    padding: 16px 8px;`
  }
`;
