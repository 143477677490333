import styled from 'styled-components';

export const FullscreenPanoramaBase = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
`;

export const FullscreenPanoramaBackButton = styled.div`
  position: fixed;
  left: 44px;
  top: 20px;
  z-index: 3;

  display: flex;
  align-items: center;

  user-select: none;
  cursor: pointer;

  span {
    margin-left: 30px;
    color: white;
  }
`;

export const MinimapPopupDiv = styled.div`
  top: 24px;
  position: fixed;
  z-index: 3;

  width: 400px;
  filter: drop-shadow(0px 5px 30px rgba(0, 0, 0, 0.25));
`;

interface MinimapPopupHeaderProps {
  transparent?: boolean;
}

export const MinimapPopupHeader = styled.div<MinimapPopupHeaderProps>`
  background-color: ${(props) => (props.transparent ? 'transparent' : '#f8f8f8')};
  padding: 11px 16px;
  border-radius: 2px 2px 0 0;
`;

export const MinimapPopupImage = styled.div`
  border-radius: 0 0 2px 2px;
  display: block;
  background-color: white;
  overflow: hidden;

  height: 220px;
  max-width: 100%;
`;

export const SplitScreenContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
