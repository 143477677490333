import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DateRange, Range } from 'react-date-range';
import { format } from 'date-fns';
import Select from 'react-select';
import { addDays, startOfMonth, endOfMonth, addMonths, endOfWeek, startOfWeek } from 'date-fns';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Text } from '../Text';
import { Icon } from '../Icon';
import iconCalendar from '../../../assets/images/icon_calendar.svg';

const QuickSelectKeys = [
  {
    key: 'this_month',
    label: 'This Month',
    fn: () => ({ startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) }),
  },
  {
    key: 'last_month',
    label: 'Last Month',
    fn: () => ({
      startDate: startOfMonth(addMonths(new Date(), -1)),
      endDate: endOfMonth(addMonths(new Date(), -1)),
    }),
  },
  {
    key: 'this_week',
    label: 'This Week',
    fn: () => ({ startDate: startOfWeek(new Date()), endDate: endOfWeek(new Date()) }),
  },
  {
    key: 'last_week',
    label: 'Last Week',
    fn: () => ({
      startDate: startOfWeek(addDays(new Date(), -7)),
      endDate: endOfWeek(addDays(new Date(), -7)),
    }),
  },
  {
    key: 'custom',
    label: 'Custom',
    fn: () => null,
  },
];
interface QuickDateSelectorProps {
  onChange?: (v: Range) => void;
  styles?: any;
}
export const QuickDateSelector = ({ onChange, styles }: QuickDateSelectorProps) => {
  const selectorRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const selectionRange: Range = {
    startDate: startOfMonth(new Date()),
    endDate: endOfMonth(new Date()),
    key: 'selection',
  };
  const [selectedRange, setSelectedRange] = useState<Range>(selectionRange);
  const [qsv, setQSV] = useState(QuickSelectKeys[0]);
  const [displayed, setDisplayed] = useState<boolean>(false);

  const onQuickChange = (v: any) => {
    setQSV(v);
    if (v.key !== 'custom') setSelectedRange(v.fn());
  };

  const onDateSelect = (v: any) => {
    setQSV(QuickSelectKeys[4]);
    setSelectedRange(v.range1 || v.selection);
  };

  useEffect(() => {
    if (onChange) onChange(selectedRange);
  }, [selectedRange]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (selectorRef.current && !selectorRef.current.contains(event.target)) {
        setDisplayed(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectorRef]);

  return (
    <DateRangeSelectorBase ref={selectorRef} id="drsb" style={styles}>
      {displayed && (
        <DateRangeSelectorPopup>
          <DateRange
            rangeColors={['#073c7a']}
            color="#073c7a"
            ranges={[selectedRange]}
            maxDate={new Date()}
            showDateDisplay={false}
            onChange={onDateSelect}
          />
        </DateRangeSelectorPopup>
      )}
      <DateRangeSelectorDisplay className="left">
        <Select
          onChange={onQuickChange}
          options={QuickSelectKeys}
          getOptionValue={option => option.key}
          getOptionLabel={option => option.label}
          styles={customStyles}
          value={qsv}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: '#073c7a',
            },
          })}
        />
      </DateRangeSelectorDisplay>
      <DateRangeSelectorDisplay onClick={() => setDisplayed(!displayed)} className="right">
        {selectedRange ? (
          <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Icon icon={iconCalendar} size={12} style={{ marginRight: '9px' }} />
            <Text size={14} color={'#073c7a'} bold={false}>
              {format(selectedRange.startDate!, 'MMM dd')}
              <span
                style={{
                  display: 'inline-block',
                  width: '7px',
                  height: '1px',
                  backgroundColor: '#000',
                  margin: '4px 3px',
                }}
              />
              {selectedRange.endDate ? format(selectedRange.endDate!, 'MMM dd') : ''}
            </Text>
          </div>
        ) : (
          <Text size={16} color={'#073C7A'}>
            Select Range
          </Text>
        )}
      </DateRangeSelectorDisplay>
    </DateRangeSelectorBase>
  );
};

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#073c7a' : '#F4F5F9',
    boxShadow: 'none',
    minHeight: '50px',
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#073c7a',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '6px',
  }),
};

const DateRangeSelectorBase = styled.div`
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 10;
  position: relative;
`;
DateRangeSelectorBase.displayName = 'DateRangeSelectorBase';

const DateRangeSelectorPopup = styled.div`
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  z-index: 2;
  position: absolute;
  top: 50px;
`;
DateRangeSelectorPopup.displayName = 'DateRangeSelectorPopup';

const DateRangeSelectorDisplay = styled.div`
  padding: 10px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #f4f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  &.left {
    border: none;
    padding: unset;
    border-radius: 2px 0 0 2px;
  }
  &.right {
    border-radius: 0 2px 2px 0;
  }
`;
DateRangeSelectorDisplay.displayName = 'DateRangeSelectorDisplay';
