import React from 'react';
import styled from 'styled-components';

const AddProjectButtonBase = styled.div`
  background: #ffffff;
  border: 1px solid #073c7a;
  box-sizing: border-box;
  border-radius: 2px;

  color: #073c7a;

  height: 44px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 14px;

  cursor: pointer;
`;

const AddProjectButtonImage = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;

export const AddProjectButton = (props: { 
  icon?: string;
  text: string;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  buttonStyle?: React.CSSProperties;
}) => {
  return (
    <AddProjectButtonBase
      onClick={(e: React.MouseEvent<HTMLDivElement>) => {
        if (props.onClick) {
          props.onClick(e)
        }
      }}
      style={{...props.buttonStyle}}
    >
      {props.icon && <AddProjectButtonImage src={props.icon} /> }
      <div>{props.text}</div>
    </AddProjectButtonBase>
  );
};
