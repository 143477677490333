import { useBuildingContext } from "../../../../../../../contexts/buildingContext";
import { CustomDropdownArrow, Frequency } from "../ScheduleControls"
import '../ScheduleControlsCalendar.css';
import { Calendar, DateRange } from "react-date-range";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { Dropdown } from "../../../../../../common/Dropdown/Dropdown";

export const commonInputStyles = `
  border: 1px solid #D4DBE8;
  border-radius: 2px;
  padding: 8px 10px;
  width: 100%;
  font-size: 12px;
`;

export const invalidInputStyle = `
  border: 2px solid #ff5252;
`

const datePickerClassNames = { 
  calendarWrapper: 'calendar-wrapper', 
  month: 'calendar-month', 
  nextPrevButton: 'calendar-next-prev-button',
  monthPicker: 'calendar-month-picker',
  yearPicker: 'calendar-year-picker',
  dateDisplayWrapper: 'calendar-date-display-wrapper',
  monthAndYearWrapper: 'calendar-month-and-year-wrapper',
};

interface IScheduleControlsDatePickerProps {
  valid: boolean;
  disabled: boolean;
}

export const ScheduleControlsDatePicker = ({
  valid,
  disabled,
}: IScheduleControlsDatePickerProps) => {
  const {
    updateSchedule,
    state: buildingState,
  } = useBuildingContext();

  const { scheduleData } = buildingState;
  const { start_date, till_date, frequency } = scheduleData;

  const [showDatePickers, setShowDatePickers] = useState<boolean>(false);
  const dateDropdownRef = useRef<HTMLDivElement>(null);

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  useEffect(() => {
    if (!disabled) {
      const dropdownContainer = dateDropdownRef.current!

      const onClickDateDropdown = (e: MouseEvent | TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setShowDatePickers(prevState => !prevState);
      }

      dropdownContainer.addEventListener('mousedown', onClickDateDropdown);
      dropdownContainer.addEventListener('touchstart', onClickDateDropdown);
      return () => {
        dropdownContainer.removeEventListener('mousedown', onClickDateDropdown);
        dropdownContainer.removeEventListener('touchstart', onClickDateDropdown);
      }
    }
  }, [disabled]);

  const onChangeStartDate = (newDate: Date) => {
    updateSchedule({
      start_date: newDate,
      till_date: newDate,
    });
  }

  const onChangeDateRange = (range: any) => {
    const { startDate, endDate } = range.selection;

    updateSchedule({
      start_date: startDate,
      till_date: endDate,
    });
  }

  const displayDateHeader = () => {
    const formattedStartDate = moment(start_date).format('MMM-D-YYYY');
    const formattedTillDate = moment(till_date).format('MMM-D-YYYY');

    if (!start_date) {
      return 'Start Date';
    } else if (frequency === Frequency.never || frequency === Frequency.once) {
      return formattedStartDate;
    } else if (frequency === Frequency.weekly || frequency === Frequency.monthly) {
      return `${formattedStartDate} to ${formattedTillDate}`
    }
  }

  return (
    <>
      <DateDropdown
        ref={dateDropdownRef}
        valid={valid}
        disabled={disabled}
      >
        { displayDateHeader() }
        
        <CustomDropdownArrow/>
      </DateDropdown>

      <Dropdown
        display={showDatePickers}
        setDisplay={setShowDatePickers}
        style={{
          right: '-20px',
          top: '110px',
          maxHeight: '100%'
        }}
      >
        { (frequency === Frequency.once || frequency === Frequency.never) &&
          <Calendar
            classNames={datePickerClassNames}
            date={start_date}
            minDate={tomorrow}
            onChange={onChangeStartDate}
          />
        }
        { (frequency === Frequency.weekly || frequency === Frequency.monthly) &&
          <DateRange
            classNames={datePickerClassNames}
            ranges={[{
              startDate: start_date,
              endDate: till_date,
              key: 'selection'
            }]}
            onChange={onChangeDateRange}
            minDate={tomorrow}
          />
        }
      </Dropdown>
    </>
  )
}

const DateDropdown = styled.div<IScheduleControlsDatePickerProps>`
  ${commonInputStyles}
  position: relative;
  cursor: ${props => props.disabled ? 'default' : 'disabled'};
  ${props => !props.valid ? invalidInputStyle : ''}
  ${props => props.disabled && `
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  `}
`;