import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from 'react';
import { useSafetyContext } from './safetyContext';
import { SafetyMetricSlug, safetyMetricMetaData } from '../api/safety';

interface GraphData {
  date: Date;
  value: number;
  time: number;
}

interface CompositeSafetyIndexContextState {
  graphData: GraphData[];
}

const initialCompositeSafetyIndexState: CompositeSafetyIndexContextState = {
  graphData: [],
};

type ICompositeSafetyIndexContext = [CompositeSafetyIndexContextState,  React.Dispatch<CompositeSafetyIndexAction>];

const initialCompositeSafetyIndexContext: ICompositeSafetyIndexContext = [{...initialCompositeSafetyIndexState}, () => {}];
const CompositeSafetyIndexContext = createContext<ICompositeSafetyIndexContext>({...initialCompositeSafetyIndexContext});
const UPDATE_COMPOSITE_SAFETY_INDEX = 'UPDATE_COMPOSITE_SAFETY_INDEX';

type CompositeSafetyIndexAction = {
  type: string;
  payload: Partial<CompositeSafetyIndexContextState>;
};

const safetyReducer = (state: CompositeSafetyIndexContextState, action: CompositeSafetyIndexAction) => {
  switch (action.type) {
    case UPDATE_COMPOSITE_SAFETY_INDEX:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export const CompositeSafetyIndexProvider = ({ children }: any) => {
  const {state: safetyState} = useSafetyContext();
  const {metricSummaryLoadingStatus, metricGraphs} = safetyState;

  const [state, dispatch] = useReducer(safetyReducer, {...initialCompositeSafetyIndexState});

  const metricsLoaded = useMemo(() => {
    return Object.values(metricSummaryLoadingStatus).every(status => !status);
  }, [metricSummaryLoadingStatus]);

  const aggregatedDatesAndWeights = useMemo(() => {
    if (metricsLoaded && !!metricGraphs) {
      const dateMap: Record<number, {totalValue: number, totalWeight: number}> = {};

      Object.entries(metricGraphs).forEach(([slug, data]: [string, any]) => {
        if (data) {
          const slugMetaData = safetyMetricMetaData[slug as SafetyMetricSlug];

          data.forEach((graph: GraphData) => {
            const mapKey = graph.time;

            if (!dateMap[mapKey]) {
              dateMap[mapKey] = {
                totalValue: 0,
                totalWeight: 0
              };
            }

            dateMap[mapKey].totalValue += graph.value * slugMetaData.weight;
            dateMap[mapKey].totalWeight += slugMetaData.weight;
          });
        }
      });

      return dateMap;
    }

    return null;
  }, [metricGraphs, metricsLoaded]);

  const graphData = useMemo(() => {
    if (aggregatedDatesAndWeights) {
      return Object.entries(aggregatedDatesAndWeights).map(([time, {totalValue, totalWeight}]: [string, any]) => {
        return {
          date: new Date(parseInt(time)),
          value: totalValue / totalWeight,
          time: parseInt(time),
        };
      });
    }

    return [];
  }, [aggregatedDatesAndWeights]);

  useEffect(() => {
    dispatch({
      type: UPDATE_COMPOSITE_SAFETY_INDEX,
      payload: {
        graphData: graphData,
      },
    });
  }, [graphData]);

  return (
    <CompositeSafetyIndexContext.Provider value={[state, dispatch]}>
      {children}
    </CompositeSafetyIndexContext.Provider>
  );
};

export const useCompositeSafetyIndexContext = () => {
  const [state, dispatch] = useContext(CompositeSafetyIndexContext);

  const updateCompositeSafetyIndex = useCallback((payload: Partial<CompositeSafetyIndexContextState>) => {
    dispatch({
      type: UPDATE_COMPOSITE_SAFETY_INDEX,
      payload: payload,
    });
  }, [dispatch]);

  return {
    state,
    updateCompositeSafetyIndex,
  };
};
