import { useCallback, useEffect, useRef, useState } from 'react';
import { ViewerPosition } from '../types';

export const useViewer3D = (autodeskModule: any) => {
  const [viewer, setViewer] = useState<any>(null);
  const panoramaTool = useRef<any>(null);

  const getPitch = () => {
    if (panoramaTool.current) {
      return panoramaTool.current.getPitch();
    }
    return 0;
  };

  const getYaw = () => {
    if (panoramaTool.current) {
      return panoramaTool.current.getYaw();
    }
    return 0;
  };

  const getFov = () => {
    if (panoramaTool.current) {
      return panoramaTool.current.getFov();
    }
    return 0;
  };

  const setPitch = (pitch: number) => {
    if (panoramaTool.current) {
      panoramaTool.current.setPitch(pitch);
    }
  };

  const setYaw = (yaw: number) => {
    if (panoramaTool.current) {
      panoramaTool.current.setYaw(yaw);
    }
  };

  const setFov = (fov: number) => {
    if (panoramaTool.current) {
      panoramaTool.current.setFov(fov);
    }
  };

  const getViewerPosition = () => {
    const viewerPosition: ViewerPosition = {
      pitch: getPitch(),
      yaw: getYaw(),
      hfov: getFov(),
    };
    return viewerPosition;
  };

  const setViewerPosition = (viewerPosition: ViewerPosition) => {
    setPitch(viewerPosition.pitch);
    setYaw(viewerPosition.yaw);
    setFov(viewerPosition.hfov);
  };

  useEffect(() => {
    if (autodeskModule) {
      const Autodesk = autodeskModule;
      const htmlDiv = document.getElementById('forgeViewer');
      const options = {
        profileSettings: {
          bimWalkToolPopup: false,
          firstPersonToolPopup: false,
        },
        loaderExtensions: { svf: 'Autodesk.MemoryLimited' },
        extensions: [
          'Autodesk.Viewing.MemoryLimitedDebug',
          'Autodesk.BimWalk',
          'Autodesk.ViewerSettings',
          'PanoramaExtension',
        ],
      };

      // Initialize the viewer
      const newViewer = new Autodesk.Viewing.Viewer3D(htmlDiv, options);
      if (newViewer.start() > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
      }

      setViewer(newViewer);

      // Listen for the extension loaded event
      newViewer.addEventListener(Autodesk.Viewing.EXTENSION_LOADED_EVENT, (event: any) => {
        if (event.extensionId === 'PanoramaExtension') {
          const panoramaExtension = newViewer.getExtension('PanoramaExtension');
          if (panoramaExtension) {
            console.debug('Found PanoramaTool', panoramaExtension.panoramaTool);
            panoramaTool.current = panoramaExtension.panoramaTool;
          } else {
            console.warn('PanoramaExtension not found or not loaded correctly.');
          }
        }
      });

      // Clean up the viewer on unmount
      return () => {
        newViewer.finish();
      };
    }
  }, [autodeskModule]);

  return {
    viewer,
    getViewerPosition,
    setViewerPosition,
  };
};
