import styled from 'styled-components';
import { Grid } from '@react-css/grid';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import iconBack from '../../../../assets/images/icon_back_dark.svg';
import iconForward from '../../../../assets/images/icon_forward.svg';
import { IconButton } from '../../../common/IconButton';
import { Flex } from '@react-css/flex';
import axios from 'axios';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { useEffect, useState } from 'react';
import { globalErrorHandler } from '../../../../globalErrorHandler';
import { ViewerPosition } from '../types';
import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';

const API_BASE = process.env.REACT_APP_NEXTERA_API;

interface DebugPanelProps {
  imageData: any;
  viewerPosition: ViewerPosition;
}

const DebugCard = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 300px;
  background: #000;
  color: #0f0;
  padding: 15px;
  line-height: 1.1em;
  font-size: 0.9em;
`;

export const DebugPanel = ({ viewerPosition, imageData }: DebugPanelProps) => {
  const { state: buildingState } = useBuildingContext();
  const { addNotification } = useNotifications();
  const [offsetAngle, setOffsetAngle] = useState<number>(0);

  const updateAngle = () => {
    axios
      .patch(
        `${API_BASE}/project/${buildingState.projectId}/floor/${buildingState.floorId}/viewpoints/image/${imageData.data.id}`,
        { angle: angleLimit(offsetAngle) }
      )
      .then(res => addNotification('Angle Updated', 'success'))
      .catch(err => {
        globalErrorHandler(err);
        addNotification('Error updating point', 'error');
      });
  };

  const incrementAngle = (amount: number) => {
    setOffsetAngle(prevState => prevState + amount);
  };

  useEffect(() => {
    setOffsetAngle(imageData.data.angle);
    console.log('Image Data', imageData);
  }, [imageData]);

  return (
    <Flex
      style={{
        flexDirection: 'column',
        position: 'fixed',
        bottom: '0',
        zIndex: 10,
        background: '#000',
        color: '#0f0',
        padding: '15px 20px',
        gap: '10px',
        fontSize: '0.9em',
        opacity: '65%',
      }}>
      <Grid columns="0.5fr 1fr" gap="5px" columnGap="15px" style={{ width: '100%' }}>
        <Grid.Item style={{ textAlign: 'right' }}>{imageData.data.id}</Grid.Item>
        <Grid.Item>Image Id</Grid.Item>
        <Grid.Item style={{ textAlign: 'right' }}>{imageData.data.height}</Grid.Item>
        <Grid.Item>Height</Grid.Item>
        <Grid.Item style={{ textAlign: 'right' }}>{imageData.data.angle}&#176;</Grid.Item>
        <Grid.Item>Image Offset</Grid.Item>
        <Grid.Item style={{ textAlign: 'right' }}>{offsetAngle}&#176;</Grid.Item>
        <Grid.Item>Current offset</Grid.Item>
        <Grid.Item style={{ textAlign: 'right' }}>
          {viewerPosition?.yaw?.toFixed(4)}&#176;
        </Grid.Item>
        <Grid.Item>Rotation</Grid.Item>
        <Grid.Item style={{ textAlign: 'right' }}>
          {viewerPosition?.pitch?.toFixed(4)}&#176;
        </Grid.Item>
        <Grid.Item>Pitch</Grid.Item>
      </Grid>

      <Flex style={{ alignItems: 'center', gap: '10px' }}>
        <IconButton
          size={25}
          icon={iconBack}
          onClick={() => incrementAngle(15)}
          style={{
            position: 'unset',
            backgroundColor: 'black',
            border: '1px solid #0f0',
            borderRadius: '5px',
          }}
        />
        <IconButton
          size={25}
          icon={iconBack}
          onClick={() => incrementAngle(1)}
          style={{
            position: 'unset',
            backgroundColor: 'black',
            border: '1px solid #0f0',
            borderRadius: '5px',
          }}
        />
        <IconButton
          size={25}
          icon={iconForward}
          onClick={() => incrementAngle(-1)}
          style={{
            position: 'unset',
            backgroundColor: 'black',
            border: '1px solid #0f0',
            borderRadius: '5px',
          }}
        />
        <IconButton
          size={25}
          icon={iconForward}
          onClick={() => incrementAngle(-15)}
          style={{
            position: 'unset',
            backgroundColor: 'black',
            border: '1px solid #0f0',
            borderRadius: '5px',
          }}
        />

        <div
          onClick={updateAngle}
          style={{
            cursor: 'pointer',
            height: '25px',
            padding: '3px 10px',
            backgroundColor: 'black',
            border: '1px solid #0f0',
            borderRadius: '5px',
          }}>
          Save
        </div>
      </Flex>
    </Flex>
  );
};

const angleLimit = (angle: number): number => {
  if (angle > 360) {
    return angleLimit(angle - 360);
  } else if (angle < 0) {
    return angleLimit(angle + 360);
  } else {
    return angle;
  }
};
