import React from 'react';
import styled from 'styled-components';
import Flex from '@react-css/flex';

const ModeButton = styled.div`
  font-size: 14px;
  margin-right: 24px;
  color: #7e858e;

  line-height: 20px;

  cursor: pointer;
  user-select: none;

  &.active {
    font-weight: 700;
    color: #073c7a;

    border-bottom: 2px solid #073c7a;
  }

  &:before {
    display: block;
    content: attr(title);
    font-weight: 700;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const ModeSelector = (props: {
  items: string[];
  activeItem: string;
  setActiveItem: (item: string) => void;
}) => {
  return (
    <Flex>
      {props.items.map(item =>
        item === props.activeItem ? (
          <ModeButton title={item} key={item} className="active">
            {item}
          </ModeButton>
        ) : (
          <ModeButton title={item} key={item} onClick={() => props.setActiveItem(item)}>
            {item}
          </ModeButton>
        )
      )}
    </Flex>
  );
};
