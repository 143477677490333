import React, { CSSProperties, useState } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

interface IIconButtonBaseProps {
  style?: CSSProperties;
  className: string;
  onClick?: () => void;
  hoverCSS?: string;
}

const IconButtonBase = styled.div<IIconButtonBaseProps>`
  position: absolute;
  bottom: 8px;
  right: 8px;

  background-color: white;
  transition: background-color 0.3s;
  padding: 8px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  user-select: none;

  &.primary {
    background-color: #073c7a;
  }

  &.disabled {
    background-color: #92989f;
  }
`;

interface IconButtonProps {
  icon: string;
  hoverIcon?: string;
  size: number;
  tooltip?: string;
  primary?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  hoverStyle?: CSSProperties;
  classNames?: string;
  onClick?: () => void;
}

export function IconButton({
  icon,
  hoverIcon,
  size,
  tooltip,
  primary,
  disabled,
  style,
  hoverStyle,
  classNames: classNamesProps,
  onClick,
}: IconButtonProps) {
  const [isHovering, setIsHovering] = useState<boolean>(false);

  const baseStyles: CSSProperties = {
    width: `${size}px`,
    height: `${size}px`,
  };

  const currentIcon: string = hoverIcon && isHovering ? hoverIcon : icon;

  return (
    <IconButtonBase
      style={{ ...baseStyles, ...style, ...(isHovering ? hoverStyle : {}) }}
      className={classNames({ primary, disabled }) + ' ' + classNamesProps}
      onClick={onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      data-tip={tooltip}>
      <img src={currentIcon} alt="" />
    </IconButtonBase>
  );
}
