import { useEffect, useState } from 'react';
import { ViewerPosition } from '../types';
import { useSearchParam } from 'react-use';

export const useViewerPosition = () => {
  const [viewerPosition, setViewerPosition] = useState<ViewerPosition>({
    yaw: 0,
    pitch: 0,
    hfov: 120,
  });
  const pitchParam = useSearchParam('pitch');
  const yawParam = useSearchParam('yaw');
  const hfovParam = useSearchParam('hfov');

  const getInitialPosition = (isMaster: boolean) => {
    let initialPosition = { ...viewerPosition };

    // Try reading initial position from query params
    if (pitchParam && yawParam) {
      initialPosition.pitch = parseFloat(pitchParam);
      initialPosition.yaw = parseFloat(yawParam);
      if (hfovParam) {
        initialPosition.hfov = parseFloat(hfovParam);
      }
    }

    return initialPosition;
  };

  const updateViewerPosition = (newValues: any) => {
    let newVal = { ...viewerPosition, ...newValues };
    setViewerPosition(newVal);
  };

  useEffect(() => {
    setViewerPosition(getInitialPosition(true));
  }, []);

  return { viewerPosition, updateViewerPosition, getInitialPosition };
};
