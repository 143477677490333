import { MapPointContainer } from "../../../../common/MapViewer";
import iconRobot from '../../../../../assets/images/icon_robot.svg';
import iconAntenna from '../../../../../assets/images/icon_antenna.svg';
import iconStation from '../../../../../assets/images/icon_station.svg';
import { IEquipment } from "./ManageEquipmentFloorPlanView";
import styled from 'styled-components';
import { useBuildingContext } from "../../../../../contexts/buildingContext";

export enum EquipmentType {
  Robot = 'Robot',
  Antenna = 'Antenna',
  Station = 'Station',
}

export const equipmentColors = {
  [EquipmentType.Robot]: '#FB7185',
  [EquipmentType.Antenna]: '#10B981',
  [EquipmentType.Station]: '#22D3EE',
};

interface IEquipmentIconProps {
  item: IEquipment;
  showEquipmentInfo: boolean;
}

export const EquipmentIcon = ({
  item,
  showEquipmentInfo,
}: IEquipmentIconProps) => {
  const {
    updateFloor
  } = useBuildingContext();

  let icon: string = '';
  let stroke: string = '';

  switch (item.equipment_type_name) {
    case EquipmentType.Robot:
      icon = iconRobot;
      stroke = equipmentColors[EquipmentType.Robot];
      break;
    case EquipmentType.Antenna:
      icon = iconAntenna;
      stroke = equipmentColors[EquipmentType.Antenna];
      break;
    case EquipmentType.Station:
      icon = iconStation;
      stroke = equipmentColors[EquipmentType.Station];
      break;
  }

  const onMouseEnter = () => {
    updateFloor({
      hoverEquipment: item.id,
    })
  }

  const onMouseLeave = () => {
    updateFloor({
      hoverEquipment: null,
    });
  }

  return (
    <MapPointContainer
      x={item.x}
      y={item.y}
      isActive
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <img
        src={icon}
        alt='highlight'
        style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
      />
      <Ellipse 
        stroke={stroke}
      />

      { showEquipmentInfo &&
        <EquipmentInfo>
          <div>{`Name: ${item.name}`}</div>
          <div>{item.status ? `Status: ${item.status}` : ''}</div>
        </EquipmentInfo>
      }
    </MapPointContainer>
  )
}

interface IEllipseProps {
  stroke: string;
}

const Ellipse = ({
  stroke,
}: IEllipseProps) => {
  return (
    <svg
      width="150"
      height="150"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: 'absolute', transform: 'translate(-50%, -50%)' }}
    >
      <circle cx="15.3384" cy="15.3384" r="13.3384" stroke={stroke} stroke-width="4"/>
    </svg>
  )
}

const EquipmentInfo = styled.div`
  transform: translate(-50%, -50%);
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.75);
  bottom: 140px;
  padding: 15px;
  font-size: 36px;
  background-color: white;
`