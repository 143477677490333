import styled from 'styled-components';
import { Icon } from '../../../../common/Icon';
import iconHouse from '../../../../../assets/images/icon_house.svg';
import iconClock from '../../../../../assets/images/icon_clock.svg';
import { useSafetyContext } from '../../../../../contexts/safetyContext';
import { Observation } from '../../../../../api/observations';
import { SEVERITY_COLOR_MAP } from '../../../../../data/safety';
import { useCallback, useMemo, useState } from 'react';
import {
  ObservationTableData,
  ObservationTableRow,
  ObservationType,
  ProjectFloor,
  ProjectFloorCode,
  ScrollableObservationTableContainer,
  Status,
} from './styles';
import { AddProjectButton } from '../../../buildings_page/components/AddProjectButton';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';

interface IGalleryObservationTableProps {
  loadMoreObservations: () => Promise<void>;
  showMoreObservationsButtonVisible?: boolean;
}

export const GalleryObservationTable = ({
  loadMoreObservations,
  showMoreObservationsButtonVisible = false,
}: IGalleryObservationTableProps) => {
  const { state: SafetyState, updateSafety } = useSafetyContext();

  const [observationsLoading, setObservationsLoading] = useState<boolean>(false);

  const onClickObservationTableRow = useCallback(
    (observation: Observation) => {
      updateSafety({
        activeObservation: observation,
      });
    },
    [updateSafety]
  );

  const onClickLoadMore = useCallback(async () => {
    setObservationsLoading(true);

    try {
      await loadMoreObservations();
    } catch {
      console.error('Failed to load more observations');
    } finally {
      setObservationsLoading(false);
    }
  }, [loadMoreObservations]);

  const deduplicatedObservations = useMemo(() => {
    const seenIds = new Set<number>();

    return SafetyState.observations.filter((observation: Observation) => {
      if (seenIds.has(observation.id)) {
        return false;
      }

      seenIds.add(observation.id);

      return true;
    });
  }, [SafetyState.observations]);

  return (
    <ScrollableObservationTableContainer>
      {deduplicatedObservations.length === 0 && (
        <NoObservationsContainer>No observations for the given period</NoObservationsContainer>
      )}
      {deduplicatedObservations.length > 0 && (
        <table>
          <tbody>
            {deduplicatedObservations.map((observation: Observation) => {
              const isActiveObservation = SafetyState.activeObservation?.id === observation.id;
              const severity = !observation.unsafe ? 'SAFE' : observation.severity;

              return (
                <ObservationTableRow
                  key={observation.id}
                  active={isActiveObservation}
                  onClick={() => onClickObservationTableRow(observation)}>
                  <ObservationTableData>
                    <ObservationType>{observation.type.name}</ObservationType>
                  </ObservationTableData>
                  <ObservationTableData>
                    <ProjectFloor>
                      <Icon icon={iconHouse} size={12} />
                      <ProjectFloorCode>
                        {observation.is_manual
                          ? observation.project_floor.name
                          : observation?.image.processed_image.viewpoints_image.project_floor}
                      </ProjectFloorCode>
                    </ProjectFloor>
                  </ObservationTableData>
                  <ObservationTableData>
                    <TakenOn>
                      <Icon icon={iconClock} size={12} />
                      <TakenOnPrintout>
                        {observation?.image.processed_image?.viewpoints_image.taken_on}
                      </TakenOnPrintout>
                    </TakenOn>
                  </ObservationTableData>
                  <ObservationTableData align="right">
                    <Status statusColorHex={SEVERITY_COLOR_MAP[severity]}>
                      {severity.replace('SEVERITY_', '')}
                    </Status>
                  </ObservationTableData>
                </ObservationTableRow>
              );
            })}
          </tbody>
        </table>
      )}
      {showMoreObservationsButtonVisible && (
        <LoadMoreButtonContainer>
          {!observationsLoading && (
            <AddProjectButton
              text="Load More"
              onClick={onClickLoadMore}
              buttonStyle={{ marginBottom: '5px' }}
            />
          )}
          {observationsLoading && <LoadingIndicator size={55} />}
        </LoadMoreButtonContainer>
      )}
    </ScrollableObservationTableContainer>
  );
};

const TakenOn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100px;
`;

const TakenOnPrintout = styled.div`
  font-family: Frutiger LT;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1px;
`;

const NoObservationsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
`;

const LoadMoreButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
