import { useQuery } from "react-query";
import { useBuildingContext } from "../../../../contexts/buildingContext";
import { fetchProjectTrackerMetricSummary, fetchFireExtinguisherViewpointDistanceMetrics, fetchFireExtinguisherViewpointDistanceSummary, fetchGuardrailObservationSummary, fetchGuardrailObservations, fetchLightingMetricSummary, fetchPPEMetricSummary } from "../../../../api/safety";
import { listObservationStats } from "../../../../api/observations";

export const useFetchObservationStatsQuery = (projectId: string, filters: any) => {
  return useQuery(
    ['observation-stats', projectId, filters.start, filters.end, filters.limit, filters.claimed, filters.type_codes, filters.archived],
    async () => {
      return await listObservationStats(projectId, filters);
    },
    { enabled: !!projectId }
  );
}

export const useFetchLightingMetricSummaryQuery = (projectId?: string, floorCode?: string) => {
  return useQuery(
    ['lighting-metric-summary', projectId, floorCode],
    async () => {
      return await fetchLightingMetricSummary({project_id: projectId, floor_code: floorCode});
    }
  );
}

export const useFetchPPEMetricSummaryQuery = (projectId?: string, floorCode?: string) => {
  return useQuery(
    ['ppe-metric-summary', projectId, floorCode],
    async () => {
      return await fetchPPEMetricSummary({project_id: projectId, floor_code: floorCode});
    }
  );
}

export const useFetchGuardrailObservationSummaryQuery = (projectId?: string, floorCode?: string) => {
  return useQuery(
    ['guardrail-observation-summary', projectId, floorCode],
    async () => {
      return await fetchGuardrailObservationSummary({project_id: projectId, floor_code: floorCode});
    }
  );
}

export const useFetchFireExtinguisherViewpointDistanceSummaryQuery = (projectId?: string, floorCode?: string) => {
  return useQuery(
    ['fire-extinguisher-viewpoint-distance-summary', projectId, floorCode],
    async () => {
      return await fetchFireExtinguisherViewpointDistanceSummary({project_id: projectId, floor_code: floorCode});
    }
  );
}

export const useFetchProjectTrackerMetricSummaryQuery = (projectId: string, trackerName: string) => {
  return useQuery(
    ['project-tracker-metric-summary', projectId, trackerName],
    async () => {
      return await fetchProjectTrackerMetricSummary(projectId, trackerName);
    }
  );
}

export const useGuardrailObservationsQuery = () => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;
  const floorId = buildingState.floorId;

  return useQuery(
    ['guardrail-observations', projectId, floorId],
    async () => {
      return await fetchGuardrailObservations({project_id: projectId, floor_code: floorId});
    },
    { enabled: !!projectId && !!floorId }
  );
}

export const useFetchFireExtinguisherViewpointDistanceMetrics = () => {
  const {state: buildingState} = useBuildingContext();
  const projectId = buildingState.projectId;
  const floorId = buildingState.floorId;

  return useQuery(
    ['fire-extinguisher-viewpoint-distance', projectId, floorId],
    async () => {
      return await fetchFireExtinguisherViewpointDistanceMetrics({project_id: projectId, floor_code: floorId});
    },
    { enabled: !!projectId && !!floorId }
  );
}