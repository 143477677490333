import { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { ViewerPosition } from '../views/image_viewer/types';
import { useAutodeskScript } from '../../hooks/useAutodeskScript';
import { useViewer3D } from '../../hooks/useViewer3D';
import { useKnownPosition } from '../../hooks/useKnownPosition';
import { useRealtimePosition } from '../../hooks/useRealtimePosition';
import { applyViewerPositionDelta, reverseViewerPositionDelta } from '../../utils';

//Temporary solution to loading multiple files
const KAYE_FILE_URNS = [
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQTkwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdWJzdHJ1Y3R1cmUlMjBFeGNhdmF0aW9uJlNob3JpbmclMjBTeXN0ZW1fUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjEwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdGFpcnNfUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjEwJTIwMjIwMCUyMEtheWVfQ0wlMjBTdHJ1Y3R1cmVfUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQjIwJTIwMjIwMCUyMEtheWVfQ0wlMjBFeHRlcmlvciUyMEVuY2xvc3VyZV9SMjAyMS5ydnQ=',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQzEwJTIwMjIwMCUyMEtheWVfQ0wlMjBJbnRlcmlvcl9SMjAyMS5ydnQ=',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvQ0wlMjAyMjAwJTIwS2F5ZV9DZW50cmFsLnJ2dA==',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDIwLUQ3MCUyMDIyMDAlMjBLYXllX0NMJTIwQ2VpbGluZyUyMERldmljZXNfUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRzIwJTIwMjIwMCUyMEtheWVfQ0wlMjBMYW5kc2NhcGVfUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDMwX0tBWUVfSFZBQ19SMjAyMS5ydnQ=',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDMwX0tBWUVfSFlEUk9OSUNfUjIwMjEucnZ0',
  'urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6ZGlkZ2UvRDIwX0tBWUVfUGx1bWJpbmdfUjIwMjEucnZ0',
];

interface ForgeViewerProps {
  position: THREE.Vector3;
  viewerPosition: ViewerPosition;
  viewerPositionDelta?: ViewerPosition;
  followRealtime?: boolean;
  fileUrn: string;
  sync: boolean;
  initialRotation: number;
  onDragging?: (isActive: boolean) => void;
  onUpdate?: (newValues: ViewerPosition) => void;
  onLoaded: (loaded: boolean) => void;
  projectId: string;
}

export const ForgeViewer = ({
  position,
  viewerPosition,
  viewerPositionDelta,
  followRealtime = false,
  fileUrn,
  sync,
  initialRotation,
  onDragging,
  onUpdate,
  onLoaded,
  projectId,
}: ForgeViewerProps) => {
  const [loaded, setLoaded] = useState(false);
  const { autodeskModule } = useAutodeskScript();
  const { viewer, getViewerPosition, setViewerPosition } = useViewer3D(autodeskModule);
  const isMouseDown = useRef<boolean>(false);
  const { registerCallback, unregisterCallback, updateRealtimePosition } = useRealtimePosition();

  const handleUpdate = useCallback(
    (positionToPropagate: ViewerPosition) => {
      if (!isMouseDown.current && !followRealtime) {
        onUpdate && onUpdate(positionToPropagate);
      }
    },
    [followRealtime, onUpdate]
  );

  const handleLookAt = useCallback(
    (positionToLookAt: ViewerPosition) => {
      if (!isMouseDown.current) {
        setViewerPosition(positionToLookAt);
      }
    },
    [setViewerPosition]
  );

  const { updateKnownPosition } = useKnownPosition(handleLookAt, handleUpdate);

  const handleProgressUpdate = useCallback(
    (e: { percent: number; state: number; model: any }) => {
      if (e.percent === 1) {
        viewer.setBimWalkToolPopup(false);
        viewer.setNavigationLock(true);

        // Invert mouse movement to be compatible with
        const bimWalk = viewer.getExtension('Autodesk.BimWalk');
        if (bimWalk) {
          bimWalk.tool.navigator.configuration.mouseTurnInverted = true;
        }

        // Notify this React component ForgeViewer loaded the document
        setLoaded(true);
      }
    },
    [viewer]
  );

  const handleDragging = useCallback(
    (active: boolean) => {
      isMouseDown.current = active;
      onDragging && onDragging(active);
    },
    [onDragging]
  );

  const handleEscape = useCallback(() => {
    // For debugging positions, press esc to print position
    console.log(viewer.navigation.getPosition());
    const camera = viewer.navigation.getCamera();
    let adjusted = position.clone();
    const target = viewer.navigation.getTarget();
    adjusted = rotate(position, target, 90);
    camera.fov = viewerPosition.hfov;
    viewer.navigation.setView(position, adjusted);
  }, [position, viewer, viewerPosition]);

  const handleFinalFrameRenderedChanged = useCallback(
    event => {
      if (event.value.finalFrame && !isMouseDown.current && !followRealtime) {
        const nativePosition = getViewerPosition();
        updateKnownPosition(nativePosition, true, false);
      }
    },
    [followRealtime, getViewerPosition, updateKnownPosition]
  );

  // Registers itself for realtime updates if mouse is not down
  useEffect(() => {
    const handlePositionUpdate = (position: ViewerPosition) => {
      if (followRealtime) {
        if (viewerPositionDelta) {
          const actualPosition = applyViewerPositionDelta(position, viewerPositionDelta);
          handleLookAt(actualPosition);
        } else {
          handleLookAt(position);
        }
      }
    };
    registerCallback(handlePositionUpdate);
    return () => {
      unregisterCallback(handlePositionUpdate);
    };
  }, [followRealtime, handleLookAt, registerCallback, unregisterCallback, viewerPositionDelta]);

  // Listen to Autodesk viewer events
  useEffect(() => {
    if (autodeskModule && viewer) {
      const Autodesk = autodeskModule;
      viewer.addEventListener(Autodesk.Viewing.PROGRESS_UPDATE_EVENT, handleProgressUpdate);
      viewer.addEventListener(Autodesk.Viewing.ESCAPE_EVENT, handleEscape);
      viewer.addEventListener(
        Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
        handleFinalFrameRenderedChanged
      );
      return () => {
        viewer.removeEventListener(Autodesk.Viewing.PROGRESS_UPDATE_EVENT, handleProgressUpdate);
        viewer.removeEventListener(Autodesk.Viewing.ESCAPE_EVENT, handleEscape);
        viewer.removeEventListener(
          Autodesk.Viewing.FINAL_FRAME_RENDERED_CHANGED_EVENT,
          handleFinalFrameRenderedChanged
        );
      };
    }
  }, [autodeskModule, handleEscape, handleFinalFrameRenderedChanged, handleProgressUpdate, viewer]);

  // Keeps track of mouse moves and live-updates
  useEffect(() => {
    if (viewer) {
      const handleMouseDown = (event: MouseEvent) => {
        handleDragging(true);
      };
      const handleMouseUp = (event: MouseEvent) => {
        handleDragging(false);
      };
      const handleMouseMove = (event: MouseEvent) => {
        if (isMouseDown.current) {
          const nativePosition: ViewerPosition = getViewerPosition();
          if (viewerPositionDelta) {
            const actualPosition = reverseViewerPositionDelta(nativePosition, viewerPositionDelta);
            updateRealtimePosition(actualPosition);
          } else {
            updateRealtimePosition(nativePosition);
          }
        }
      };
      const canvas = viewer.canvas;
      canvas.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
      return () => {
        canvas.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [viewer, updateRealtimePosition, handleDragging, getViewerPosition, viewerPositionDelta]);

  // Load Autodesk document into viewer
  useEffect(() => {
    if (viewer && autodeskModule) {
      const Autodesk = autodeskModule;

      const handleDocumentSuccess = (viewerDocument: any) => {
        let defaultModel = viewerDocument.getRoot().getDefaultGeometry();
        if (viewer) {
          if (projectId === '99Vt3MfWNCN7') {
            viewer.loadDocumentNode(viewerDocument, defaultModel, {
              keepCurrentModels: true,
              globalOffset: { x: 0, y: 0, z: 0 },
            });
          } else {
            viewer.loadDocumentNode(viewerDocument, defaultModel);
          }
        }
      };

      const handleDocumentFailure = () => {
        console.error('Failed fetching Forge manifest');
      };

      if (projectId === '99Vt3MfWNCN7') {
        KAYE_FILE_URNS.forEach(urn => {
          Autodesk.Viewing.Document.load(urn, handleDocumentSuccess, handleDocumentFailure);
        });
      } else {
        Autodesk.Viewing.Document.load(fileUrn, handleDocumentSuccess, handleDocumentFailure);
      }
    }
  }, [autodeskModule, fileUrn, projectId, viewer]);

  // Notifies Autodesk document was loaded
  useEffect(() => {
    onLoaded(loaded);
  }, [loaded, onLoaded]);

  // Stand at given position in the BIM model
  useEffect(() => {
    if (!viewer || !position || !loaded) return;

    const cameraPosition = new THREE.Vector3(position.x, position.y, position.z);

    // Get the current camera position for comparison
    const currentCameraPosition = viewer.navigation.getPosition();

    // Check if there is a significant change in position
    const positionChange = cameraPosition.distanceTo(currentCameraPosition);
    console.log('ForgeViewer Position changed', positionChange);
    if (positionChange > 1.0) {
      viewer.navigation.setPosition(cameraPosition);
    }
  }, [position, loaded, viewer]);

  // Updates internal viewer position when property value from parent changes
  useEffect(() => {
    if (!isMouseDown.current && !followRealtime && loaded) {
      updateKnownPosition(viewerPosition, false);
    }
  }, [followRealtime, updateKnownPosition, viewerPosition, loaded]);

  return <div id="forgeViewer" />;
};

function rotate(center: THREE.Vector3, p: THREE.Vector3, angle: number) {
  angle = angle * (Math.PI / 180);
  let s = Math.sin(angle);
  let c = Math.cos(angle);
  // translate point back to origin:
  p.x -= center.x;
  p.y -= center.y;
  // rotate point
  let xnew = p.x * c - p.y * s;
  let ynew = p.x * s + p.y * c;
  // translate point back:
  p.x = xnew + center.x;
  p.y = ynew + center.y;
  return p;
}
