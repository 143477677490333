import { useCallback, useEffect, useState } from "react";
import { getAccessToken, procoreLoginUrl, verifyProcoreToken } from "../../../api/procore";
import { useQueryParams } from "../../../hooks/useQueryParams";
import { LoadingIndicator } from "../../common/LoadingIndicator";
import { CardBase, CardDesc, CardDescItem, CardImage, CardTitle, CardTitleInner } from "../buildings_page/components/Card";
import procoreLogo from '../../../assets/images/logos/procore_logo.jpg';
import { AddProjectButton } from "../buildings_page/components/AddProjectButton";

export const Integrations = () => {
  const queryParams = useQueryParams();
  const authorizationCode = queryParams.get('code');

  const [linkedWithProcore, setLinkedWithProcore] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);

  const checkUserProcoreTokens = useCallback(() => {
    verifyProcoreToken()
      .then(() => {
        setLinkedWithProcore(true);
      })
      .catch(() => {
        setLinkedWithProcore(false);
      })
      .finally(() => {
        setDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (!linkedWithProcore) {
      setDataLoaded(false);

      if (authorizationCode) {
        getAccessToken(authorizationCode)
          .then(() => {
            setLinkedWithProcore(true);
          })
          .catch(() => {
            checkUserProcoreTokens();
          })
          .finally(() => {
            setDataLoaded(true);
          })
      } else {
        checkUserProcoreTokens();
      }
    }
  }, [authorizationCode, linkedWithProcore, checkUserProcoreTokens]);

  const openProcoreLoginUrl = () => {
    if (!linkedWithProcore) {
      window.location.href = procoreLoginUrl;
    }
  }

  return (
    <div>
      {!dataLoaded &&
        <LoadingIndicator/>
      }
      {dataLoaded &&
        <CardBase
          onClick={openProcoreLoginUrl}
          style={{
            cursor: linkedWithProcore ? 'default' : 'pointer',
          }}
        >
          <CardImage src={procoreLogo}/>
          <CardTitle>
            <CardTitleInner>
              Procore
            </CardTitleInner>
          </CardTitle>
          <CardDesc
            style={{
              marginTop: '5px',
            }}
          >
            <CardDescItem>
              {linkedWithProcore &&
                'Account linked successfully'
              }
              {!linkedWithProcore &&
                <AddProjectButton
                  text="Authenticate with Procore"
                />
              }
            </CardDescItem>
          </CardDesc>
        </CardBase>
      }
    </div>
  )
}