import { CSSProperties, useState } from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { FloorPlanView } from '../../../building_page/components/FloorPlanView';
import styled from 'styled-components';
import { ViewSelector } from '../../../../common/ViewSelector/ViewSelector';

import { EquipmentIcon, EquipmentType } from './EquipmentIcon';
import { DeviceSelector } from './DeviceSelector';
import { useFloorEquipmentQuery } from '../../hooks/adminBuildingQueries';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';

export interface IEquipment {
  id: number;
  name: string;
  equipment_type_name: EquipmentType;
  project_floor_name: string;
  x: number;
  y: number;
  status?: string;
}

export const ManageEquipmentFloorPlanView = () => {
  const { updateFloor, state: buildingState } = useBuildingContext();

  const [devicesExpanded, setDevicesExpanded] = useState<boolean>(true);

  const zoomIconStyle: CSSProperties = { position: 'absolute', right: '0px', zIndex: 2 };
  const zoomInIconStyle: CSSProperties = { ...zoomIconStyle, bottom: '55px' };
  const zoomOutIconStyle: CSSProperties = { ...zoomIconStyle, bottom: '10px' };

  const { equipment, visibleEquipment } = buildingState.floorData;

  const updateFloorDesc = (data: any) => {
    updateFloor(data);
  };

  const { isLoading: equipmentLoading, isIdle: equipmentIdle } =
    useFloorEquipmentQuery(updateFloorDesc);

  if (equipmentLoading || equipmentIdle) {
    return <LoadingIndicator />;
  }

  const equipmentIcons = equipment.map((item: IEquipment) => {
    if (visibleEquipment.has(item.id)) {
      return (
        <EquipmentIcon
          key={item.id}
          item={item}
          showEquipmentInfo={item.id === buildingState.floorData.hoverEquipment}
        />
      );
    } else {
      return <></>;
    }
  });

  const showEquipmentStatus = (item: IEquipment) => {
    return <EquipmentStatusDisplay>{item.status}</EquipmentStatusDisplay>;
  };

  return (
    <>
      <FloorPlanView
        floorData={buildingState.floorData}
        hideDateRangeSelector
        zoomInIconStyle={zoomInIconStyle}
        zoomOutIconStyle={zoomOutIconStyle}
        mapViewerChildren={equipmentIcons}
        showPoints={false}
      />
      <ViewSelectorContainer>
        <ViewSelector
          title="Devices"
          initialViewSelectorExpanded
          viewingExpanded={devicesExpanded}
          setViewingExpanded={setDevicesExpanded}
          maxHeight={275}>
          <DeviceSelector
            equipment={buildingState.floorData.equipment}
            generateFarText={showEquipmentStatus}
          />
        </ViewSelector>
      </ViewSelectorContainer>
    </>
  );
};

const ViewSelectorContainer = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  flex-direction: column;
`;

const EquipmentStatusDisplay = styled.span`
  right: 0px;
  bottom: auto;
  position: absolute;
  font-weight: 600;
`;
