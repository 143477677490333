import { useUserContext } from "../../../contexts/userContext";
import { UserInformationField } from "./UserInformationField";

export const UserInformation = () => {
  const {
    state: userState,
  } = useUserContext();

  return (
    <div>
      <UserInformationField label='First Name' value={userState.first_name} disabled/>
      <UserInformationField label='Last Name' value={userState.last_name} disabled/>
    </div>
  )
}