import React, { useRef } from 'react';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import { StyledDropdown } from './styles';

interface DropdownProps {
  children: any;
  display: boolean;
  setDisplay: (v: boolean) => void;
  style?: any;
}
export const Dropdown = ({ children, display, setDisplay, style }: DropdownProps) => {
  const dropdownRef: any = useRef();
  useOnClickOutside(dropdownRef, () => setDisplay(false));

  if (!display) return <></>;
  return (
    <StyledDropdown ref={dropdownRef} style={style}>
      {children}
    </StyledDropdown>
  );
};
