import React, { CSSProperties } from 'react';
import { useUserContext } from '../../contexts/userContext';
import styled from 'styled-components';
import { Icon } from './Icon';
import imgSignOut from '../../assets/images/icon_sign_out.svg';
import imgAdminZone from '../../assets/images/icon_lock_open_black.svg';
import imgMyProfile from '../../assets/images/icon_settings_black.svg';
import imgCamera from '../../assets/images/icon_camera_black.svg';
import Flex from '@react-css/flex';
import { resetMixpanel, trackEvent } from '../../analytics';
import { Dropdown } from './Dropdown/Dropdown';
import { PermissionWrappedComponent } from './PermissionWrapper/PermissionWrapper';
import { useNavigation } from '../../hooks/useNavigation';
import { useSiteWalkNavigation } from '../views/site_walk/hooks/useSiteWalkNavigation';
import { useMsal } from '@azure/msal-react';

const DropdownLink = styled.p`
  cursor: pointer;
`;

const DropdownRow = (props: {
  icon: string;
  iconStyle?: CSSProperties;
  onClick: () => void;
  linkText: string;
  lastRow?: boolean;
}) => {
  return (
    <Flex flexDirection="row" style={{ marginBottom: props.lastRow ? '0px' : '15px' }}>
      <Icon icon={props.icon} size={16} style={{ marginRight: '6px', ...props.iconStyle }} />
      <DropdownLink onClick={props.onClick}>{props.linkText}</DropdownLink>
    </Flex>
  );
};

export const UserMenu = (props: { display: boolean; setDisplay: (v: boolean) => void }) => {
  const { resetUser } = useUserContext();
  const { navigateToAdminZone, navigateToUserProfile } = useNavigation();
  const { navigateToSiteWalkPage } = useSiteWalkNavigation();

  const { instance } = useMsal();
  const activeAccount = instance?.getActiveAccount();
  const userAuthenticatedWithMsal = !!activeAccount;

  const closeMenu = () => {
    props.setDisplay(false);
  }

  const handleMsalLogout = () => {
    instance.setActiveAccount(null);
    // instance.logout()
  };

  const logout = () => {
    if (userAuthenticatedWithMsal) {
      handleMsalLogout();
    }

    resetUser();
    localStorage.clear();
    trackEvent('Logout');
    resetMixpanel();

    closeMenu();
  };

  const enterAdminZone = () => {
    navigateToAdminZone();
    closeMenu();
  };

  const viewMyProfile = () => {
    navigateToUserProfile();
    closeMenu();
  }

  const goToSiteWalkPage = () => {
    navigateToSiteWalkPage();
    closeMenu();
  }

  if (props.display) {
    return (
      <Dropdown
        display={props.display}
        setDisplay={props.setDisplay}
        style={{ minWidth: 'unset', right: '75px', top: '-32px', zIndex: 3 }}>
        <DropdownRow icon={imgMyProfile} onClick={viewMyProfile} linkText='My Profile'/>

        <PermissionWrappedComponent permission='project_tables.admin_zone_access'>
          <DropdownRow
            icon={imgAdminZone}
            onClick={enterAdminZone}
            linkText='Admin Zone'
          />
        </PermissionWrappedComponent>
        <DropdownRow icon={imgCamera} onClick={goToSiteWalkPage} linkText="Site Walk" />
        <DropdownRow icon={imgSignOut} onClick={logout} linkText="Logout" lastRow />
      </Dropdown>
    );
  }
  return null;
};
