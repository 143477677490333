import { useState } from 'react';
import { useProcoreCalls } from '../../../../api/procore';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { useGetImageCategoriesQuery } from '../hooks/procoreQueries';
import {
  RFIFormButton,
  RFIFormButtonsContainer,
  RFIFormDivider,
  RFIFormRow,
  RFIFormSelect,
  RFIFormTitle,
} from './styles';
import styled from 'styled-components';
import { LoadingIndicator } from '../../LoadingIndicator';

interface ISaveImageFormProps {
  onCloseForm: () => void;
  onTakeScreenshot(callback: (blob: Blob) => void): void;
}

export const SaveImageForm = ({ onCloseForm, onTakeScreenshot }: ISaveImageFormProps) => {
  const [selectedImageCategory, setSelectedImageCategory] = useState<number | undefined>(undefined);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const { addNotification } = useNotifications();
  const { uploadImageToProcore } = useProcoreCalls();
  const { data: imageCategories } = useGetImageCategoriesQuery();

  const onErrorUploadingImage = (err: unknown) => {
    addNotification('Error uploading screenshot', 'error');
    console.log('Error uploading image: ', err);
  };

  const handleSaveClick = async () => {
    setSaveInProgress(true);
    onTakeScreenshot(async (blob: Blob) => {
      try {
        await uploadImageToProcore(blob, 'screen.png', selectedImageCategory);
        addNotification('Screenshot uploaded successfully', 'success');
        onCloseForm();
      } catch (err) {
        onErrorUploadingImage(err);
      } finally {
        setSaveInProgress(false);
      }
    });
  };

  return (
    <SaveImageFormContainer>
      <RFIFormTitle>Choose Image Directory</RFIFormTitle>

      <RFIFormDivider />

      {saveInProgress && (
        <LoadingIndicator
          size={57}
          containerStyle={{
            backgroundColor: 'transparent',
            width: '345px',
          }}
        />
      )}

      {!saveInProgress && (
        <RFIFormRow>
          <RFIFormSelect
            style={{ width: '345px' }}
            label="Directory"
            value={selectedImageCategory}
            onChange={newVal =>
              setSelectedImageCategory(newVal !== undefined ? parseInt(newVal) : newVal)
            }
            options={imageCategories ?? []}
            mapOptionValue={option => option.id.toString()}
            mapOptionLabel={option => option.name}
          />
        </RFIFormRow>
      )}
      <RFIFormButtonsContainer>
        {!saveInProgress && <RFIFormButton onClick={onCloseForm}>Cancel</RFIFormButton>}
        <RFIFormButton primary disabled={saveInProgress} onClick={handleSaveClick}>
          Save
        </RFIFormButton>
      </RFIFormButtonsContainer>
    </SaveImageFormContainer>
  );
};

const SaveImageFormContainer = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: column;
`;
