import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '@react-css/flex';
import Moment from 'react-moment';
import axios from 'axios';

import { Observation } from '../../../../../api/observations';
import { SEVERITY_COLOR_MAP } from '../../../../../data/safety';
import { LoadingIndicator } from '../../../../common/LoadingIndicator';
import { Icon } from '../../../../common/Icon';
import { useSafetyContext } from '../../../../../contexts/safetyContext';

import iconClock from '../../../../../assets/images/icon_clock.svg';
import iconHouse from '../../../../../assets/images/icon_house.svg';

let urlCreator = window.URL || window.webkitURL;

interface GalleryTileProps {
  observation: Observation;
  active: boolean;
  onClick: (observation: Observation) => void;
}
export const GalleryTile = ({ observation, active, onClick }: GalleryTileProps) => {
  const { updateSafety } = useSafetyContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [imageUrl, setImageURL] = useState<string | null>();
  const [imgSize, setImgSize] = useState<number[]>([6080, 3040]);

  useEffect(() => {
    // create a new instance of axios to remove extra headers for aws
    let localAxios = axios.create();
    delete localAxios.defaults.headers.common.Authorization;
    let url = observation.image.processed_image?.base_image_url;
    if (observation?.is_manual) {
      // @ts-ignore
      url = observation?.image.user_image.image_url;
    }

    // download the image without global headers
    localAxios
      .get(url, { responseType: 'blob' })
      .then(r => {
        let imgUrl = urlCreator.createObjectURL(r.data);
        setImageURL(imgUrl);
        observation.localImage = imgUrl;
        const img = new Image();
        img.onload = function () {
          //@ts-ignore
          setImgSize([this.width, this.height]);
        };
        img.src = imgUrl;
        if (active) updateSafety({ activeObservation: observation });
      })
      .then(() => setLoading(false))
      .catch(e => console.log(e));
  }, [observation]);

  return (
    <TileContainer
      id={`tile-${observation.id}`}
      onClick={() => onClick(observation)}
      className={active ? 'active' : ''}>
      <ImagePreview
        style={{
          backgroundImage: `url(${imageUrl})`,
          backgroundPositionX: `${
            (getCxCy(observation.labels[0].coordinates.split(','))[0] / imgSize[0]) * 100
          }%`,
          backgroundPositionY: `${
            (getCxCy(observation.labels[0].coordinates.split(','))[1] / imgSize[1]) * 100
          }%`,
          backgroundColor: '#ddd',
        }}>
        {loading && <LoadingIndicator fb_center />}
      </ImagePreview>
      <Flex flexDirection={'column'} justifyCenter style={{ padding: '10px' }}>
        <SafetyTag severity={observation.unsafe ? observation.severity : 'SAFE'}>
          <p>{observation.unsafe ? observation.severity.split('_')[1] : 'SAFE'}</p>
        </SafetyTag>
        <Flex style={{ margin: '5px 0' }}>
          <h1 style={{ fontSize: '1em', marginLeft: '3px' }}>{observation?.type.name}</h1>
        </Flex>
        <Flex>
          <InfoFloat>
            <Icon icon={iconHouse} size={10} style={{ marginRight: '3px' }} />
            {observation.is_manual
              ? observation.project_floor.name
              : observation?.image.processed_image.viewpoints_image.project_floor}
          </InfoFloat>
          <InfoFloat>
            <Icon icon={iconClock} size={10} style={{ marginRight: '3px' }} />
            <Moment
              date={observation?.image.processed_image?.viewpoints_image.taken_on}
              format="MMM Do, YYYY"
            />
          </InfoFloat>
        </Flex>
      </Flex>
    </TileContainer>
  );
};

function hexToRgbA(hex: string, opacity = 1) {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // @ts-ignore
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
  }
  throw new Error('Bad Hex');
}

function getCxCy(coordinates: string[]) {
  let numbers = coordinates.map(Number);
  return [numbers[0] + numbers[2] / 2, numbers[1] + numbers[3] / 3];
}

const TileContainer = styled.div`
  border-radius: 2px;
  min-height: 125px;
  width: 100%;
  cursor: pointer;
  display: flex;
  background: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  &.active {
    opacity: 50%;
  }

  :hover {
    opacity: 50%;
  }

  :first-child {
    margin-top: 0;
  }
`;
TileContainer.displayName = 'TileContainer';

const ImagePreview = styled.div`
  width: 115px;
  height: 100%;
  flex-shrink: 0;
  background-size: 3040px 1520px !important;
`;
ImagePreview.displayName = 'ImagePreview';

const InfoFloat = styled.div`
  border-radius: 4px;
  margin: 5px 5px;
  font-size: 0.7em;
  align-items: center;
  display: flex;
`;
InfoFloat.displayName = 'InfoFloat';

const SafetyTag = styled.div<any>`
  background: ${(props: any) => hexToRgbA(SEVERITY_COLOR_MAP[props.severity], 0.3)};
  padding: 4px 8px;
  height: auto;
  width: min-content;
  font-size: 0.6em;
  border-radius: 10px;

  p {
    color: ${(props: any) => SEVERITY_COLOR_MAP[props.severity]};
    opacity: 1;
  }
`;
SafetyTag.displayName = 'SafetyTag';
