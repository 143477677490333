import axios from 'axios';
import { Project } from './types';

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

export const listProjects = async (userId: string, status?: string): Promise<Project[]> => {
  const params = {
    status: status,
  }

  const res = await axios.get(
    `${BASE_URL}/user/${userId}/projects`,
    {
      params: params,
    }
  );
  const response = await res.data;

  return response.data;
}

export const listProjectSubcontractors = (projectId: string) => {
  return axios.get(`${BASE_URL}/project/${projectId}/subcontractors`).then(r => r.data);
};

export interface ProjectSubcontractor {
  project: number;
  subcontractor: Company;
}

interface Company {
  id: number;
  public_id: string;
  name: string;
  website_url?: string;
  logo_s3_key?: string;
  is_verified: boolean;
}
