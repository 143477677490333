import styled from 'styled-components';

export const ObservationContainer = styled.div`
  background: #fff;
  border-radius: 2px;
  z-index: 100;
  position: relative;
  width: 350px;
  height: 100%;
  max-height: 500px;
  position: fixed;
  padding: 7px;
  display: flex;
  flex-direction: column;
`;

export const ObservationForm = styled.form`
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 0.8em;
  }
`;

export const DetailsButton = styled.div`
  margin: 5px 0;
  border-radius: 2px;
  border: 1px solid #073c7a;
  color: #073c7a;
  cursor: pointer;
  padding: 10px 10px;
  text-align: center;
  width:100%;
    &.disabled {
    cursor: default;
    color: #ccc;
    border: 1px solid #ccc;
  }
`;
