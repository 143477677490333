import Flex from '@react-css/flex';
import styled from 'styled-components';

interface SegmentedControlProps {
  values: string[];
  value: string;
  onClick: (val: string) => void;
  style?: any;
}
export const SegmentedControl = ({ values, value, onClick, style }: SegmentedControlProps) => {
  return (
    <Flex
      flexDirection="row"
      style={{ margin: '1.2em 0', fontSize: '0.8em', ...style }}
      justifyCenter>
      {values.map((val: string) => (
        <ModeButton
          key={val}
          onClick={() => onClick(val)}
          style={{
            background: value === val ? '#073c7a' : '#F8F8F8',
            color: value === val ? '#fff' : '#A7AABD'
          }}>
          {val}
        </ModeButton>
      ))}
    </Flex>
  );
};

const ModeButton = styled.div`
  height: 100%;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  width: 100%;
  padding: 7px 10px;
`;
