import { Link, useRouteMatch } from 'react-router-dom';
import { useQuery } from 'react-query';
import Flex from '@react-css/flex';
import { Text } from '../../../common/Text';
import { Icon } from '../../../common/Icon';
import imgMapMarker from '../../../../assets/images/icon_map_marker.svg';
import React from 'react';
import { LoadingIndicator } from '../../../common/LoadingIndicator';
import { useAdminZoneContext } from '../../../../contexts/adminZoneContext';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_NEXTERA_API;


export const BuildingPageHeaderOutlet = () => {
  const { adminPrefix } = useAdminZoneContext();

  const buildingMatch = useRouteMatch<{ projectId: string; floorId: string; pointId: string }>();

  const { projectId, floorId, pointId } = buildingMatch.params;

  let url = `${API_BASE}/project/${projectId}`;
  if (floorId) url = `${API_BASE}/project/${projectId}/floor/${floorId}`;

  const fetchProjects = async () => {
    console.log('URL in header', url);
    return axios.get(url)
      .then(res => res.data)
      .then(response => response.data)
      .catch(err => {
        console.log('errr==>>', err);
      });
  };

  const buildingQuery = useQuery(['buildingHeader', projectId, floorId, pointId], fetchProjects);

  const buildingDesc = buildingQuery.data;

  if (buildingQuery.isLoading) return <LoadingIndicator fb={true} />;

  if (buildingDesc) {
    return (
      <Flex flexDirection="column">
        <Link to={`${adminPrefix}/project/${projectId}`} style={{ color: '#000' }}>
          <Text size={16} marginBottom={4} align={'center'}>
            {buildingDesc.name}
          </Text>
        </Link>
        <Flex alignItems={'center'} justifyContent={'center'}>
          <Icon icon={imgMapMarker} size={16} style={{ marginRight: '6px' }} />
          <Text size={14} color={'#7E858E'} marginTop={2}>
            {buildingDesc.location.city}, {buildingDesc.location.state_code}
          </Text>
        </Flex>
      </Flex>
    );
  }
  return <></>;
};
