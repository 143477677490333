import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IChatNotification } from './types';
import { Mail } from './styles';
import imgChat from '../../../assets/images/icon_chat_outline.svg';
import imgChatUnread from '../../../assets/images/icon_chat_unread.svg';
import { ChatNotifications } from './ChatNotifications/ChatNotifications';
import { useUserContext } from '../../../contexts/userContext';
import { getChatNotifications } from '../../../api/notificationFetches';

export const NotificationHub = () => {
  const location = useLocation();
  const { state: userState } = useUserContext();
  const [menuOpen, setMenuOpen] = useState(false);
  const [chatNotifications, setChatNotifications] = useState<any>([]);
  const [hasUnread, setHasUnread] = useState<boolean>(false);

  const findUnreads = (notifications: IChatNotification[]) => {
    if (notifications?.length) {
      let unreads = notifications.filter((item: IChatNotification) => item.has_unread);
      setHasUnread(unreads.length > 0);
    }
  };

  const loadMore = (pageNumber: number) => {
    getChatNotifications(userState.public_id, pageNumber).then(data => {
      setChatNotifications((prevState: any) => {
        return { ...data, data: prevState.data.concat(data.data) };
      });
    });
  };

  useEffect(() => {
    if (userState.public_id) {
      getChatNotifications(userState.public_id).then(data => {
        if (data) {
          setChatNotifications(data);
          findUnreads(data.data);
        }
      });
    }
  }, [location]);

  return (
    <>
      <Mail src={hasUnread ? imgChatUnread : imgChat} onClick={() => setMenuOpen(!menuOpen)} />
      <ChatNotifications
        display={menuOpen}
        notifications={chatNotifications}
        menuToggle={setMenuOpen}
        loadMore={loadMore}
      />
      {/*<Ring />*/}
    </>
  );
};
