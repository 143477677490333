import React from "react";
import styled from "styled-components";

export const ObservationTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const ScrollableObservationTableContainer = styled(ObservationTableContainer)`
  max-height: 215px;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 1em;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 2px rgba(0, 0, 0, 0);
    margin: 10px 0;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 2px;
    margin: 10px 0;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
`;

export const ObservationTableRow = styled.tr<{active?: boolean}>`
  background-color: ${props => props.active ? '#F8FAFC' : 'white'};
  cursor: pointer;
`;

interface ObservationTableDataProps extends React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement> {}

export const ObservationTableData = ({
  align,
  children
}: React.PropsWithChildren<ObservationTableDataProps>) => {
  return (
    <td
      align={align}
      style={{
        padding: '10px'
      }}
    >
      {children}
    </td>
  );
}

export const ObservationType = styled.div`
  font-family: Frutiger LT;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  width: fit-content;
  min-width: 150px;
`;

export const ProjectFloor = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 125px;
`;

export const ProjectFloorCode = styled.div`
  font-family: Frutiger LT;
  font-size: 12px;
  font-weight: 400;
  margin-top: 1px;
`;

export const ProjectFloorName = styled.div`
  display: flex;
  gap: 10px;
`;

export const Status = styled.div<{statusColorHex: string}>`
  background-color: ${props => props.statusColorHex}1A;
  color: ${props => props.statusColorHex};
  width: 70px;
  border-radius: 16px;
  font-size: 12px;
  padding: 5px 4px;
  text-transform: uppercase;
  text-align: center;
`;