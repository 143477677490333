import { Icon } from '../../../common/Icon';
import icon360Light from '../../../../assets/images/icon_360_light.svg';
import icon360Dark from '../../../../assets/images/icon_360_dark.svg';
import iconCompassLight from '../../../../assets/images/icon_compass_light.svg';
import iconCompassDark from '../../../../assets/images/icon_compass_dark.svg';
import mixpanel from 'mixpanel-browser';
import { useCallback, useMemo } from 'react';

interface SplitScreenGutterProps {
  sync: boolean;
  compass: boolean;
  showCompass: boolean;
  onSyncChange(sync: boolean): void;
  onCompassChange(compass: boolean): void;
}

export const SplitScreenGutter = ({
  sync,
  compass,
  showCompass,
  onSyncChange,
  onCompassChange,
}: SplitScreenGutterProps) => {
  const handleSyncClick = useCallback(() => {
    mixpanel.track('Toggle Split Screen Sync');
    onSyncChange(!sync);
  }, [onSyncChange, sync]);

  const handleCompassClick = useCallback(() => {
    if (sync) {
      mixpanel.track('Toggle Split Screen Compass');
      onCompassChange(!compass);
    }
  }, [onCompassChange, sync, compass]);

  const compassBackground = useMemo(() => {
    if (sync) {
      return compass ? '#073c7a' : '#fff';
    } else {
      return '#92989f';
    }
  }, [sync, compass]);

  const compassIcon = useMemo(() => {
    if (sync) {
      return compass ? iconCompassLight : iconCompassDark;
    } else {
      return iconCompassLight;
    }
  }, [sync, compass]);

  return (
    <div
      style={{
        width: '5px',
        backgroundColor: '#f8f8f8',
        display: 'flex',
        gap: '5px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px',
          position: 'relative',
          backgroundColor: sync ? '#073c7a' : '#fff',
          zIndex: 100,
          borderRadius: '5px',
          cursor: 'pointer',
          boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.35)',
        }}
        onClick={handleSyncClick}>
        <Icon icon={sync ? icon360Light : icon360Dark} size={20} />
      </div>
      {showCompass && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            position: 'relative',
            backgroundColor: compassBackground,
            zIndex: 100,
            borderRadius: '5px',
            cursor: 'pointer',
            color: 'red',
            boxShadow: '0px 0px 10px 0px rgba(255, 255, 255, 0.35)',
          }}
          onClick={handleCompassClick}>
          <Icon icon={compassIcon} size={20} />
        </div>
      )}
    </div>
  );
};
