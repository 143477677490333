import styled from 'styled-components';
import msLogo from '../../../assets/images/logos/ms-logo.webp';

export const Content = styled.div`
  position: absolute;
  right: 60px;
  left: 60px;
  top: 68px;
  bottom: 0;
  margin-top: 1em;
`;

export const LoginCard = styled.div`
  width: 350px;
  border-radius: 2px;
`;

const buttonBoxShadow = '0 1px 2px 0 rgba(0,0,0,0.2)';

export const LoginButton = styled.input`
  border: none;
  background-color: #073c7a;
  color: white;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 9px 20px;
  margin-bottom: 1em;
  margin: auto;
  width: 100%;
  box-shadow: ${buttonBoxShadow};
`;

export const LoginCardBody = styled.div`
  padding: 20px;
`;

export const LoginLabel = styled.label`
  font-size: 0.9em;
`;

export const LoginError = styled.div`
  color: #ee2d23;
  margin-bottom: 10px;
  font-size: 0.9em;
`;

interface LoginWithMicrosoftButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
}

export const LoginWithMicrosoftButton = (props: LoginWithMicrosoftButtonProps) => {
  return (
    <LoginWithMicrosoftButtonContainer
      {...props}
    >
      <MicrosoftLogoSvg/>
      <span style={{marginTop: '1px'}}>Login with Microsoft</span>
      <div style={{ width: '20px' }} />
    </LoginWithMicrosoftButtonContainer>
  )
}

const LoginWithMicrosoftButtonContainer = styled.button`
  border: none;
  background-color: white;
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  padding: 6px 20px;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${buttonBoxShadow};
`;

const MicrosoftLogoSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 28 27"
      width="20px"
      height="20px"
    >
      <path
        fill="#E95228"
        d="M.6 0h12.743v12.743H.6V0Z"
      />
      <path
        fill="#7EBA28"
        d="M14.657 0H27.4v12.743H14.657V0Z"/>
      <path
        fill="#339FDA"
        d="M.6 14.081h12.743v12.72H.6V14.08Z"/>
      <path
        fill="#FBB811"
        d="M14.657 14.081H27.4v12.72H14.657V14.08Z"
      />
    </svg>
  )
}

export const ThirdPartySeparator = styled.div`
  margin: 15px 0px;
  text-align: center;
  font-size: 13px;
  color: #0000008A;
`
