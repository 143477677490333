import { useUserContext } from '../../../contexts/userContext';
import { Redirect, Route } from 'react-router-dom';
import { PermissionWrapper } from '../PermissionWrapper/PermissionWrapper';
import React from 'react';
import { LoadingIndicator } from '../LoadingIndicator';

export function PermissionRoute({ permission, component: Component, ...rest }: any) {
  const { state: authState } = useUserContext();
  if (authState.loading) return <LoadingIndicator />;

  return (
    <Route
      {...rest}
      render={props =>
        authState.isAuthenticated ? (
          <PermissionWrapper permission={permission}>
            <Component {...props} {...rest}/>
          </PermissionWrapper>
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
