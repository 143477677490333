import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Icon } from "../Icon";

interface Mode {
  name: string;
  path: string;
  icon: string;
  iconActive?: string;
}

interface IconViewModeSelectorProps {
  modes: Mode[];
  iconSize?: number;
}

export const IconViewModeSelector = ({
  modes,
  iconSize=18,
}: IconViewModeSelectorProps) => {
  const history = useHistory();

  const onClickViewButton = (mode: Mode) => {
    history.replace(mode.path);
  }

  return (
    <>
      {modes.map(mode => {
        const active = history.location.pathname === mode.path;
        const icon = (active && mode.iconActive) ? mode.iconActive : mode.icon;

        return (
          <ViewButton
            key={mode.name}
            className={active ? 'active' : ''}
            onClick={() => onClickViewButton(mode)}
          >
            <Icon icon={icon} size={iconSize} />
          </ViewButton>
        )
      })}
    </>
  );
};

const ViewButton = styled.div`
  border: 1px solid #94a3b8;
  border-radius: 4px;
  padding: 6px;
  cursor: pointer;

  &.active {
    border: 1px solid #073c7a;
    background: #073c7a;
  }
`;
