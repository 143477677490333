import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Icon } from '../../../common/Icon';

import iconMapMarker from '../../../../assets/images/icon_map_marker.svg';
import iconVerticalDotMenu from '../../../../assets/images/icon_vertical_dot_menu.svg';
import iconProcoreGray from '../../../../assets/images/icon_procore_gray.svg';
import iconProcoreWhite from '../../../../assets/images/icon_procore_white.svg';
import Moment from 'react-moment';
import { useAdminZoneContext } from '../../../../contexts/adminZoneContext';
import { ContextMenuContainer, MenuItem } from '../../image_viewer/components/ViewerPane/components/ContextMenu/styles';
import { useOnClickOutside } from '../../../../hooks/useOnClickOutside';

export const CardBase = styled.div`
  width: 350px;
  height: 280px;
  cursor: pointer; // remove with BetaLink
  margin: 18px;

  background: #ffffff;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.1);
`;

export const CardImage = styled.img`
  width: 350px;
  height: 172px;
`;

export const CardTitle = styled.div`
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardTitleInner = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: #05051d;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardDesc = styled.div`
  margin-top: 18px;
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: space-between;
  color: #7e858e;
`;

export const CardDescItem = styled.div`
  font-size: 14px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;

const CardStatus = styled.div<{status: string}>`
  background-color: ${props => props.status === 'hold' ? '#f7cd6540' : props.status === 'done' ? '#34d39940' : '#073c7a40'};
  color: ${props => props.status === 'hold' ? '#F7CD65' : props.status === 'done' ? '#34D399' : '#073C7A'};
  font-size: 14px;
  padding: 5px 7px;
  border-radius: 5px;
`

/**
 * Temporary component to wrap cards. Allows for only specified project to be clicked.
 *
 * @param projectId
 * @param children
 */

const dummyProjects = ['EV7XcemsKA', 'Abl8diemnC', 'VAmecLab4e', 'brt79Zwxyy'];
const BetaProofLink = ({ projectId, children }: any) => {
  const { adminPrefix } = useAdminZoneContext();

  if (!dummyProjects.includes(projectId)) {
    return <Link to={`${adminPrefix}/project/${projectId}`}>{children}</Link>;
  }
  return children;
};

export const Card = (props: {
  public_id: string;
  thumbnail_url: string;
  name: string;
  last_modified_on?: any;
  location?: any;
  image_id?: string;
  image_url?: string;
  latest_data_received_on?: any;
  status?: any;
  userHasMenuAccess: boolean;
  onClickMenuItem: (integration: Integrations) => void;
}) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const onClickMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setMenuOpen(true);
  }

  return (
    <BetaProofLink projectId={props.public_id}>
      <CardBase>
        <CardImage src={props.image_url} />
        <CardTitle
          style={{
            position: 'relative'
          }}
        >
          <CardTitleInner>{props.name}</CardTitleInner>
          <div
            style={{display: 'flex', alignItems: 'center'}}
          >
            <CardStatus
              status={props.status}
            >
              {props.status.capitalize()}
            </CardStatus>
            {props.userHasMenuAccess &&
              <Icon
                icon={iconVerticalDotMenu}
                size={20}
                onClick={onClickMenu}
                style={{
                  marginRight: '-8px',
                }}
              />
            }
          </div>
          {(props.userHasMenuAccess && menuOpen) &&
            <IntegrationsMenu
              onCloseMenu={() => setMenuOpen(false)}
              onClickMenuItem={props.onClickMenuItem}
            />
          }
        </CardTitle>
        <CardDesc>
          <CardDescItem>
            <Icon icon={iconMapMarker} size={16} style={{ marginRight: '7px' }} />
            <div>{props.location.city + ', ' + props.location.state_code}</div>
          </CardDescItem>
          <CardDescItem>
            Last Update:&nbsp;
            <Moment date={props.latest_data_received_on} format="MM/DD/YY" />
          </CardDescItem>
        </CardDesc>
      </CardBase>
    </BetaProofLink>
  );
}

export enum Integrations {
  Procore
}

interface IIntegrationsMenuProps {
  onCloseMenu: () => void;
  onClickMenuItem: (integration: Integrations) => void;
}

const IntegrationsMenu = ({
  onCloseMenu,
  onClickMenuItem,
}: IIntegrationsMenuProps) => {
  const menuRef = createRef<HTMLDivElement>();

  useOnClickOutside(menuRef, onCloseMenu);

  const handleMenuItemClick = (e: React.MouseEvent<HTMLLIElement>, integration: Integrations) => {
    e.preventDefault();
    e.stopPropagation();
    
    onClickMenuItem(integration);
    onCloseMenu();
  }

  return (
    <ContextMenuContainer
      ref={menuRef}
      width={230}
      style={{
        position: 'absolute',
        left: '320px',
        background: 'rgba(255, 255, 255)',
      }}
    >
      <ul>
        <MenuItem
          onClick={e => handleMenuItemClick(e, Integrations.Procore)}
          icon={iconProcoreGray}
          hoverIcon={iconProcoreWhite}
          style={{
            padding: '5px 10px'
          }}
        >
          Link Project With Procore
        </MenuItem>
      </ul>
    </ContextMenuContainer>
  )
}
