import { useTagContext } from '../../../../contexts/tagContext';
import { RFIFormContainer, RFIFormDivider, RFIFormLabel, RFIFormRow, RFIFormTitle } from './styles';
import { useFetchRFIQuery } from '../hooks/procoreQueries';
import styled from 'styled-components';
import { LoadingIndicator } from '../../LoadingIndicator';
import { createRef, useEffect, useMemo } from 'react';
import { Icon } from '../../Icon';
import iconLaunch from '../../../../assets/images/icon_launch.svg';
import { updateRFITag } from '../../../../api/procore';
import { useUserContext } from '../../../../contexts/userContext';

interface ICreatedRFIProps {
  hydrate: (tagsToUpdate?: number[]) => Promise<any>;
}

export const CreatedRFI = ({
  hydrate,
}: ICreatedRFIProps) => {
  const rfiFormContainerRef = createRef<HTMLDivElement>();
  const { state: userState } = useUserContext();
  const { state: tagState } = useTagContext();
  const currentTag = tagState.tags.current;
  
  const {data: rfi, isLoading: rfiLoading} = useFetchRFIQuery(currentTag.rfi?.rfi_id);

  useEffect(() => {
    if (rfiFormContainerRef && rfiFormContainerRef.current) {
      rfiFormContainerRef.current.scrollTop = 0;
    }
  }, [rfiFormContainerRef]);

  useEffect(() => {
    if (rfi && rfi.status === 'closed') {
      const rfiTagMarkedAsClosed = currentTag.rfi && currentTag.rfi.status === 'closed';

      if (!rfiTagMarkedAsClosed) {
        const updateRFIData = {
          status: 'closed',
          last_edited_by: userState.public_id,
        };

        updateRFITag(currentTag.rfi.rfi_id, updateRFIData)
          .then(updatedRFIs => {
            const updatedRFITagIds = updatedRFIs.map(tag => tag.tag);   
            console.log(updatedRFITagIds);
            hydrate(updatedRFITagIds);
          });
      }
    }
  }, [rfi, currentTag, userState.public_id, hydrate]);

  const distributionNames = useMemo(() => {
    if (rfi && rfi.distribution_list) {
      return rfi.distribution_list.map(user => user.name);
    }

    return [];
  }, [rfi]);

  const assigneeNames = useMemo(() => {
    if (rfi) {
      return rfi.assignees.map(assignee => assignee.name)
    }

    return [];
  }, [rfi]);

  const openRFIInProcore = () => {
    if (rfi) {
      window.open(rfi.link, "_blank");
    }
  }

  return (
    <RFIFormContainer
      ref={rfiFormContainerRef}
      style={{gap: '20px'}}
    >
      {(!rfi || rfiLoading) &&
        <LoadingIndicator/>
      }
      {(rfi && !rfiLoading) &&
        <>
          <HeaderContainer>
            <EmptyIcon/>

            <RFIFormTitle>Procore RFI - {`#${rfi.number}: ${rfi.subject}`}</RFIFormTitle>

            <Icon
              icon={iconLaunch}
              size={16}
              onClick={openRFIInProcore}
              style={{ cursor: 'pointer' }}
            />
          </HeaderContainer>

          <RFIFormDivider/>

          <RFIFormRow>
            <RFIDetail
              label="Subject"
              value={rfi.subject}
            />
          </RFIFormRow>
          <RFIFormRow>
            <RFIDetail
              label="RFI Manager"
              value={rfi.rfi_manager.name}
            />
            <RFIDetail
              label="Distribution Members"
              value={distributionNames}
            />
          </RFIFormRow>
          <RFIFormRow>
            <RFIDetail
              label="Received From"
              value={rfi.received_from?.name}
            />
            <RFIDetail
              label="Responsible Contractor"
              value={rfi.responsible_contractor?.name}
            />
          </RFIFormRow>
          <RFIFormRow>
            <RFIDetail
              label="Drawing Number"
              value={rfi.drawing_number}
            />
            <RFIDetail
              label="Assigned To"
              value={assigneeNames}
            />
          </RFIFormRow>
          <RFIFormRow>
            <RFIDetail
              label="Spec Section"
              value={rfi.specification_section?.description}
            />
            <RFIDetail
              label="Location"
              value={rfi.location?.node_name}
            />
          </RFIFormRow>
          {rfi.questions.map((question, i) => {
            return (
              <RFIFormRow
                key={question.id}
              >
                <RFIDetail
                  label={`Question ${i+1}:`}
                  value={question.plain_text_body}
                />
              </RFIFormRow>
            )
          })}
        </>
      }
    </RFIFormContainer>
  )
}

const RFIProperty = styled.div`
  font-weight: 400;
  font-size: 12px;
  width: 168px;
`;

interface IRFIDetailProps {
  label: string;
  value: string | string[] | number | number[] | null | undefined;
}

const RFIDetail = ({
  label,
  value,
}: IRFIDetailProps) => {
  const valueIsArray = Array.isArray(value);

  return (
    <div>
      <RFIFormLabel bold>
        {label}
      </RFIFormLabel>
      {valueIsArray &&
        <>
          {(value as string[] | number[]).map((element, i) => {
            return (
              <RFIProperty
                key={i}
              >
                {element}
              </RFIProperty>
            )
          })}
        </>
      }
      {!valueIsArray &&
        <RFIProperty>
          {value}
        </RFIProperty>
      }
    </div>
  )
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const EmptyIcon = styled.div`
  flex-basis: 16px;
  flex-grow: 0;
  flex-shrink: 0;
`;