import { Icon } from '../../../../../common/Icon';
import iconCalendar from '../../../../../../assets/images/icon_calendar.svg';
import iconBackDark from '../../../../../../assets/images/icon_back_dark.svg';
import iconForward from '../../../../../../assets/images/icon_forward.svg';
import React, { useEffect, useState } from 'react';
import { fetchImage, fetchPointImage } from '../../../../../../api/buildingFetches';
import { Facebook } from 'react-spinners-css';
import { Flex } from '@react-css/flex';
import { useNavigation } from '../../../../../../hooks/useNavigation';
import { useImageViewerContext } from '../../../imageViewerContext';
import mixpanel from 'mixpanel-browser';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';

let urlCreator = window.URL || window.webkitURL;

interface IFullscreenTimeNavigationProps {
  point: any;
  type: string;
}
export const FullScreenTimeNaviation = ({ point, type }: IFullscreenTimeNavigationProps) => {
  const { state: buildingState } = useBuildingContext();
  const { state: ImageViewerState, updatePane2, updateMaster } = useImageViewerContext();
  const updateMethod = type === 'master' ? updateMaster : updatePane2;
  const { navigateToPointFullscreen } = useNavigation();
  const [previousImage, setPreviousImage] = useState(null);
  const [nextImage, setNextImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);
  const [localLoad, setLocalLoad] = useState(false);
  const [sorted, setSorted] = useState<any>([]);

  const getDateString = () => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const registeredDate = new Date(ImageViewerState[type].data?.taken_on);
    const day = registeredDate.getDate();
    const month = monthNames[registeredDate.getMonth()];
    const year = registeredDate.getFullYear();
    return `${month} ${day}, ${year}`;
  };

  const getSetCurrentImageData = async (image: any) => {
    let oldAngle = ImageViewerState[type].data.angle;
    let newAngle = image.angle;
    const viewpointsImage = await fetchPointImage(
      buildingState.projectId,
      buildingState.floorData.floor_code,
      image.id
    );
    const imageBlob = await fetchImage(viewpointsImage.processed_image_url);
    const currentImageData = {
      image: urlCreator.createObjectURL(imageBlob),
      data: image,
      angleDelta: newAngle - oldAngle,
      observationDate: image.taken_on,
      isMostRecent: true,
    };
    mixpanel.track('Minimap Time Navigation');
    navigateToPointFullscreen(image.sub_point_id, { date: image.taken_on });
    updateMethod(currentImageData);
    setLocalLoad(false);
    // urlCreator.revokeObjectURL(props.currentImageData.image);
  };

  const onClickNext = () => {
    setLocalLoad(true);
    setCurrentIndex(nextImage);
    // @ts-ignore
    getSetCurrentImageData(sorted[nextImage]);
  };

  const onClickPrev = () => {
    setLocalLoad(true);
    setCurrentIndex(previousImage);
    // @ts-ignore
    getSetCurrentImageData(sorted[previousImage]);
  };

  const sortImages = (images: any) => {
    return [...images].sort((a: any, b: any) =>
      new Date(a.taken_on).getTime() > new Date(b.taken_on).getTime() ? 1 : -1
    );
  };

  useEffect(() => {
    if (currentIndex !== null) {
      if (currentIndex! > 0) {
        // @ts-ignore
        setPreviousImage(currentIndex - 1);
      } else {
        setPreviousImage(null);
      }
      if (currentIndex! < sorted.length - 1) {
        // @ts-ignore
        setNextImage(currentIndex + 1);
      } else {
        setNextImage(null);
      }
    }
    // eslint-disable-next-line
  }, [currentIndex, sorted]);

  useEffect(() => {
    let sorts = sortImages(point.images);
    setSorted(sorts);
    if (sorts.length < 2) {
      setNextImage(null);
      setPreviousImage(null);
    }
    for (let p in sorts) {
      if (sorts[p]?.id === ImageViewerState[type].data?.id) {
        // @ts-ignore
        setCurrentIndex(parseInt(p));
      }
    }
  }, [ImageViewerState[type], point]);

  return (
    <Flex flexDirection="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
      {localLoad && <Facebook color="#073C7A" style={{ margin: '0 5px' }} size={14} />}
      <Flex style={{ width: '25px', alignItems: 'center', height: '100%' }}>
        {previousImage !== null && (
          <Icon
            icon={iconBackDark}
            size={14}
            style={{ marginRight: '9px', cursor: 'pointer', margin: 'auto' }}
            onClick={onClickPrev}
          />
        )}
      </Flex>
      <Icon icon={iconCalendar} size={14} style={{ marginRight: '9px' }} />
      <span className="minimap-date">{getDateString()}</span>
      <Flex style={{ width: '25px', alignItems: 'center' }}>
        {nextImage !== null && (
          <Icon
            icon={iconForward}
            size={14}
            style={{ marginLeft: '9px', cursor: 'pointer', margin: 'auto' }}
            onClick={onClickNext}
          />
        )}
      </Flex>
    </Flex>
  );
};
