import styled from "styled-components";

export const ProgressDateSelectorTitle = styled.div`
  padding: 12px 24px;
  border-radius: 4px 4px 0 0;
  background: #073c7a;
  margin: 0 -12px;
  margin-bottom: 12px;
  text-align: center;

  h1 {
    font-size: 1em;
    text-transform: uppercase;
    color: #fff;
  }
`;

export const ProgressDateSelectorContainer = styled.div`
  position: absolute;
  top: 48px;
  left: 0;
  background: #fff;
  border-radius: 4px;
  padding: 0 12px;
  padding-bottom: 12px;
  width: 300px;
`;