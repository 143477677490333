
import React, { createContext, useCallback, useContext, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const NotificationContext = createContext([] as any[]);

type Level = 'success' | 'warning' | 'error';

export interface INotification {
  uid: string;
  message: string;
  level: Level;
  sent: boolean;
}

export const NotificationsProvider = ({ children }: any) => {
  const [notifications, setNotifications] = useState<INotification[]>([]);

  return (
    <NotificationContext.Provider value={[notifications, setNotifications]}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const [notifications, setNotifications] = useContext(NotificationContext);

  const addNotification = useCallback((message: string, level: Level) => {
    const newNotification = {
      uid: uuidv4(),
      message,
      level,
      sent: false,
    }

    setNotifications((prevNotifications: INotification[]) => {
      return [...prevNotifications, newNotification];
    })
  }, [setNotifications]);

  return {
    notifications: notifications as INotification[],
    setNotifications,
    addNotification,
  }
}