import { ForgeViewer } from '../../../../../../third_party/ForgeViewer';
import * as THREE from 'three';
import { useBuildingContext } from '../../../../../../../contexts/buildingContext';
import { useImageViewerContext } from '../../../../imageViewerContext';
import { useEffect, useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { LoadingIndicator } from '../../../../../../common/LoadingIndicator';
import { ViewerPosition } from '../../../../types';

const CAMERA_HEIGHT = 4;

interface Transformation {
  linear_transformation: [number, number][];
  translation: [number, number];
  scaling: [number, number];
  rotation: number;
}

interface FloorTranslation {
  height: number;
  primary_to_secondary: string;
  secondary_to_primary: string;
}

interface XRayViewProps {
  viewerPosition: ViewerPosition;
  viewerPositionDelta?: ViewerPosition;
  followRealtime?: boolean;
  onDragging?: (isActive: boolean) => void;
  onUpdate?: (newValues: ViewerPosition) => void;
}

export const XRayView = ({
  viewerPosition,
  viewerPositionDelta,
  followRealtime = false,
  onDragging,
  onUpdate,
}: XRayViewProps) => {
  const { state: buildingState } = useBuildingContext();
  const { state: imageViewerContext } = useImageViewerContext();
  const [position, setPosition] = useState<THREE.Vector3>();
  const [loading, setLoading] = useState<boolean>(true);
  const [rotation, setRotation] = useState(0);

  const transformPoint = (x: number, y: number, transformations: Transformation): THREE.Vector2 => {
    const [[a, b], [c, d]] = transformations.linear_transformation;
    const [tx, ty] = transformations.translation;

    const transformedX = a * x + b * y + tx;
    const transformedY = c * x + d * y + ty;

    return new THREE.Vector2(transformedX, transformedY);
  };

  const handleLoaded = (loaded: boolean) => {
    setLoading(!loaded);
  };

  useEffect(() => {
    const forgeJSON: Record<string, FloorTranslation> =
      buildingState.projectData.forge_translations_json;

    const floorTransformation = forgeJSON[buildingState.floorData.floor_code];
    const transformations: Transformation = JSON.parse(floorTransformation.primary_to_secondary);

    const x = buildingState.pointData.x;
    const y = buildingState.pointData.y;

    const transformedPosition = transformPoint(x, y, transformations);
    const calculatedPosition = new THREE.Vector3(
      transformedPosition.x,
      transformedPosition.y,
      floorTransformation.height + CAMERA_HEIGHT
    );

    setRotation(transformations.rotation || 0);
    setPosition(calculatedPosition);

    mixpanel.track('Show 3D Viewer');
  }, [buildingState]);

  if (!position) return <></>;

  return (
    <>
      {loading && (
        <div style={{ background: '#f8f8f8', height: '100%', width: '100%' }}>
          <LoadingIndicator />
        </div>
      )}
      <ForgeViewer
        position={position}
        viewerPosition={viewerPosition}
        viewerPositionDelta={viewerPositionDelta}
        followRealtime={followRealtime}
        fileUrn={buildingState.projectData.forge_model_urn}
        sync={imageViewerContext.sync}
        initialRotation={rotation}
        onDragging={onDragging}
        onUpdate={onUpdate}
        onLoaded={handleLoaded}
        projectId={buildingState.projectId}
      />
    </>
  );
};
