import { useCallback, useMemo } from 'react';
import { ProgressSummaryEntry } from './ProgressCharts';
import { monthAbbreviations } from '../../ProgressOverview/ProgressOverview';
import styled from 'styled-components';
import { useProgressContext } from '../../../../../contexts/progressContext';
import { ProjectTracker } from '../../../../../api/trackers';
import { JobType, ProjectJobType } from '../../../../../api/jobs';

//replace this with an actual solution to map out different paths a ceiling job can take
const CEILING_ITEM_TYPE_OVERRIDES: Record<string, number> = {
  ceiling_grid: 1,
  ceiling_tile: 1,
  ceiling_framing: 2,
  ceiling_drywall_hung: 2,
  ceiling_drywall_taped: 2,
  ceiling_drywall_painted: 2,
  ceiling_wood: 3,
  ceiling_wood_painted: 4,
  ceiling_concrete_painted: 5,
};

interface IProgressCompltionDatesProps {
  progress_by_date: ProgressSummaryEntry[];
}

export const ProgressCompletionDates = ({ progress_by_date }: IProgressCompltionDatesProps) => {
  const { state: progressState } = useProgressContext();
  const { activeTracker, trackers } = progressState;

  const activeProjectTracker = useMemo(() => {
    return trackers.find((tracker: ProjectTracker) => tracker.type.name === activeTracker);
  }, [activeTracker, trackers]);

  const activeTrackerJobTypes = useMemo(() => {
    let jobTypes: Record<string, JobType> = {};

    if (activeProjectTracker && activeProjectTracker.types) {
      activeProjectTracker.types.forEach((type: ProjectJobType) => {
        jobTypes[type.job_type.slug] = type.job_type;
      });
    }

    return jobTypes;
  }, [activeProjectTracker]);

  const isDateValid = useCallback((date: Date) => {
    return date instanceof Date && !isNaN(date.getTime());
  }, []);

  const calculateSlopeInterceptForPivot = useCallback(
    (points: [number, number][], pivot: [number, number]): [number, number] => {
      let [x_p, y_p] = pivot;

      let numerator = 0;
      let denominator = 0;

      points.forEach(([x, y]) => {
        numerator += (x - x_p) * (y - y_p);
        denominator += Math.pow(x - x_p, 2);
      });

      // Protect against division by zero
      let m = denominator !== 0 ? numerator / denominator : Infinity;
      let b = y_p - m * x_p;

      return [m, b];
    },
    []
  );

  const slugCompletionDates = useMemo(() => {
    const completionDates: { slug: string; completionDate: Date }[] = [];
    const seenCompletedUnitValues = new Map<string, Set<number>>();

    if (progress_by_date.length > 0) {
      const { date, ...individualEntries } = progress_by_date[0];
      const slugs = Object.keys(individualEntries);
      const jobTypes = slugs.map(slug => activeTrackerJobTypes[slug]);
      const itemTypeMinDates: Record<number, number> = {};

      for (let i = jobTypes.length - 1; i >= 0; i--) {
        const jobType = jobTypes[i];

        if (!jobType) {
          continue;
        }

        const slug = jobType.slug;
        const itemType =
          activeProjectTracker.type.name === 'Ceiling'
            ? CEILING_ITEM_TYPE_OVERRIDES[slug]
            : jobType.item_type;

        if (itemTypeMinDates[itemType] === undefined) {
          itemTypeMinDates[itemType] = Infinity;
        }

        seenCompletedUnitValues.set(slug, new Set<number>());
        const points: [number, number][] = [];

        progress_by_date.forEach(entry => {
          const completedUnits = entry[slug].completed_units;
          const completionPercentage = entry[slug].completed_units / entry[slug].total_units;
          const entryDate = new Date(entry.date);

          if (!seenCompletedUnitValues.get(slug)?.has(completedUnits)) {
            seenCompletedUnitValues.get(slug)?.add(completedUnits);
            points.push([entryDate.getTime(), completionPercentage]);
          }
        });

        const [m, b] = calculateSlopeInterceptForPivot(points.slice(-5), points[points.length - 1]);
        let completionDate = new Date((1 - b) / m);

        if (isDateValid(completionDate)) {
          itemTypeMinDates[itemType] = Math.min(
            itemTypeMinDates[itemType],
            completionDate.getTime()
          );
        }

        if (activeProjectTracker.type.is_sequential) {
          completionDate = new Date(itemTypeMinDates[itemType]);
        }

        completionDates.unshift({ slug: slug, completionDate: completionDate });
      }
    }

    return completionDates;
  }, [
    activeProjectTracker,
    activeTrackerJobTypes,
    calculateSlopeInterceptForPivot,
    isDateValid,
    progress_by_date,
  ]);

  const formatCompletionDate = useCallback(
    (slug: string, date: Date) => {
      const dateIsValid = isDateValid(date);
      const mostRecentProgress = progress_by_date[progress_by_date.length - 1][slug];
      const today = new Date();
      const oneYearFromNow = new Date(new Date(today).setFullYear(today.getFullYear() + 1));
      const completionPercentage =
        mostRecentProgress.total_units !== 0
          ? Math.round((mostRecentProgress.completed_units / mostRecentProgress.total_units) * 100)
          : 0;

      if (completionPercentage === 0) {
        return 'Not Started';
      } else if (completionPercentage >= 98) {
        return 'Completed';
      } else if (!dateIsValid) {
        return 'Insufficient Data';
      } else if (date <= today) {
        return 'Nearing Completion';
      } else if (date > oneYearFromNow) {
        if (completionPercentage <= 10) {
          return 'Insufficient Data';
        } else if (completionPercentage >= 90) {
          return 'Nearing Completion';
        } else {
          return '> 1 Year';
        }
      } else {
        return `${monthAbbreviations[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
      }
    },
    [isDateValid, progress_by_date]
  );

  return (
    <ProgressCompletionDatesContainer>
      <ProgressCompletionDatesColumn>
        {slugCompletionDates.map(({ slug }) => {
          const jobType = activeTrackerJobTypes[slug];
          const jobTypeName = jobType?.name;
          const color = jobType?.display_color;

          return (
            <SlugLabel
              key={slug}
              style={{
                color: color,
              }}>
              {jobTypeName}
            </SlugLabel>
          );
        })}
      </ProgressCompletionDatesColumn>
      <ProgressCompletionDatesColumn>
        {slugCompletionDates.map(({ slug, completionDate }) => {
          const jobType = activeTrackerJobTypes[slug];

          if (jobType) {
            return (
              <CompletionDate key={slug}>
                {formatCompletionDate(slug, completionDate)}
              </CompletionDate>
            );
          }

          return <></>;
        })}
      </ProgressCompletionDatesColumn>
    </ProgressCompletionDatesContainer>
  );
};

const SlugLabel = styled.div`
  font-size: 16px;
`;

const ProgressCompletionDatesContainer = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 20px;
`;

const ProgressCompletionDatesColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CompletionDate = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
