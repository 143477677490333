import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Text } from '../../../../../common/Text';
import { Icon } from '../../../../../common/Icon';
import iconBack from '../../../../../../assets/images/icon_back_carat.svg';
import iconForward from '../../../../../../assets/images/icon_forward_carat.svg';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { fetchImage, fetchPointImage } from '../../../../../../api/buildingFetches';
import { findClosestDate } from '../../../../building_page/hooks/buildingQueries';
import { Point } from '../../../../../../api/types';

let urlCreator = window.URL || window.webkitURL;

interface TimeScrollProps {
  observationDate: Date;
  onChangeObservationDate: (newDate: Date) => void;
}

export const TimeScroll = ({
  observationDate,
  onChangeObservationDate,
}: TimeScrollProps) => {
  const { state: buildingState, updateImage } = useBuildingContext();
  // const { state: progressState, updateProgress } = useProgressContext();
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [sorted, setSorted] = useState<any>([]);

  const getSetCurrentImageData = async (image: any) => {
    const viewpointsImage = await fetchPointImage(
      buildingState.projectId,
      buildingState.floorData.floor_code,
      image.id
    );
    const imageBlob = await fetchImage(viewpointsImage.processed_image_url);

    updateImage({
      image: urlCreator.createObjectURL(imageBlob),
      data: viewpointsImage,
    });
  };
  const sortImages = (images: any) => {
    return [...images].sort((a: any, b: any) =>
      new Date(a.taken_on).getTime() > new Date(b.taken_on).getTime() ? -1 : 1
    );
  };

  const onIncrementCurrentIndex = (delta: number) => {
    const newIndex = currentIndex + delta;

    if (newIndex >= 0 && newIndex < sorted.length) {
      setCurrentIndex(newIndex);
      onChangeObservationDate(new Date(sorted[newIndex].taken_on));
    }
  }

  useEffect(() => {
    if (buildingState.pointData?.images?.length && buildingState.imageData.data) {
      let sorts = sortImages(buildingState.pointData.images);
      setSorted(sorts);
      // setCurrentIndex(0);
      sorts.forEach((s, i) => {
        if (sorts[i].id === buildingState.imageData.data.id) {
          // @ts-ignore
          setCurrentIndex(parseInt(i));
        }
      });
    }
  }, [buildingState.pointData, buildingState.imageData.data]);

  useEffect(() => {
    if (sorted.length) {
      const sortedWithIndex = sorted.map((sortedImage: any, i: number) => ({...sortedImage, originalIndex: i}));
      let dateSorted = [...sortedWithIndex].sort((a: any, b: any) => findClosestDate(observationDate, a.taken_on, b.taken_on));

      const searchDateMax = new Date(observationDate);
      searchDateMax.setHours(23, 59, 59, 999);

      const beforeSearchDate = dateSorted.filter((point: Point) => new Date(point.taken_on) <= searchDateMax);

      if (beforeSearchDate.length > 0) {
        dateSorted = beforeSearchDate.sort( (a:any, b: any) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());
      }

      const newIndex = dateSorted[0].originalIndex;

      setCurrentIndex(newIndex);
      getSetCurrentImageData(sorted[newIndex]);
    }
  }, [observationDate]);

  if (!sorted.length || !buildingState.imageData.data) {
    return <></>;
  }

  return (
    <DateRangeSelectorBase>
      <ViewButton
        onClick={() => onIncrementCurrentIndex(1)}
        className={sorted.length === currentIndex + 1 ? 'inactive' : ''}>
        <Icon icon={iconBack} size={8} />
      </ViewButton>
      <DateRangeSelectorDisplay className="right">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Text size={14} color={'#073c7a'} bold={false} marginRight={5}>
            {format(new Date(buildingState.imageData.data.taken_on), 'MMM dd, yyyy')}
          </Text>
        </div>
      </DateRangeSelectorDisplay>
      <ViewButton
        onClick={() => onIncrementCurrentIndex(-1)}
        className={currentIndex <= 0 ? 'inactive' : ''}>
        <Icon icon={iconForward} size={8} />
      </ViewButton>
    </DateRangeSelectorBase>
  );
}

const ViewButton = styled.div`
  border: 1px solid #94a3b8;
  border-radius: 4px;
  padding: 6px 10px;
  margin: 4px;
  cursor: pointer;

  &.inactive {
    border: 1px solid #ccc;
    background: #ccc;
  }
`;

const DateRangeSelectorBase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 200;
  position: absolute;
  bottom: 20px;
  left: 10px;
  background: #f4f5f9;
  border-radius: 4px;
  opacity: 0.8;
`;

const DateRangeSelectorDisplay = styled.div`
  padding: 10px;
  height: 100%;
  border: 1px solid #f4f5f9;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;
  user-select: none;
  &.left {
    border: none;
    padding: unset;
    border-radius: 2px 0 0 2px;
  }
  &.right {
    border-radius: 0 2px 2px 0;
  }
`;