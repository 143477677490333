import { useQuery } from "react-query";
import { SiteWalkPoint, fetchPointClouds, fetchSiteWalk, fetchSiteWalkPoints } from "../../../../api/sitewalk";

export const useFetchSiteWalkQuery = (siteWalkId?: string | number, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['site-walk', siteWalkId],
    async () => {
      if (siteWalkId) {
        return await fetchSiteWalk(siteWalkId);
      }
    },
    { enabled: !!siteWalkId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }} 
  );
}

export const useFetchSiteWalkPointsQuery = (siteWalkId?: string | number, onSuccess?: (data: SiteWalkPoint[]) => void) => {
  return useQuery(
    ['site-walk-points', siteWalkId],
    async () => {
      if (siteWalkId) {
        return await fetchSiteWalkPoints(siteWalkId);
      }
    },
    { enabled: !!siteWalkId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data ?? []);
      }
    }} 
  );
}

export const useFetchPointCloudsQuery = (siteWalkId?: string | number, onSuccess?: (data: any) => void) => {
  return useQuery(
    ['point-clouds', siteWalkId],
    async () => {
      if (siteWalkId) {
        return await fetchPointClouds(siteWalkId);
      }
    },
    { enabled: !!siteWalkId, onSuccess: data => {
      if (onSuccess) {
        onSuccess(data)
      }
    }} 
  );
}