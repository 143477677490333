import { useRouteMatch } from 'react-router-dom';
import { PATH_STRINGS } from '../../../../hooks/useGeneratedPaths';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { useEffect } from 'react';

/**
 * Parses route parameters into variables
 */
export const useRouteData = () => {
  const { updateBuilding } = useBuildingContext();
  const buildingMatch = useRouteMatch<{ projectId: string }>(PATH_STRINGS.project);
  const floorMatch = useRouteMatch<{ floorId: string }>([
    PATH_STRINGS.floor,
    PATH_STRINGS.progressFloor,
    PATH_STRINGS.safetyMetricDetailFloor,
    PATH_STRINGS.safetyMetricDetailFloorPoint
  ]);
  const pointMatch = useRouteMatch<{ pointId: string }>([
    PATH_STRINGS.point,
    PATH_STRINGS.progressFloorPoint,
    PATH_STRINGS.safetyMetricDetailFloorPoint
  ]);
  const pointFullMatch = useRouteMatch<{ pointId: string }>(PATH_STRINGS.full);
  const projectId: string = buildingMatch?.params.projectId || '';
  const floorId: string = floorMatch?.params.floorId || '';
  const pointId: string = pointMatch?.params.pointId || pointFullMatch?.params.pointId || '';
  const fullScreen: boolean = pointFullMatch?.isExact || false;
  useEffect(() => {
    updateBuilding({
      projectId: projectId,
      floorId: floorId,
      pointId: pointId,
      fullscreen: fullScreen,
    });
    // eslint-disable-next-line
  }, [projectId, floorId, pointId, fullScreen]);

  return { projectId, floorId, pointId, fullScreen };
};
