import styled from 'styled-components';

export const SafetyDetailsPane = styled.div`
  min-width: 500px;
  line-height: 1.2em;
  height: 100%;
  border-radius: 2px;
  position: relative;
`;

export const SafetyImagePane = styled.div`
  width: 100%;
  height: 100%;
  margin-right: -60px;
`;
