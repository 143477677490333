import { useCallback, useMemo } from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#073c7a' : '#F4F5F9',
    boxShadow: 'none',
    minHeight: '50px',
    minWidth: 200,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#073c7a',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '6px',
  }),
};

interface CategoryFilterProps {
  onChange?: (severity: string) => void;
}

export const CategoryFilter = ({ onChange }: CategoryFilterProps) => {
  const handleChange = useCallback(
    newValue => {
      onChange && onChange(newValue.value);
    },
    [onChange]
  );

  const defaultOption = useMemo(() => {
    return { value: '', label: 'Any Category' };
  }, []);

  const options = useMemo(
    () => [
      defaultOption,
      {
        label: 'Concrete & Masonry',
        options: [
          { value: '472', label: 'Rebar no Cap' },
          { value: '802', label: 'Rebar with Cap' },
        ],
      },
      {
        label: 'Electrical',
        options: [
          { value: '780', label: 'Electrical Outlet Pole' },
          { value: '759', label: 'Electrical Outlet Spider Box' },
        ],
      },
      {
        label: 'Fall Protection',
        options: [
          { value: '563', label: 'Floor Opening Protected' },
          { value: '564', label: 'Floor Opening Unprotected' },
          { value: '787', label: 'Partially Covered Floor Opening' },
          { value: '785', label: 'Tight Wire Rope Guardrail with Netting' },
          { value: '568', label: 'Unprotected Leading Edge' },
          { value: '782', label: 'Wire Rope Guardrail without Netting' },
          { value: '781', label: 'Wooden Guardrail with Netting' },
          { value: '784', label: 'Wooden Guardrail without Middle Rail' },
          { value: '794', label: 'Wooden Guardrail without Netting' },
          { value: '783', label: 'Wooden Guardrail without Toe Board' },
          { value: '572', label: 'Worker with Harness on Scissor Lift' },
          { value: '801', label: 'Worker without Harness on Scissor Lift' },
          { value: '749', label: 'Person Wearing Harness' },
          { value: '795', label: 'Metal Guardrail' },
        ],
      },
      {
        label: 'Housekeeping',
        options: [{ value: '623', label: 'Wet Surface' }],
      },
      {
        label: 'Ladders & Stairs',
        options: [
          { value: '754', label: 'Closed Ladder' },
          { value: '789', label: 'Job Ladder' },
          { value: '799', label: 'Ladder Unsafe Position' },
          { value: '630', label: 'Locked Ladder' },
          { value: '798', label: 'Person on Ladder Safe' },
          { value: '797', label: 'Person on Ladder Unsafe' },
          { value: '755', label: 'Podium Ladder' },
          { value: '788', label: 'Unlocked Ladder' },
          { value: '800', label: 'Tool on Ladder' },
        ],
      },
      {
        label: 'PPE',
        options: [
          { value: '791', label: 'Person Not Wearing Hardhat' },
          { value: '792', label: 'Person Not Wearing High Visibility' },
          { value: '662', label: 'Person Not Wearing Gloves' },
          { value: '796', label: 'Person Not Wearing Glasses' },
          { value: '664', label: 'Person Wearing Hardhat' },
          { value: '666', label: 'Person Wearing High Visibility' },
          { value: '793', label: 'Person Wearing Gloves' },
          { value: '663', label: 'Person Wearing Safety Glasses' },
        ],
      },
      {
        label: 'Scissor/Aerial Lifts',
        options: [
          { value: '748', label: 'Scissor Lift' },
          { value: '692', label: 'Worker on Lift Rails' },
        ],
      },
    ],
    []
  );

  return (
    <Select
      onChange={handleChange}
      options={options}
      styles={customStyles}
      defaultValue={defaultOption}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#073c7a',
        },
      })}
    />
  );
};
