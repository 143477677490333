import { Cell, Label, Pie, PieChart } from "recharts";
import React from "react";

interface SafetyCircleProps {
  stats: any;
}
export const SafetyCircle = ({stats}: SafetyCircleProps) => {
  return(
    <PieChart width={50} height={50}>
      <Pie
        data={stats}
        dataKey="value"
        innerRadius={17}
        outerRadius={22}
        startAngle={90}
        endAngle={450}
        paddingAngle={2}>
        <Label position="center" style={{fontSize: '12px'}}>{`${Math.round((stats[0].value/(stats[0].value+stats[1].value))*100)}%`}</Label>
        {stats.map((entry: any, index: number) => <Cell key={index} fill={entry.color}/>)}
      </Pie>
    </PieChart>
  )
}