import { useReducer, useState } from "react";
import { useCreateRFITag, useProcoreCalls } from "../../../../api/procore";
import { useNotifications } from "../../../../contexts/notificationProvider";
import { LoadingIndicator } from "../../LoadingIndicator";
import { useGetPotentialRFIAssigneesQuery, useGetPotentialRFIManagersQuery, useGetPotentialRFIDistributionMembersQuery, useGetProcoreSpecSectionsQuery, useGetProcoreProjectLocationsQuery, useGetProcoreProjectVendorsQuery, useGetPotentialRFIReceivedFromsQuery } from "../hooks/procoreQueries";
import { RFIFormButton, RFIFormButtonsContainer, RFIFormDivider, RFIFormInput, RFIFormLabel, RFIFormRow, RFIFormSelect, RFIFormTitle, RFIFormTextArea, RFIFormContainer, RFIFormMultiSelect } from "./styles";

interface INewRFIFormProps {
  onCloseForm: () => void;
  hydrate: () => Promise<any>;
}

export interface NewRFI {
  subject: string;
  rfi_manager_id: number | null;
  assignee_ids: number[];
  question: {
    body: string;
    attachments: string[];
  };
  distribution_ids?: number[];
  received_from_login_information_id?: number;
  responsible_contractor_id?: number;
  drawing_number?: string;
  specification_section_id?: number;
  location_id?: number;
}

const initialNewRFI: NewRFI = {
  subject: '',
  rfi_manager_id: null,
  assignee_ids: [],
  question: {
    body: '',
    attachments: [],
  },
}

const newRFIReducer = (state: NewRFI, payload: Partial<NewRFI>) => {
  return {
    ...state,
    ...payload
  }
}

export const NewRFIForm = ({
  onCloseForm,
  hydrate,
}: INewRFIFormProps) => {
  const {addNotification} = useNotifications();
  const [newRFI, dispatchNewRFI] = useReducer(newRFIReducer, initialNewRFI);
  const [saveInProgress, setSaveInProgress] = useState<boolean>(false);

  const {data: potentialRFIManagers} = useGetPotentialRFIManagersQuery();
  const {data: potentialRFIAssignees} = useGetPotentialRFIAssigneesQuery();
  const {data: potentialRFIDistributionMembers} = useGetPotentialRFIDistributionMembersQuery();
  const {data: potentialRFIReceivedFroms} = useGetPotentialRFIReceivedFromsQuery();
  const {data: specSections} = useGetProcoreSpecSectionsQuery();
  const {data: locations} = useGetProcoreProjectLocationsQuery();
  const {data: projectVendors} = useGetProcoreProjectVendorsQuery();
  const createRFITag = useCreateRFITag();
  const {createRFI} = useProcoreCalls();
  
  const atLeastOneAssignee = !!newRFI.assignee_ids && newRFI.assignee_ids.length > 0;
  const formValidForSubmit = !!newRFI.subject && !!newRFI.rfi_manager_id && !!newRFI.question.body && atLeastOneAssignee;

  const onClickCreateRFI = async () => {
    try {
      setSaveInProgress(true);

      const createResponse = await createRFI(newRFI);
      
      await createRFITag(createResponse.id);

      await hydrate();
      addNotification('RFI created successfully', 'success');
      onCloseForm();
    } catch (err) {
      console.log('CREATE RFI ERR: ', err);
      addNotification('Error creating RFI', 'error');
    } finally {
      setSaveInProgress(false);
    }
  }

  const onHandleMultiSelectOptions = <T extends {}>(items: T[] | undefined, checked: boolean, selectedItem: T) => {
    let newItems = [...items ? items : []];

    if (checked) {
      newItems.push(selectedItem);
    } else {
      newItems = newItems.filter(item => item !== selectedItem);
    }

    return newItems;
  }

  const onChangeDistributionMembers = (checked: boolean, memberId: number) => {
    const newDistributionMembers = onHandleMultiSelectOptions(newRFI.distribution_ids, checked, memberId);

    dispatchNewRFI({distribution_ids: newDistributionMembers});
  }

  const onChangePotentialAssignees = (checked: boolean, assigneeId: number) => {
    const newPotentialAssignees = onHandleMultiSelectOptions(newRFI.assignee_ids, checked, assigneeId);

    dispatchNewRFI({assignee_ids: newPotentialAssignees});
  }

  const parseValueAsInt = (val: string | undefined) => {
    if (val) {
      return parseInt(val);
    }

    return undefined;
  }

  return (
    <>
      <RFIFormContainer>
        <RFIFormTitle>New Procore RFI</RFIFormTitle>

        <RFIFormDivider/>

        {saveInProgress &&
          <LoadingIndicator
            containerStyle={{
              backgroundColor: 'transparent'
            }}
          />
        }
        {!saveInProgress &&
          <>
            <RFIFormRow>
              <RFIFormInput
                label="Subject"
                required
                value={newRFI.subject}
                onChange={newVal => dispatchNewRFI({subject: newVal})}
                inputStyle={{width: '345px'}}
              />
            </RFIFormRow>
            <RFIFormRow>
              <RFIFormSelect
                required
                hideEmptyOption
                label="RFI Manager"
                value={newRFI.rfi_manager_id}
                onChange={newVal => dispatchNewRFI({rfi_manager_id: parseValueAsInt(newVal)})}
                options={potentialRFIManagers ?? []}
                mapOptionValue={option => option.id.toString()}
                mapOptionLabel={option => option.name}
              />

              <RFIFormMultiSelect
                label="Distribution Members"
                options={potentialRFIDistributionMembers ?? []}
                selectedValues={newRFI.distribution_ids ?? []}
                mapOptionLabel={option => option.name}
                mapOptionValue={option => option.id}
                onChangeChecked={onChangeDistributionMembers}
              />
            </RFIFormRow>
            <RFIFormRow>
              <RFIFormSelect
                label="Responsible Contractor"
                value={newRFI.responsible_contractor_id}
                onChange={newVal => dispatchNewRFI({responsible_contractor_id: parseValueAsInt(newVal)})}
                options={projectVendors ?? []}
                mapOptionValue={option => option.id.toString()}
                mapOptionLabel={option => option.name}
              />

              <RFIFormSelect
                label="Received From"
                value={newRFI.received_from_login_information_id}
                onChange={newVal => dispatchNewRFI({received_from_login_information_id: parseValueAsInt(newVal)})}
                options={potentialRFIReceivedFroms ?? []}
                mapOptionValue={option => option.id.toString()}
                mapOptionLabel={option => option.name}
              />
            </RFIFormRow>
            <RFIFormRow>
              <RFIFormInput
                label="Drawing Number"
                value={newRFI.drawing_number}
                onChange={newVal => dispatchNewRFI({drawing_number: newVal})}
              />
              <RFIFormMultiSelect
                required
                label="Assigned To"
                options={potentialRFIAssignees ?? []}
                selectedValues={newRFI.assignee_ids ?? []}
                mapOptionLabel={option => option.name}
                mapOptionValue={option => option.id}
                onChangeChecked={onChangePotentialAssignees}
              />
            </RFIFormRow>
            <RFIFormRow>
              <RFIFormSelect
                label="Spec Section"
                value={newRFI.specification_section_id}
                onChange={newVal => dispatchNewRFI({specification_section_id: parseValueAsInt(newVal)})}
                options={specSections ?? []}
                mapOptionValue={option => option.id.toString()}
                mapOptionLabel={option => option.label}
              />

              <RFIFormSelect
                label="Location"
                value={newRFI.location_id}
                onChange={newVal => dispatchNewRFI({location_id: parseValueAsInt(newVal)})}
                options={locations ?? []}
                mapOptionValue={option => option.id.toString()}
                mapOptionLabel={option => option.name}
              />
            </RFIFormRow>
            <RFIFormRow>
              <div>
                <RFIFormLabel required>Question</RFIFormLabel>
                <RFIFormTextArea
                  rows={5}
                  value={newRFI.question.body}
                  onChange={newVal => dispatchNewRFI({question: {...newRFI.question, body: newVal.target.value}})}
                />
              </div>
            </RFIFormRow>
          </>
        }
      </RFIFormContainer>
      <RFIFormButtonsContainer>
        {!saveInProgress &&
          <RFIFormButton
            onClick={onCloseForm}
          >
            Discard
          </RFIFormButton>
        }
        <RFIFormButton
          primary
          disabled={!formValidForSubmit || saveInProgress}
          onClick={onClickCreateRFI}
        >
          Create
        </RFIFormButton>
      </RFIFormButtonsContainer>
    </>
  )
}