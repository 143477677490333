import axios from "axios";

const BASE_URL = process.env.REACT_APP_NEXTERA_API;

const siteWalkAxiosInstance = axios.create();
delete siteWalkAxiosInstance.defaults.headers.common['Authorization'];

const s3UploadAxiosInstance = axios.create();
delete s3UploadAxiosInstance.defaults.headers.common['Authorization'];

interface InitiateMultipartResponse {
  upload_id: string;
  video_s3_key: string;
  presigned_posts: string[];
}

export const initiateSiteWalkMultipartUpload = async (
  siteWalkId: number,
  videoFileName: string,
  videoNumParts: number,
): Promise<InitiateMultipartResponse> => {
  const res = await siteWalkAxiosInstance.post(
    `${BASE_URL}/site-walk/${siteWalkId}/initiate-multipart-upload`,
    {
      video_file_name: videoFileName,
      video_num_parts: videoNumParts,
    },
  );

  const response = await res.data;
  return response.data;
};

export const uploadPart = async (
  url: string,
  chunk: Blob
): Promise<string> => {
  const res = await s3UploadAxiosInstance.put(url, chunk, {
    headers: {
      'Content-Type': 'application/octet-stream'
    }
  });

  const eTagHeader = res.headers.ETag || res.headers.etag;

  return eTagHeader;
}

export interface MultipartPart {
  PartNumber: number;
  ETag: string;
}

export const completeSiteWalkMultipartUpload = async (
  siteWalkId: number,
  videoS3Key: string,
  uploadId: string,
  parts: MultipartPart[],
) => {
  const res = await siteWalkAxiosInstance.post(
    `${BASE_URL}/site-walk/${siteWalkId}/complete-multipart-upload`,
    {
      video_s3_key: videoS3Key,
      upload_id: uploadId,
      parts,
    },
  );

  const response = await res.data;
  return response.data;
};

export const abortSiteWalkMultipartUpload = async (
  siteWalkId: number,
  videoS3Key: string,
  uploadId: string,
) => {
  const res = await siteWalkAxiosInstance.post(
    `${BASE_URL}/site-walk/${siteWalkId}/abort-multipart-upload`,
    {
      video_s3_key: videoS3Key,
      upload_id: uploadId,
    },
  );

  const response = await res.data;
  return response.data;
};

export const createSiteWalkVideoEntry = async (siteWalkId: number, updateData: Partial<SiteWalkVideo>) => {
  const res = await siteWalkAxiosInstance.post(
    `${BASE_URL}/site-walk/${siteWalkId}/video`,
    updateData
  );

  return await res.data;
};

export const updateSiteWalkVideoEntry = async (siteWalkId: number, videoSubId: number, updateData: Partial<SiteWalkVideo>) => {
  const res = await siteWalkAxiosInstance.patch(
    `${BASE_URL}/site-walk/${siteWalkId}/video/${videoSubId}`,
    updateData
  );

  return await res.data;
};

type SiteWalkStatus =
  | 'New'
  | 'Uploaded'
  | 'Incomplete'
  | 'Not Uploaded'
  | 'Upload Later'
  | 'Awaiting Transformation'
  | 'Transformed'

type SiteWalkType = 'picture' | 'video';

interface SiteWalkVideo {
  id: number;
  sub_id: number;
  site_walk: number;
  front_lens_file_name: string | null;
  front_lens_s3_key: string | null;
  back_lens_file_name: string | null;
  back_lens_s3_key: string | null;
  stitched_video_file_name: string | null;
  stitched_video_s3_key: string | null;
  front_lens_url: string | null;
  back_lens_url: string | null;
  stitched_video_url: string | null;
  number_of_parts: number | null;
  registered_on: string;
  last_modified_on: string;
}

export interface Sitewalk {
  id: number;
  status: SiteWalkStatus;
  user: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
  };
  duration: number;
  sub_group_id: number;
  project_floor: {
    id: number;
    floor_code: string;
  };
  project: {
    id: number;
    public_id: string;
    name: string;
  };
  video: SiteWalkVideo | null;
  last_modified_on: string;
  taken_on: string;
  group_name: string;
}

export const fetchSiteWalk = async (siteWalkId: string | number): Promise<Sitewalk> => {
  const res = await axios.get(`${BASE_URL}/site-walk/${siteWalkId}`);

  const response = await res.data;
  return response.data;
}

export const fetchSiteWalks = async (
  projectId?: string,
  statuses?: SiteWalkStatus[],
  type?: SiteWalkType,
): Promise<Sitewalk[]> => {
  const params = new URLSearchParams({
    ...!!projectId && {project: projectId},
    ...!!statuses && {status: statuses.join(',')},
    ...!!type && {type: type},
  });

  const res = await axios.get(
    `${BASE_URL}/site-walks`,
    {
      params: params
    },
  );

  const response = await res.data;
  return response.data;
}

export interface SiteWalkViewpointImage {
  angle: number;
  id: number;
  sub_point_id: number;
  taken_on: string;
  is_reviewed: boolean;
  is_approved: boolean;
}

export interface SiteWalkReviewFlags {
  wrong_location: boolean;
  brightness_issue: boolean;
  blurriness_issue: boolean;
}

export interface SiteWalkPoint extends SiteWalkReviewFlags {
  id: number;
  pcd_sub_id: number | null;
  // part_number: number | null;
  part_numbers: number[];
  site_walk: number;
  viewpoints_image: SiteWalkViewpointImage;
}

export const fetchSiteWalkPoints = async (siteWalkId: string | number): Promise<SiteWalkPoint[]> => {
  const res = await axios.get(`${BASE_URL}/site-walk/${siteWalkId}/points`);

  const response = await res.data;

  return response.data.map((point: SiteWalkPoint, i: number) => {
    point.viewpoints_image.taken_on = point.viewpoints_image.taken_on + `T12:00:00.${i % 1000}`;

    return point;
  });
}

export interface SiteWalkPointBulkUpdateData extends Partial<SiteWalkReviewFlags> {
  id: number;
  is_reviewed?: boolean;
  is_approved?: boolean;
}

export const bulkUpdateSiteWalkPoints = async (siteWalkId: string | number, data: SiteWalkPointBulkUpdateData[]): Promise<SiteWalkPoint[]> => {
  const res = await axios.patch(
    `${BASE_URL}/site-walk/${siteWalkId}/points/bulk-update`,
    data,
  );

  const response = await res.data;
  return response.data;
}

export interface SiteWalkPointCloud {
  id: number;
  is_rejected: boolean;
  is_stitched: boolean;
  last_modified_on: string;
  part_numbers: number[];
  point_cloud_s3_key: string;
  point_cloud_url: string;
  registered_on: string;
  site_walk: number;
  sub_id: number;
  trajectory_s3_key: string;
  trajectory_url: string;
  transformation_json: string;
}

export const fetchPointClouds = async (siteWalkId: string | number): Promise<SiteWalkPointCloud[]> => {
  const res = await axios.get(
    `${BASE_URL}/site-walk/${siteWalkId}/point-clouds`
  );

  const response = await res.data;
  return response.data;
}

interface Camera {
  model: string;
  manufacturer: string;
}

interface SiteWalkPost {
  duration: number;
  project_floor: number;
  sub_group_id: number;
  type: SiteWalkType;
  camera: Camera | null;
  taken_on: Date;
  start_point_sub_id: number | null;
}

export const createSiteWalk = async (
  siteWalkData: SiteWalkPost,
): Promise<Sitewalk> => {
  const res = await axios.post(
    `${BASE_URL}/site-walk`,
    siteWalkData,
  );

  const response = await res.data;
  return response.data;
};

interface VerificationStats {
  total: number;
  is_reviewed: number;
  is_approved: number;
}

export type SiteWalkVerificationStats = Record<number, VerificationStats>;

export const fetchSiteWalkVerificationStats = async (
): Promise<SiteWalkVerificationStats> => {
  const res = await axios.get(
    `${BASE_URL}/site-walks/verification-stats`
  );

  const response = await res.data;
  return response.data;
}