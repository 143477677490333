import { useMemo } from 'react';
import { Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { JobType } from '../../../../../api/jobs';
import { CustomTooltip, CustomTooltipLabel, CustomTooltipValue } from './CustomTooltip';
import { NoChartData, ProgressSummaryEntry, xAxisTickFormatter } from './ProgressCharts';

const formatCompletePercentage = (decimal: number) => {
  const percent = Math.round(decimal * 100);

  return `${percent}%`;
};

interface IRenderCustomTooltipProps {
  active?: boolean;
  label?: string;
  payload?: any[];
  activeTrackerJobTypes: JobType[];
}

const RenderCustomTooltip = ({
  active,
  label,
  payload,
  activeTrackerJobTypes,
}: IRenderCustomTooltipProps) => {
  if (active && payload && payload.length && label) {
    const tooltipLabel = xAxisTickFormatter(label);

    return (
      <CustomTooltip>
        <CustomTooltipLabel>{tooltipLabel}</CustomTooltipLabel>
        {activeTrackerJobTypes.map(jobType => {
          const payloadEntry = payload.find(entry => entry.dataKey === jobType.slug);

          if (payloadEntry) {
            return (
              <CustomTooltipValue
                style={{
                  color: payloadEntry.color,
                }}>
                {jobType.name}: {formatCompletePercentage(payloadEntry.value)}
              </CustomTooltipValue>
            );
          } else {
            return <></>;
          }
        })}
      </CustomTooltip>
    );
  }

  return <></>;
};

interface IProgressLineChartProps {
  progressSummaryByDate: ProgressSummaryEntry[];
  activeTrackerJobTypes: JobType[];
}

export const ProgressLineChart = ({
  progressSummaryByDate,
  activeTrackerJobTypes,
}: IProgressLineChartProps) => {
  const data = useMemo(() => {
    return progressSummaryByDate.map(entry => {
      const dateSummary: Record<string, string | number | Date> = { date: new Date(entry.date) };

      Object.getOwnPropertyNames(entry).forEach(propName => {
        if (propName !== 'date') {
          dateSummary[propName] = entry[propName].completed_units / entry[propName].total_units;
        }
      });

      return dateSummary;
    });
  }, [progressSummaryByDate]);

  if (data.length === 0) {
    return <NoChartData />;
  }

  return (
    <ResponsiveContainer width="99%" height="99%">
      <LineChart data={data}>
        <YAxis domain={[0, 1]} tickFormatter={formatCompletePercentage} />
        <XAxis dataKey="date" tickFormatter={xAxisTickFormatter} interval="preserveStartEnd" />
        <Tooltip
          content={props => (
            <RenderCustomTooltip {...props} activeTrackerJobTypes={activeTrackerJobTypes} />
          )}
          wrapperStyle={{
            top: '-10px',
          }}
        />
        {activeTrackerJobTypes.map(type => {
          return (
            <Line key={type.slug} dataKey={type.slug} stroke={type.display_color} strokeWidth={2} />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};
