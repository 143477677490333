import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Job } from '../../../../../api/jobs';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { Item } from '../../../../../api/items';

const generateLightingBoltSVGPath = (xOffset: number, yOffset: number) => {
  return `
    M ${19.375 + xOffset} ${13.7837 + yOffset}
    H ${11.0308 + xOffset}
    L ${15.0632 + xOffset} ${1.875 + yOffset}
    L ${0.625 + xOffset} ${17.8026 + yOffset}
    H ${8.96923 + xOffset}
    L ${4.94437 + xOffset} ${30 + yOffset}
    L ${19.375 + xOffset} ${13.7837 + yOffset}
  `;
}

const seeThroughSlugPrefixes = ["ceiling_", "clutter", "lighting", "wet_surface"];

interface LayerManagerProps {
  jobs: Job[];
  setTooltipItem: (item: Item | null) => void;
  setHoverCoordinates: (coordinates: { x: number; y: number }) => void;
  selectedJobTypes: number[];
  activeTracker: string;
  displayZeroValues?: boolean;
  observationDate?: Date;
  selectedItemId?: number;
  inDebugMode?: boolean;
  onClickItem?: (pointId: string, observationDate?: Date, activeTracker?: string, itemId?: string | number) => void;
}

export const LayerManager = ({
  jobs,
  setTooltipItem,
  setHoverCoordinates,
  selectedJobTypes,
  activeTracker,
  displayZeroValues=false,
  inDebugMode=false,
  observationDate,
  selectedItemId,
  onClickItem
}: LayerManagerProps) => {
  const [paths, setPaths] = useState<any[]>([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const { state: buildingState } = useBuildingContext();

  const buildingPointMap = useMemo(() => {
    const pointMap = new Map<number, number>();

    if (buildingState.floorData.points) {
      buildingState.floorData.points.forEach((point: any) => {
        pointMap.set(point.id, point.point_id);
      })
    }

    return pointMap;
  }, [buildingState.floorData.points]);

  const useLineForMechanical = useMemo(() => {
    const oldProjectIds = ["myFE3eLIhA", "xVrtPeVag", "Cdfrk7G", "nNkkv7gmQ", "rVHF8Zfxz", "2MEQPeZxFv", "3FD6QNa"];
    const isOldProject = oldProjectIds.includes(buildingState.projectId);
    const isMechanical = activeTracker === 'Mechanical';

    return isOldProject && isMechanical 
  }, [buildingState.projectId, activeTracker]);

  //Older projects were set up to display mechanical jobs as lines
  //Now they are displayed as areas
  //This corrects "area" back to "line" for these old jobs
  useEffect(() => {
    if (jobs && useLineForMechanical) {
      jobs.forEach(job => {
        if (job.type.job_type.display_shape === 'area') {
          job.type.job_type.display_shape = 'line';
        }
      });
    }
  }, [jobs, useLineForMechanical]);

  const jobsForSelectedTypes = useMemo(() => {
    if (jobs) {
      return jobs.filter((job: any) => selectedJobTypes.includes(job.type.job_type.id));
    }

    return [];
  }, [jobs, selectedJobTypes]);

  const drawlines = (data: any) => {
    // @ts-ignore
    let c: HTMLCanvasElement | null = document.getElementById('progress-canvas');
    let pths: any = [];
    if (c) {
      let ctx = c.getContext('2d');
      if (ctx) {
        //@ts-ignore
        ctx.clearRect(0, 0, dimensions.width, dimensions.height);
        ctx.lineWidth = 14;
        ctx.lineCap = 'butt';
        ctx.globalAlpha = 1;
        
        const dummyJobs: Job[] = [];
        const seenItems = new Set<number>();

        jobs.forEach(job => {
          const itemId = job.item.id;

          if (!seenItems.has(itemId)) {
            const dummyJob: Job = {
              ...job,
              completed_units: 1,
              type: {
                ...job.type,
                job_type: {
                  ...job.type.job_type,
                  display_order: Number.MIN_SAFE_INTEGER,
                  display_color: '#808080',
                  secondary_display_color: job.type.job_type.slug.startsWith('electrical') ? '#e8e1e1' : '#808080',
                  tertiary_display_color: '#d1cbcb'
                }
              }
            }

            dummyJobs.push(dummyJob);
            seenItems.add(itemId);
          }
        });

        let sorted = [...data, ...dummyJobs].sort((a: any, b: any) => {
          try {
            return a.type.job_type.display_order < b.type.job_type.display_order ? -1 : 1;
          } catch (e) {
            console.log(e);
            return 0;
          }
        });

        sorted.forEach((job: any) => {
          if (job.completed_units >= 0) {
            let coords = job.item.position.map((p: any) => [p.x, p.y]);
            const completedUnits = job.completed_units;

            const {
              display_shape: displayShape,
              slug,
              display_color,
              secondary_display_color,
              tertiary_display_color,
            } = job.type.job_type;

            const drawPath = displayShape === "line" || displayShape === "area";
            const meetsCompletedUnitsThreshold = displayZeroValues || completedUnits > 0;
            const isWetSurface = slug.startsWith("wet_surface");
            const seeThrough = seeThroughSlugPrefixes.some(value => slug.startsWith(value));

            if (ctx && coords[0] && meetsCompletedUnitsThreshold) {
              let path = new Path2D();
              let strokeStyle = display_color;
              let fillStyle = secondary_display_color ?? display_color;

              if (isWetSurface && job.completed_units === 1) {
                fillStyle = `${fillStyle}00`
              }

              ctx.strokeStyle = strokeStyle;
              ctx.fillStyle = fillStyle;

              if (drawPath) {
                path.moveTo(coords[0][0], coords[0][1]);
                coords = coords.slice(1)
                coords.forEach( (point: any) => {
                  path.lineTo(point[0], point[1])
                })
                if(displayShape === "line"){
                  // path.lineTo(coords[0][0], coords[0][1])
                  ctx.stroke(path);
                } else if(displayShape === "area"){
                  if (seeThrough) {
                    ctx.globalAlpha = 0.5;
                  }
                  
                  ctx.closePath()
                  ctx.fill(path);
                }
              } else if (displayShape === "point") {
                if (slug.startsWith("electrical")) {
                  ctx.lineWidth = 4;
                  ctx.strokeStyle = fillStyle;
                  ctx.fillStyle = strokeStyle;
                  path.arc(coords[0][0], coords[0][1], 22, 0, 2 * Math.PI);
                  ctx.stroke(path);

                  const fillingPath = new Path2D();
                  fillingPath.arc(coords[0][0], coords[0][1], 20, 0, 2 * Math.PI);
                  ctx.fill(fillingPath);

                  ctx.fillStyle = tertiary_display_color;
                  const lightningBoltPath = new Path2D(generateLightingBoltSVGPath(parseFloat(coords[0][0]) - 9.5, parseFloat(coords[0][1]) - 16));
                  ctx.fill(lightningBoltPath);
                }
              }

              pths.push({ path: path, job: job });
            }
          }
        });
      }
      setPaths(pths);
    }
  };

  const checkHovering = useCallback((e: MouseEvent) => {
    // @ts-ignore
    const canvasElement: HTMLCanvasElement | null = document.getElementById('progress-canvas');
    if (canvasElement) {
      const ctx = canvasElement.getContext('2d');
      if (ctx) {
        const pos = getMousePos(canvasElement, e); // get adjusted coordinates as above
        let matrix = ctx.getTransform();
        const imatrix = matrix.invertSelf(); // invert
        // apply to point:
        const x = pos.x * imatrix.a + pos.y * imatrix.c + imatrix.e;
        const y = pos.x * imatrix.b + pos.y * imatrix.d + imatrix.f;
        let found = false;
        let found_path: any = null;
        
        paths.forEach(p => {
          const displayShape = p.job.type.job_type.display_shape;

          if (ctx && ctx.isPointInStroke(p.path, x, y)) {
            if(displayShape === "line" || displayShape === "point") {
              found = true;
              found_path = p;
            }
          }
          if (ctx && ctx.isPointInPath(p.path, x, y)) {
            if(displayShape === "area" || displayShape === "point") {
              found = true;
              found_path = p;
            }
          }
        });

        if (found) {
          canvasElement.style.cursor = 'pointer';
          const found_item = found_path.job.item;

          if (found_item) {
            setHoverCoordinates({ x: pos.tooltipX, y: pos.tooltipY });

            return found_item as Item;
          }
        } else {
          canvasElement.style.cursor = 'default';
        }
      }
    }

    return null;
  }, [paths, setHoverCoordinates]);

  const handleItemHover = useCallback((e: MouseEvent) => {
    const mousePointerItem = checkHovering(e);

    setTooltipItem(mousePointerItem);
  }, [checkHovering, setTooltipItem]);

  const handleItemClick = useCallback((e: MouseEvent) => {
    const mousePointerItem = checkHovering(e);

    if (mousePointerItem) {
      const mousePointerItemPointSubId = buildingPointMap.get(mousePointerItem.viewpoint);

      if (mousePointerItemPointSubId && onClickItem) {
        onClickItem(mousePointerItemPointSubId.toString(), observationDate, activeTracker, mousePointerItem.id);
      }
    }
  }, [activeTracker, buildingPointMap, checkHovering, observationDate, onClickItem]);

  const canvasRef = useCallback(
    node => {
      if (node !== null && dimensions.height !== 0) {
        drawlines(jobsForSelectedTypes);
      }
    },
    [jobsForSelectedTypes, dimensions]
  );

  useEffect(() => {
    let fp = document.getElementById('floor-plan');
    if (fp && jobs) {
      setDimensions({ width: fp.clientWidth, height: fp.clientHeight });
    }
  }, [jobs]);

  useEffect(() => {
    let canvas = document.getElementById('progress-canvas');
    if (canvas) {
      canvas.addEventListener('mousemove', handleItemHover);
      canvas.addEventListener('click', handleItemClick);
    }
    return () => {
      if (canvas) {
        canvas.removeEventListener('mousemove', handleItemHover);
        canvas.removeEventListener('click', handleItemClick);
      }
    };
  }, [handleItemClick, handleItemHover]);

  return (
    <>
      <canvas
        id="progress-canvas"
        ref={canvasRef}
        width={dimensions.width}
        height={dimensions.height}
        style={{ position: 'absolute', left: 0 }}
      />
    </>
  );
};

function getMousePos(canvas: any, evt: MouseEvent) {
  var rect = canvas.getBoundingClientRect(), // abs. size of element
    scaleX = canvas.width / rect.width, // relationship bitmap vs. element for x
    scaleY = canvas.height / rect.height; // relationship bitmap vs. element for y

  return {
    x: (evt.clientX - rect.left) * scaleX, // scale mouse coordinates after they have
    y: (evt.clientY - rect.top) * scaleY, // been adjusted to be relative to element
    tooltipX: evt.clientX,
    tooltipY: evt.clientY,
  };
}
