import styled from "styled-components";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { AddProjectButton } from "../../../buildings_page/components/AddProjectButton";
import { useSchedulesQuery } from "../../hooks/adminBuildingQueries";
import iconPlus from '../../../../../assets/images/icon_plus.svg';
import iconTrash from '../../../../../assets/images/icon_trash_red.svg';
import { useNavigation } from "../../../../../hooks/useNavigation";
import Flex from "@react-css/flex";
import { Table } from "../../../../common/Table/Table";
import React, { useCallback, useMemo, useState } from "react";
import { deleteSchedule } from "../../../../../api/adminBuildingFetches";
import { useNotifications } from "../../../../../contexts/notificationProvider";
import { ConfirmationModal } from "../../../../common/Confirmation/Confirmation";
import { Column } from "react-table";
import { useManageSchedulesContext } from "./ManageSchedulesContext";

export interface IScheduleDashboardEntry {
  id: number | 'new';
  name: string;
  frequency: string;
  frequencyFormatted: string;
  start_date: string;
  till_date: string;
  time: string;
  formatted_time?: string;
  converted_time?: Date;
  nextScan?: Date;
  nextScanFormatted?: string;
  nextScanExpired?: boolean;
  nextScanLessThan24HoursAway?: boolean;
}

export interface ISchedule {
  id: number | 'new';
  name: string;
  frequency: string;
  start_date: Date | string;
  till_date: Date | string;
  time: string;
}

interface ISchedulesDashboardProps {
  firstFloorName: string;
}

export const SchedulesDashboard = ({
  firstFloorName,
}: ISchedulesDashboardProps) => {
  const {
    updateBuilding,
    state: buildingState,
  } = useBuildingContext();

  const {
    canCreateSchedules,
    canDeleteSchedules,
  } = useManageSchedulesContext();

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [scheduleToDelete, setScheduleToDelete] = useState<number | 'new' | null>(null);

  const { navigateToManageSchedules } = useNavigation();

  const { addNotification } = useNotifications();

  const updateSchedules = useCallback((schedules: ISchedule[]) => {
    updateBuilding({
      schedules
    })
  }, [updateBuilding]);

  const { isLoading: schedulesLoading } = useSchedulesQuery(updateSchedules);

  const onClickAddSchedule = () => {
    navigateToManageSchedules(firstFloorName, 'new');
  }

  const onClickScheduleRow = (schedule: ISchedule) => {
    navigateToManageSchedules(firstFloorName, schedule.id);
  }

  const onDeleteSchedule = async () => {
    if (scheduleToDelete !== null) {
      try {
        const deletedSchedule = await deleteSchedule(buildingState.projectId, scheduleToDelete);
        const updatedSchedules = buildingState.schedules.filter((schedule: ISchedule) => schedule.id !== deletedSchedule.id);

        updateSchedules(updatedSchedules);
        addNotification('Schedule deleted successfully', 'success');
      } catch (err) {
        console.log('deleteSchedule==>>', err);
        addNotification('Error deleting schedule', 'error');
      }

      setScheduleToDelete(null);
    }
  }

  const data = useMemo(() => {
    return buildingState.schedules.map((schedule: ISchedule) => {
      const onClickDelete = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        setConfirmation(true);
        setScheduleToDelete(schedule.id);
      }

      return {
        ...schedule,
        delete: (
          <AddProjectButton
            text='Delete'
            icon={iconTrash}
            onClick={(e) => onClickDelete(e)}
            buttonStyle={{color: '#ff5252', border: '1px solid #ff5252'}}
          />
        )
      }
    })
  }, [buildingState.schedules]);

  const sortDates = useCallback((rowA: any, rowB: any, columnId: string, desc?: boolean) => {
    if (rowA.original.nextScanExpired && rowB.original.nextScanExpired) {
      return 0;
    } else if (rowA.original.nextScanExpired) {
      return desc ? -1 : 1;
    } else if (rowB.original.nextScanExpired) {
      return desc ? 1 : -1;
    }

    const dateA: Date = rowA.original.nextScan;
    const dateB: Date = rowB.original.nextScan;

    const dateALarger = dateA.getTime() - dateB.getTime() >= 0;

    return dateALarger ? 1 : -1;
  }, []);

  const columns = useMemo((): Column[] => [
    { Header: 'Name', accessor: 'name', width: '40%' },
    { Header: 'Frequency', accessor: 'frequencyFormatted', width: '20%' },
    { Header: 'Next Scan', accessor: 'nextScanFormatted', sortType: sortDates, width: '25%' },
    ...canDeleteSchedules ? [{ Header: 'Delete', accessor: 'delete', width: '15%', disableSortBy: true }] : [],
  ], [sortDates, canDeleteSchedules]);

  const tableHeader = <ScheduleHeading>SCHEDULES</ScheduleHeading>;

  return (
    <>
      { schedulesLoading &&
        <LoadingIndicator />
      }
      
      { !schedulesLoading &&
        <>
          { canCreateSchedules &&
            <Flex
              flexDirection='column'
              alignItems='flex-end'
            >
              <AddProjectButton
                text='Add Schedule'
                icon={iconPlus}
                onClick={onClickAddSchedule}
              />
            </Flex>
          }

          <Table
            data={data}
            columns={columns}
            onClickRow={onClickScheduleRow}
            headerStyles={{textAlign: 'left'}}
            bodyStyles={{textAlign: 'left', paddingLeft: '5px'}}
            headerText={tableHeader}
            initialSortBy={[
              {
                id: 'nextScanFormatted',
                desc: false
              }
            ]}
          />
        </>
      }

      <ConfirmationModal
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message='Are you sure you would like to delete this schedule?'
        onConfirm={onDeleteSchedule}
      />
    </>
  )
}

const ScheduleHeading = styled.h1`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #212121;
  margin-bottom: 8px;
`;