import { Point } from '../../../../../../api/types';
import { useBuildingContext } from '../../../../../../contexts/buildingContext';
import { memo, useCallback, useEffect, useState } from 'react';
import { useNavigation } from '../../../../../../hooks/useNavigation';
import mixpanel from 'mixpanel-browser';
import { useImageViewerContext } from '../../../imageViewerContext';
import { getTourPoints } from './getTourPoints';
import { usePannellumViewer } from '../../../../../../hooks/usePannellum';

const INITIAL_RANGE = 350;

interface TourManagerProps {
  viewerId: string;
  height: number;
  trueX: string;
  trueY: string;
}

export const TourManager = memo((props: TourManagerProps) => {
  const { state: buildingState, updateTour } = useBuildingContext();
  const { navigateToPointFullscreen } = useNavigation();
  const { updateMaster, state: imageViewerState } = useImageViewerContext();
  const { loaded, addHotSpot } = usePannellumViewer(props.viewerId);
  const [populated, setPopulated] = useState(false);
  const [tourPoints, setTourPoints] = useState<any[]>([]);
  const SCALE = buildingState.floorData.latest_floor_plan.scale || 1;

  const hospotClickHandler = useCallback(
    (
      _event: any,
      args: { pitch: number; yaw: number; type: string; tagId?: number; extras?: any }
    ) => {
      if (args.extras) {
        let date = new URLSearchParams(window.location.search).get('date');
        if (date) {
          navigateToPointFullscreen(args.extras.pointId, { date: date });
        } else {
          navigateToPointFullscreen(args.extras.pointId);
        }
      }
      updateMaster({ angleDelta: null });
      mixpanel.track('Click Tour Hotspot');
    },
    [navigateToPointFullscreen, updateMaster]
  );

  useEffect(() => {
    const newPoints = getTourPoints(buildingState.floorData.points, buildingState.pointData);
    setTourPoints(newPoints);
  }, [buildingState.floorData.points, buildingState.pointData]);

  useEffect(() => {
    if (tourPoints.length > 0 && !populated) {
      updateTour(tourPoints);
    }
  }, [tourPoints, populated, updateTour]);

  const populate = useCallback(() => {
    let currentPointCoords = { x: buildingState.pointData.x, y: buildingState.pointData.y };
    if (props.trueX) {
      currentPointCoords.x = parseFloat(props.trueX);
      currentPointCoords.y = parseFloat(props.trueY);
    }

    if (tourPoints.length > 0) {
      tourPoints.forEach((point: Point) => {
        let distance = Math.round(distanceBetween(point, currentPointCoords));
        let relative = relativeCoords(
          currentPointCoords,
          point,
          distance,
          SCALE,
          props.height || 50
        );
        let scaleY = limitNumberWithinRange(
          SCALE > 1
            ? 1 - (distance * SCALE - INITIAL_RANGE / SCALE) / (INITIAL_RANGE / SCALE)
            : ((distance * SCALE - INITIAL_RANGE / SCALE) * -1) / (INITIAL_RANGE / SCALE),
          0.25,
          1
        );
        if (loaded && !populated) {
          addHotSpot(
            relative,
            'TOUR',
            hospotClickHandler,
            () => null,
            Number(point.point_id),
            imageViewerState['master'].data?.angle || 0,
            {
              pointId: point.point_id,
              distance: distance,
              scaleY: scaleY,
              scaleX: scaleY + 1.4 - (1 - scaleY),
            }
          );
        }
      });
      setPopulated(true);
    }
  }, [
    SCALE,
    addHotSpot,
    buildingState.pointData.x,
    buildingState.pointData.y,
    hospotClickHandler,
    imageViewerState,
    loaded,
    populated,
    props.height,
    props.trueX,
    props.trueY,
    tourPoints,
  ]);

  useEffect(() => {
    if (loaded) populate();
  }, [loaded, populate]);

  useEffect(() => {
    if (loaded) populate();
  }, [buildingState.imageData, loaded, populate]);

  return <></>;
});

TourManager.displayName = 'TourManager';

// Util functions

const distanceBetween = (p1: any, p2: any) => {
  return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
};

function getPitch(distance: number, scale: number, imageHeight: number) {
  if (distance <= 0) {
    return 0;
  }
  let ROVER_HEIGHT = imageHeight / scale;
  let c = Math.sqrt(Math.pow(distance, 2) + Math.pow(ROVER_HEIGHT, 2));
  let theta = Math.atan2(c, ROVER_HEIGHT);
  return (theta / Math.PI) * 180 - 90;
}

const relativeCoords = (
  p1: any,
  p2: any,
  distance: number,
  scale: number,
  imageHeight: number
): [number, number] => {
  var deltaX = p2.x - p1.x;
  var deltaY = p2.y - p1.y;
  var rad = Math.atan2(deltaY, deltaX);
  var yaw = (rad / Math.PI) * 180 + (rad > 0 ? 0 : 360);
  yaw += 90; // arbitrary correction, but seems to work.
  return [getPitch(distance, scale, imageHeight), yaw];
};

function limitNumberWithinRange(num: number, min: number, max: number) {
  const MIN = min || 1;
  const MAX = max || 20;
  return Math.min(Math.max(num, MIN), MAX);
}
