import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MultipartPart, Sitewalk, completeSiteWalkMultipartUpload, updateSiteWalkVideoEntry, fetchSiteWalks, initiateSiteWalkMultipartUpload, uploadPart, createSiteWalkVideoEntry } from "../../../api/sitewalk";
import { DragAndDrop } from "./DragAndDrop";
import { useUserContext } from "../../../contexts/userContext";
import { SiteWalkVideoUploadTable } from "./SiteWalkVideoUploadTable";
import styled from "styled-components";
import { AddProjectButton } from "../buildings_page/components/AddProjectButton";
import {v4 as uuidv4} from 'uuid';
import { useNotifications } from "../../../contexts/notificationProvider";
import { UnassociatedVideosTable } from "./UnassociatedVideosTable";

export interface SiteWalkUploadData {
  id: string;
  videoFile: File;
  siteWalk: Sitewalk | undefined;
  progress: number;
  completedParts: MultipartPart[];
  presignedPosts: string[];
  videoS3Key: string;
  uploadId: string;
  initiated: boolean;
  error: boolean;
}

export const SiteWalkVideoUpload = () => {
  const [videosToUpload, setVideosToUpload] = useState<SiteWalkUploadData[]>([]);
  const newBatch = useRef<SiteWalkUploadData[]>([]);
  const [uploadInProgress, setUploadInProgress] = useState<boolean>(false);
  const [unassociatedVideos, setUnassociatedVideos] = useState<SiteWalkUploadData[]>([]);
  const [siteWalks, setSiteWalks] = useState<Sitewalk[]>([]);

  const displayTables = videosToUpload.length > 0 || unassociatedVideos.length > 0;

  const {addNotification} = useNotifications();

  const {state: userState} = useUserContext();

  const allVideosComplete = useMemo(() => {
    return unassociatedVideos.length === 0 && videosToUpload.length > 0 && videosToUpload.every(entry => entry.initiated && entry.progress === 1);
  }, [unassociatedVideos, videosToUpload]);

  const failedUploads = useMemo(() => {
    return videosToUpload.filter(video => video.error);
  }, [videosToUpload]);

  const fetchSiteWalksNotUploaded = useCallback(async () => {
    const returnedWalks = await fetchSiteWalks(undefined, ['Not Uploaded'], 'video');
    returnedWalks.sort((a,b) => new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime());

    setSiteWalks(returnedWalks);
  }, []);

  useEffect(() => {
    fetchSiteWalksNotUploaded();
  }, [fetchSiteWalksNotUploaded]);

  const cleanFileName = (fileName: string) => {
    if (fileName[0] === 'R' && fileName.slice(-4) === '.MP4') {
      return fileName.replace(/\[\d\]/, '');
    }

    return fileName;
  }

  const isFileNameMatch = (fileName1: string, fileName2: string) => {
    const nameRegex = /^([^.]+)/;
    const extensionRegex = /(?<=\.)[^.]+/;

    const name1 = fileName1.match(nameRegex);
    const name2 = fileName2.match(nameRegex);

    const extension1 = fileName1.match(extensionRegex);
    const extension2 = fileName2.match(extensionRegex);

    const sameNames = !!name1 && !!name2 && name1[0] === name2[0];
    const sameExtensions = !!extension1 && !!extension2 && !!extension1[0] && !!extension2[0] && extension1[0].toLowerCase() === extension2[0].toLowerCase();

    return sameNames && sameExtensions;
  }

  const handleAddFiles = (fileList: FileList | null) => {
    if (fileList) {
      const siteWalksWithVideo = siteWalks.filter(walk => walk.video !== null);
      const userEmail = userState.email;

      siteWalksWithVideo.sort((a, b) => {
        if (a.user.email !== b.user.email) {
          return a.user.email === userEmail ? -1 : b.user.email === userEmail ? 1 : 0;
        } else {
          return new Date(b.taken_on).getTime() - new Date(a.taken_on).getTime();
        }
      });

      const newValidFiles: SiteWalkUploadData[] = [];
      const newUnassociatedFiles: SiteWalkUploadData[] = [];
      const assignedSiteWalks = new Set<number>();
      let invalidFileFound = false;

      for (let i=0; i<fileList.length; i++) {
        const file = fileList[i];
        const validFileType = !!file.type && file.type.startsWith('video');

        const candidateSiteWalks = siteWalksWithVideo.filter(walk => {
          if (assignedSiteWalks.has(walk.id)) {
            return false;
          }

          if (walk.user.email !== userEmail) {
            return false;
          }

          let frontNameMatches = false;
          let backNameMatches = false;
          let stitchNameMatches = false;
          const cleanedFileName = cleanFileName(file.name);
          
          if (walk.video?.front_lens_file_name) {
            frontNameMatches = isFileNameMatch(walk.video.front_lens_file_name, cleanedFileName);
          }

          if (walk.video?.back_lens_file_name) {
            backNameMatches = isFileNameMatch(walk.video.back_lens_file_name, cleanedFileName);
          }

          if (walk.video?.stitched_video_file_name) {
            stitchNameMatches = isFileNameMatch(walk.video.stitched_video_file_name, cleanedFileName);
          }

          return frontNameMatches || backNameMatches || stitchNameMatches;
        });

        const suspectedSiteWalk = candidateSiteWalks.length === 1 ? candidateSiteWalks[0] : undefined;

        if (file.size > 0 && validFileType) {
          const videoData: SiteWalkUploadData = {
            id: uuidv4(),
            videoFile: file,
            siteWalk: suspectedSiteWalk,
            progress: 0,
            completedParts: [],
            presignedPosts: [],
            videoS3Key: "",
            uploadId: "",
            initiated: false,
            error: false,
          }

          if (suspectedSiteWalk) {
            assignedSiteWalks.add(suspectedSiteWalk.id);

            newValidFiles.push(videoData);
          } else {
            newUnassociatedFiles.push(videoData);
          }
        } else {
          invalidFileFound = true;
        }
      }

      setVideosToUpload(prev => [...prev, ...newValidFiles]);
      setUnassociatedVideos(prev => [...prev, ...newUnassociatedFiles]);
      uploadPendingWalks(newValidFiles);

      if (invalidFileFound) {
        addNotification('One or more files could not be uploaded', 'warning');
      }
    }
  }

  const onSelectFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleAddFiles(e.target.files);
  }

  const updateVideoState = (videoIdentifier: string, data: Partial<SiteWalkUploadData>) => {
    setVideosToUpload(prevVideos => {
      return prevVideos.map(video => {
        const videoIsInProgress = !!video.id && video.id === videoIdentifier;

        if (videoIsInProgress) {
          return {
            ...video,
            ...data,
          }
        } else {
          return video;
        }
      });
    });
  }

  const uploadSiteWalkVideo = async (videoToUpload: SiteWalkUploadData) => {
    const {videoFile, siteWalk} = videoToUpload;
    const siteWalkId = siteWalk?.id;
    const fileSize = videoFile.size;
    const chunkSize = 50 * 1000 * 1000;
    const numChunks = Math.ceil(fileSize / chunkSize);

    if (!!siteWalk && !!siteWalkId) {
      updateVideoState(videoToUpload.id, {initiated: true});

      let presignedPosts = [...videoToUpload.presignedPosts];
      let videoS3Key = videoToUpload.videoS3Key;
      let uploadId = videoToUpload.uploadId;

      if (presignedPosts.length === 0 || !videoS3Key || !uploadId) {
        const initiatedMultipartUpload = await initiateSiteWalkMultipartUpload(siteWalkId, videoFile.name, numChunks);

        presignedPosts = initiatedMultipartUpload.presigned_posts;
        videoS3Key = initiatedMultipartUpload.video_s3_key;
        uploadId = initiatedMultipartUpload.upload_id;
      }

      const completedParts: MultipartPart[] = [...videoToUpload.completedParts];
      const completedPartSet: Set<number> = new Set(completedParts.map(part => part.PartNumber));
      const partsToUpload = Array.from(Array(numChunks), (_, i) => i+1).filter(i => !completedPartSet.has(i));

      const retryCounts = new Map<number, number>();
      let failedPartsInARow = 0;

      updateVideoState(videoToUpload.id, {
        presignedPosts: presignedPosts,
        videoS3Key: videoS3Key,
        uploadId: uploadId,
        completedParts: completedParts,
      });
      
      for (let j=0; j<partsToUpload.length; j++) {
        const partNumber = partsToUpload[j];
        const partIndex = partNumber - 1;
        const post = presignedPosts[partIndex];
        retryCounts.set(partNumber, 0);
        
        const start = partIndex * chunkSize;
        const end = start + chunkSize;
        const blob = videoFile.slice(start, end);

        try {
          const eTag = await uploadPart(post, blob);

          completedParts.push({
            ETag: eTag,
            PartNumber: partNumber,
          });

          const progress = completedParts.length / numChunks;

          updateVideoState(videoToUpload.id, {
            progress: progress,
          });

          failedPartsInARow = 0;
        } catch (err: any) {
          const partRetryCount = retryCounts.get(partNumber) ?? 0 + 1;
          failedPartsInARow += 1;

          const tooManyFailedPartsInARow = failedPartsInARow > 5;
          const tooManyRetriesForPart = partRetryCount > 5;

          if (tooManyFailedPartsInARow || tooManyRetriesForPart) {
            updateVideoState(videoToUpload.id, {error: true});

            break;
          } else if (partRetryCount <= 5) {
            console.log('RETRYING PART NUMBER: ', partNumber);
            partsToUpload.push(partNumber);
            retryCounts.set(partNumber, partRetryCount);
          }
        }
      }

      if (completedParts.length === numChunks) {
        //S3 requires the parts to be in order
        completedParts.sort((a,b) => a.PartNumber - b.PartNumber);

        await completeSiteWalkMultipartUpload(siteWalkId, videoS3Key, uploadId, completedParts);

        const uploadedVideoData = {stitched_video_s3_key: videoS3Key};

        if (siteWalk.video?.sub_id) {
          await updateSiteWalkVideoEntry(siteWalkId, siteWalk.video.sub_id, uploadedVideoData);
        } else {
          await createSiteWalkVideoEntry(siteWalkId, uploadedVideoData);
        }

        addNotification(`${videoToUpload.videoFile.name} uploaded successfully`, 'success');
      }
    }
  }

  const uploadPendingWalks = async (pendingSiteWalks: SiteWalkUploadData[]) => {
    setUploadInProgress(true);

    for (let i=0; i<pendingSiteWalks.length; i++) {
      const walkToUpload = pendingSiteWalks[i];

      await uploadSiteWalkVideo(walkToUpload);
    };

    const newlyAddedVideos = [...newBatch.current];

    if (newlyAddedVideos.length > 0) {
      newBatch.current = [];

      await uploadPendingWalks(newlyAddedVideos);
    }

    setUploadInProgress(false);
  };

  const onDrop = (files: FileList) => {
    handleAddFiles(files);
  }

  const onClickUploadMore = async () => {
    setVideosToUpload([]);

    await fetchSiteWalksNotUploaded();
  }

  const onSelectUnassociatedSiteWalk = (uploadData: SiteWalkUploadData, selectedWalk: Sitewalk) => {
    setUnassociatedVideos(prevVideos => {
      return prevVideos.map(video => {
        return {
          ...video,
          siteWalk: video.id === uploadData.id ? selectedWalk : video.siteWalk,
        }
      });
    })
  };

  const onClickUploadUnassociatedWalk = (uploadData: SiteWalkUploadData) => {
    setUnassociatedVideos(prevVideos => prevVideos.filter(video => video.id !== uploadData.id));
    setVideosToUpload(prevVideos => [...prevVideos, uploadData]);

    if (!uploadInProgress) {
      uploadPendingWalks([uploadData]);
    } else {
      newBatch.current = [...newBatch.current, uploadData];
    }
  }

  const onClickRemoveUnassociatedWalk = (uploadData: SiteWalkUploadData) => {
    setUnassociatedVideos(prevVideos => prevVideos.filter(video => video.id !== uploadData.id));
  }

  const onCreateNewSiteWalk = (createdSiteWalk: Sitewalk) => {
    addNotification("Site walk created successfully", "success");

    setSiteWalks(prevWalks => [createdSiteWalk, ...prevWalks]);
    const updatedUnassociatedVideos = [...unassociatedVideos];

    console.log("UNASSOCIATED: ", updatedUnassociatedVideos);

    for(let i=0; i<updatedUnassociatedVideos.length; i++) {
      const unassociatedVideo = updatedUnassociatedVideos[i];

      console.log("checking video: ", unassociatedVideo);

      if (!unassociatedVideo.siteWalk) {
        unassociatedVideo.siteWalk = createdSiteWalk;
        break;
      }
    }

    setUnassociatedVideos(updatedUnassociatedVideos);
  }

  const onRetryFailedUploads = () => {
    const videosToRetry = [...failedUploads];
    const videoIdsToRetry = new Set(videosToRetry.map(walk => walk.id));

    setVideosToUpload(prevVideos => {
      return prevVideos.map(video => {
        if (videoIdsToRetry.has(video.id)) {
          return {
            ...video,
            error: false,
            initiated: false,
          }
        } else {
          return video;
        }
      });
    })

    if (!uploadInProgress) {
      uploadPendingWalks(videosToRetry);
    } else {
      newBatch.current = [...newBatch.current, ...videosToRetry];
    }
  }

  return (
    <SiteWalkVideoUploadContainer>
      {!displayTables &&
        <DragAndDrop onDrop={onDrop}>
          <FileInputContainer>
            <FileInputLabel
              htmlFor="site-walk-video-input"
            >
              <FileInputLabelContent>
                <div>Drag and drop site walk video files</div>
                <SelectFilesContainer>
                  <AddProjectButton
                    text="SELECT FILES"
                    buttonStyle={{
                      background: '#073c7a',
                      color: 'white'
                    }}
                  />
                </SelectFilesContainer>
                <input
                  id="site-walk-video-input"
                  type="file"
                  multiple
                  onChange={onSelectFiles}
                  style={{display: 'none'}}
                />
              </FileInputLabelContent>
            </FileInputLabel>
          </FileInputContainer>
        </DragAndDrop>
      }

      {displayTables &&
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {videosToUpload.length > 0 && 
            <SiteWalkVideoUploadTable
              walks={videosToUpload}
            />
          }
          {unassociatedVideos.length > 0 &&
            <UnassociatedVideosTable
              uploadData={unassociatedVideos}
              siteWalksPendingUpload={siteWalks}
              onSelectSiteWalk={onSelectUnassociatedSiteWalk}
              onClickUpload={onClickUploadUnassociatedWalk}
              onRemoveSiteWalk={onClickRemoveUnassociatedWalk}
              onCreateNewSiteWalk={onCreateNewSiteWalk}
            />
          }
          <div style={{display: 'flex', gap: '10px'}}>
            {failedUploads.length > 0 &&
              <AddProjectButton
                text="Retry Failed Uploads"
                onClick={onRetryFailedUploads}
              />
            }
            {allVideosComplete && 
              <AddProjectButton
                text="Upload More"
                buttonStyle={{
                  background: '#073c7a',
                  color: 'white'
                }}
                onClick={onClickUploadMore}
              />
            }
          </div>
        </div>
      }
    </SiteWalkVideoUploadContainer>
  )
}

const SiteWalkVideoUploadContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: calc(100% - 150px);
`;

const FileInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FileInputLabel = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SelectFilesContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const FileInputLabelContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  height: 350px;
  border-radius: 10px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;