import styled from "styled-components";
import iconExpandGray from "../../../../../assets/images/icon_expand_gray.svg"
import { Icon } from "../../../../common/Icon";
import { SafetyMetricTileGraph } from "./SafetyMetricTileGraph";
import { LoadingIndicator } from "../../../../common/LoadingIndicator";
import { SafetyMetricMetadataEntry, SafetyMetricSlug } from "../../../../../api/safety";
import { useEffect, useMemo, useRef } from "react";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { useBuildingContext } from "../../../../../contexts/buildingContext";
import { useGenerateMetricGraph } from "../../hooks/useGenerateMetricGraph";

interface SafetyMetricTileProps {
  metric: SafetyMetricMetadataEntry;
  slug: SafetyMetricSlug;
  index: number;
  totalTilesCount: number;
  onClickTile?: () => void;
  onClickExpand?: () => void;
  selected?: boolean;
}

const tilesPerRow = 3;
const defaultMargin = 5;

const formatVerdict = (value: number) => {
  const percentage = (value * 100).toFixed(1);
  const roundedValue = parseFloat(percentage)

  if (roundedValue > 0) {
    return `+${percentage}%`;
  } else if (roundedValue < 0) {
    return `${percentage}%`;
  } else {
    return "No Change";
  }
};

export const SafetyMetricTile = ({
  metric,
  slug,
  index,
  totalTilesCount,
  onClickTile,
  onClickExpand,
  selected=false,
}: SafetyMetricTileProps) => {
  const {state: buildingState} = useBuildingContext();
  const {state: safetyState, updateSafetyMetricGraphs} = useSafetyContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const metricSummaryLoading = safetyState.metricSummaryLoadingStatus[slug];
  const metricSummary = safetyState.metricSummary[slug];
  const metricProjectSummary = metric.getProjectSummary(metricSummary, buildingState.projectId);
  
  const graph = useGenerateMetricGraph(slug);

  const displayExpandIcon = !!onClickExpand && !metricSummaryLoading && !!metricProjectSummary;

  useEffect(() => {
    updateSafetyMetricGraphs({
      [slug]: graph
    })
  }, [graph, slug, updateSafetyMetricGraphs]);

  const onClickExpandIcon = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClickExpand) {
      onClickExpand();
    }
  }

  const verdict = useMemo(() => {
    if (graph && graph.length > 0) {
      const lastEntry = graph[graph.length - 1];
      const secondToLastEntry = graph[Math.max(0, graph.length - 2)];
      const change = lastEntry.value - secondToLastEntry.value;

      return formatVerdict(change);
    }

    return "N/A"
  }, [graph]);

  const data = useMemo(() => {
    if (graph && graph.length > 0) {
      const lastEntry = graph[graph.length - 1];

      return `${(lastEntry.value * 100).toFixed(1)}%`;
    }

    return 'N/A';    
  }, [graph]);

  return (
    <SafetyMetricTileContainer
      ref={containerRef}
      onClick={() => {
        if (metricProjectSummary && onClickTile) {
          onClickTile();
        }
      }}
      selected={selected}
    >
      <MetricName>
        {metric.metric_tile_name}
      </MetricName>
      {metricSummaryLoading &&
        <LoadingIndicator
          containerStyle={{
            height: containerRef.current ? containerRef.current.clientHeight - 72 : 115
          }}
        />
      }
      {!metricSummaryLoading &&
        <>      
          <MetricVerdict
            style={{
              color: metric.color,
            }}
          >
            {verdict}
          </MetricVerdict>
          <MetricData>
            {data}
          </MetricData>

          {(!graph || graph.length <= 1) &&
            <div
              style={{
                height: containerRef.current ? containerRef.current.clientHeight - 152 : 35
              }}
            />
          }


          {(!!graph && graph.length > 1) &&
            <SafetyMetricTileGraph
              data={graph}
              displayColor={metric.color}
              height={containerRef.current ? containerRef.current.clientHeight - 152 : 35}
              tooltipProps={{
                wrapperStyle: {
                  top: index >= tilesPerRow ? '-20px' : undefined,
                }
              }}
            />
          }
        </>
      }
      {!displayExpandIcon && 
        <div
          style={{
            height: '20px',
            marginTop: '5px'
          }}
        />
      }
      {displayExpandIcon &&
        <Icon
          icon={iconExpandGray}
          size={20}
          style={{
            marginTop: '5px',
            cursor: 'pointer',
            alignSelf: 'flex-end'
          }}
          onClick={onClickExpandIcon}
        />
      }
    </SafetyMetricTileContainer>
  );
}

const SafetyMetricTileContainer = styled.div<{
  selected: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: calc(${100 / tilesPerRow}% - 7px);
  height: calc(50% - 5px);
  padding: 5px;
  background-color: white;
  ${props => props.selected && `box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);`}
  transition: box-shadow 0.5s;
`;

const MetricName = styled.div`
  font-family: Frutiger LT;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #323232;
`;

const MetricVerdict = styled.div`
  font-family: Frutiger LT;
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
`;

const MetricData = styled.div`
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 500;
  line-height: 47px;
`;