import classNames from 'classnames';
import React from 'react';
import styled from 'styled-components';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { blue, gray, pink } from '../../../../../styles/colors';
import { MapPointContainer, MapPointMainCircle } from '../../../../common/MapViewer';
import { useManagePointsContext } from './ManagePointsContext';

export interface IMapPoint {
  has_image: boolean;
  images?: any[];
  is_active: boolean;
  point_id: number;
  x: string;
  y: string;
  project_floor_section: number | null;
}

interface IManagedMapPointProps {
  x: number;
  y: number;
  point: IMapPoint;
  onClick?: (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => void;
  onMouseDown?: (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => void;
  onMouseMove?: (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => void;
  onMouseUp?: (e: React.MouseEvent<HTMLDivElement>, point: IMapPoint) => void;
}

export const ManagedMapPoint = ({
  point,
  x,
  y,
  onClick,
  onMouseDown,
  onMouseMove,
  onMouseUp,
}: IManagedMapPointProps) => {
  const {
    state: buildingState
  } = useBuildingContext();

  const {
    canSelectPoints,
  } = useManagePointsContext();

  const pointIsSelected: boolean = buildingState.floorData.selectedPoints.has(point.point_id);
  const pointIsActive: boolean = point.is_active;
  const inCurrentGroup: boolean = buildingState.floorData.groupPoints.has(point.point_id);
  const pointHasImages: boolean = point.has_image;
  const hexagonType: string = (pointIsSelected || inCurrentGroup) ? '\u2B22' : '\u2B21';

  return (
    <MapPointContainer
      className={`map-point mp-${point.point_id}`}
      x={x}
      y={y}
      isActive={canSelectPoints}
      onClick={e => {
        if (onClick) {
          onClick(e, point);
        }
      }}
      onMouseDown={e => {
        if (onMouseDown) {
          onMouseDown(e, point);
        }
      }}
      onMouseMove={e => {
        if (onMouseMove) {
          onMouseMove(e, point);
        }
      }}
      onMouseUp={e => {
        if (onMouseUp) {
          onMouseUp(e, point);
        }
      }}
    >
      { !pointHasImages &&
        <MapPointMainCircle
          className={classNames({
            back_pink:  pointIsSelected && pointIsActive,
            back_pink_empty: !pointIsSelected && pointIsActive,
            back_gray: pointIsSelected && !pointIsActive,
            back_gray_empty: !pointIsSelected && !pointIsActive,
            back_blue: inCurrentGroup,
          })}
        />      
      }
      { pointHasImages &&
        <PointWithPicture
          className={classNames({
            back_pink:  pointIsActive,
            back_gray: !pointIsActive,
            back_blue: inCurrentGroup,
          })}
          
        >
          {hexagonType}
        </PointWithPicture>
      }
    </MapPointContainer>
  )
}

const PointWithPicture = styled.div`
  position: absolute;
  font-size: 55px;
  font-weight: bold;
  transform: translate(-50%, -50%);
  box-sizing: content-box;

  &.back_pink {
    color: ${pink};
  }
  
  &.back_gray {
    color: ${gray};
  }

  &.back_blue {
    color: ${blue};
  }
`