import { CheckNameDelete } from "../../../common/ViewSelector/components/PointGroupSelector/CheckNameDelete"

export interface RejectSiteWalkReasonData {
  name: string;
  property: 'wrong_location' | 'brightness_issue' | 'blurriness_issue';
  checked: boolean;
}

interface RejectSiteWalkImageReasonsProps {
  checkboxes: RejectSiteWalkReasonData[];
  onChangeChecked: (propertyName: string, checked: boolean) => void;
}

export const RejectSiteWalkImageReasons = ({
  checkboxes,
  onChangeChecked,
}: RejectSiteWalkImageReasonsProps) => {
  return (
    <>
      {checkboxes.map(checkboxData => {
        return (
          <CheckNameDelete
            key={checkboxData.property}
            name={checkboxData.name}
            checked={checkboxData.checked}
            onChangeChecked={checked => onChangeChecked(checkboxData.property, checked)}
            checkmarkPosition={{left: '4px'}}
          />
        );
      })}
    </>
  )
}