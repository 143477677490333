import React from 'react';
import { useBuildingContext } from '../../../../../contexts/buildingContext';
import { blue, gray, pink } from '../../../../../styles/colors';
import { IBoundaryPoint, INoGoZone } from './ManageNoGoZones';
import { NoGoZoneCircle } from './NoGoZoneCircle';
import { useNoGoZoneContext } from './NoGoZoneContext';

interface IBoundaryPointProps {
  point: IBoundaryPoint;
  zone: INoGoZone;
}

export const BoundaryPoint = ({
  point,
  zone,
}: IBoundaryPointProps) => {  
  const {
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    pointDraggingOccurred,
    svgDraggingOccurred,
    setCurrentDraggingPointId,
    hoverNoGoZone,
    canEditZones,
  } = useNoGoZoneContext();

  const pointIsSelected: boolean = buildingState.floorData.selectedPoints.has(point.id);
  const color = (pointIsSelected || point.zone_id === hoverNoGoZone) ? blue : zone.is_active ? pink : gray;

  const onDragStart = (e: React.FormEvent) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (!canEditZones) {
      return;
    }

    setCurrentDraggingPointId(point.id);
  }

  const onDragEnd = () => {
    if (!canEditZones) {
      return;
    }

    setCurrentDraggingPointId(null);
  }

  const selectPoint = () => {
    if (canEditZones && !pointDraggingOccurred && !svgDraggingOccurred) {      
      const selectedPoints = new Set(buildingState.floorData.selectedPoints);
      const selectedNoGoZones = new Set(buildingState.floorData.selectedNoGoZones);

      if (pointIsSelected) {
        selectedPoints.delete(point.id);
        selectedNoGoZones.delete(point.zone_id);
      } else {
        selectedPoints.add(point.id);
      }

      const everyPointSelected: boolean = zone.points.every((point: IBoundaryPoint) => selectedPoints.has(point.id));

      if (everyPointSelected) {
        selectedNoGoZones.add(point.zone_id);
      }
  
      updateFloor({
        selectedPoints,
        selectedNoGoZones
      });
    }
  }

  const onTouchEnd = () => {
    onDragEnd();
    selectPoint();
  }

  return (
    <NoGoZoneCircle
      stroke={color}
      fill={color}
      x={point.x}
      y={point.y}
      onClick={selectPoint}
      onMouseDown={onDragStart}
      onMouseUp={onDragEnd}
      onTouchStart={onDragStart}
      onTouchEnd={onTouchEnd}
    />
  )
}