import React, { createContext, useCallback, useContext, useEffect, useMemo, useReducer } from "react";
import { useProcoreCalls, verifyProcoreToken } from "../api/procore";

interface ProcoreAuthState {
  isAuthenticated: boolean;
  authenticationCheckComplete: boolean;
  canCreateRfis: boolean;
  canReadRfis: boolean;
  canUploadImages: boolean;
}

const initialProcoreAuthState: ProcoreAuthState = {
  isAuthenticated: false,
  authenticationCheckComplete: false,
  canCreateRfis: false,
  canReadRfis: false,
  canUploadImages: false,
}

interface ProcoreContext {
  state: ProcoreAuthState;
  dispatch: React.Dispatch<Partial<ProcoreAuthState>>;
};

const initialProcoreAuthContext: ProcoreContext = {
  state: {...initialProcoreAuthState},
  dispatch: () => ({...initialProcoreAuthState}),
}

const ProcoreAuthContext = createContext<ProcoreContext>({...initialProcoreAuthContext});

const procoreAuthReducer = (state: ProcoreAuthState, action: Partial<ProcoreAuthState>) => {
  return {
    ...state,
    ...action,
  }
}

export const ProcoreAuthProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(procoreAuthReducer, initialProcoreAuthState);
  const { showProcorePermissionManifest } = useProcoreCalls();

  const runAuthCheck = useCallback(async () => {
    dispatch({authenticationCheckComplete: false});

    const stateToUpdate: Partial<ProcoreAuthState> = {};
    
    try {
      await verifyProcoreToken();
      const userPermissions = await showProcorePermissionManifest();

      stateToUpdate.isAuthenticated = true;
      
      userPermissions.forEach(permission => {
        if (permission.name === 'rfi') {
          stateToUpdate.canCreateRfis = !!permission.can_create;
          stateToUpdate.canReadRfis = permission.available_for_user;
        } else if (permission.name === 'images') {
          stateToUpdate.canUploadImages = permission.available_for_user;
        }
      });
    } catch (err) {
      stateToUpdate.isAuthenticated = false;
      stateToUpdate.canCreateRfis = false;
      stateToUpdate.canReadRfis = false;
      stateToUpdate.canUploadImages = false;
    } finally {
      stateToUpdate.authenticationCheckComplete = true;
      dispatch(stateToUpdate);
    }
  }, [showProcorePermissionManifest]);

  useEffect(() => {
    runAuthCheck();
  }, [runAuthCheck]);

  const value = useMemo(() => {
    return {
      state,
      dispatch,
    }
  }, [state]);

  return (
    <ProcoreAuthContext.Provider value={value}>
      {children}
    </ProcoreAuthContext.Provider>
  )
}

export const useProcoreAuthContext = () => {
  return useContext(ProcoreAuthContext);
};