import { User } from './api/types';
import { ViewerPosition } from './types';
import * as THREE from 'three';

export function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getUserInitials = (user: User) => {
  if (user.first_name && user.last_name) {
    return (
      user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase()
    );
  } else {
    return user.email.substring(0, 2).toUpperCase();
  }
};

export const getFullName = <T extends { first_name: string; last_name: string }>(user: T) => {
  return user.first_name + ' ' + user.last_name;
};

export const truncateMessage = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...';
  } else {
    return str;
  }
};

const componentToHex = (c: number) => {
  const hex = c.toString(16);
  return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (r: number, g: number, b: number) => {
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;
};

const hexToRgb = (hex: string) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
    : [0, 0, 0];
};

const interpolate = (start: number, end: number, factor: number) => {
  return Math.round(start + (end - start) * factor);
};

export const interpolateColor = (startColor: string, endColor: string, factor: number): string => {
  if (factor < 0 || factor > 1) {
    throw new Error('Factor must be between 0 and 1');
  }

  const [startR, startG, startB] = hexToRgb(startColor);
  const [endR, endG, endB] = hexToRgb(endColor);

  const r = interpolate(startR, endR, factor);
  const g = interpolate(startG, endG, factor);
  const b = interpolate(startB, endB, factor);

  return rgbToHex(r, g, b);
};

export const generateGradientFunction =
  (minColor: string, minValue: number, maxColor: string, maxValue: number) => (value: number) => {
    value = Math.max(minValue, Math.min(maxValue, value));
    const minColorComponents = hexToRgb(minColor);
    const maxColorComponents = hexToRgb(maxColor);

    const gradient = minColorComponents.map((channel, index) => {
      const difference = maxColorComponents[index] - channel;
      return channel + (difference / (maxValue - minValue)) * (value - minValue);
    });

    const hex = gradient.map(channel => {
      const hexChannel = Math.round(channel).toString(16);
      return hexChannel.length === 1 ? '0' + hexChannel : hexChannel;
    });

    return '#' + hex.join('');
  };

export const normalizeYaw = (yaw: number): number => {
  const normalizedYaw = yaw % 360;
  return normalizedYaw < 0 ? normalizedYaw + 360 : normalizedYaw;
};

export function normalizePanoramaAngle(angle: number): number {
  let normalizedAngle = angle % 360;
  if (normalizedAngle <= -180) {
    normalizedAngle += 360;
  } else if (normalizedAngle > 180) {
    normalizedAngle -= 360;
  }
  return normalizedAngle;
}

export function normalizeViewerPosition(viewerPosition: ViewerPosition): ViewerPosition {
  return {
    pitch: normalizePanoramaAngle(viewerPosition.pitch),
    yaw: normalizePanoramaAngle(viewerPosition.yaw),
    hfov: viewerPosition.hfov,
  };
}

export function applyViewerPositionOffset(
  viewerPosition: ViewerPosition,
  angleOffset: number
): ViewerPosition {
  return {
    pitch: viewerPosition.pitch,
    yaw: viewerPosition.yaw + angleOffset,
    hfov: viewerPosition.hfov,
  };
}

export function calculateViewerPositionDelta(
  pos1: ViewerPosition,
  pos2: ViewerPosition
): ViewerPosition {
  return {
    yaw: pos2.yaw - pos1.yaw,
    pitch: pos2.pitch - pos1.pitch,
    hfov: pos2.hfov - pos1.hfov,
  };
}

export function applyViewerPositionDelta(
  pos: ViewerPosition,
  delta: ViewerPosition,
  ignoreHfov: boolean = true
): ViewerPosition {
  return {
    yaw: pos.yaw + delta.yaw,
    pitch: pos.pitch + delta.pitch,
    hfov: ignoreHfov ? pos.hfov : pos.hfov + delta.hfov,
  };
}

export function reverseViewerPositionDelta(
  pos: ViewerPosition,
  delta: ViewerPosition,
  ignoreHfov: boolean = true
): ViewerPosition {
  return {
    yaw: pos.yaw - delta.yaw,
    pitch: pos.pitch - delta.pitch,
    hfov: ignoreHfov ? pos.hfov : pos.hfov - delta.hfov,
  };
}

export function isAlmostEqual(num1: number, num2: number, epsilon: number = 0.000001): boolean {
  return Math.abs(num1 - num2) < epsilon;
}

export function areAnglesAlmostEqual(
  angle1: number,
  angle2: number,
  epsilon: number = 0.000001
): boolean {
  const diff = Math.abs(angle1 - angle2);
  return diff <= epsilon || Math.abs(diff - 360) <= epsilon;
}

export function areViewerPositionsAlmostEqual(
  pos1: ViewerPosition,
  pos2: ViewerPosition,
  epsilon: number = 0.000001
): boolean {
  const normalized1 = normalizeViewerPosition(pos1);
  const normalized2 = normalizeViewerPosition(pos2);
  return (
    areAnglesAlmostEqual(normalized1.yaw, normalized2.yaw, epsilon) &&
    areAnglesAlmostEqual(normalized1.pitch, normalized2.pitch, epsilon) &&
    isAlmostEqual(normalized1.hfov, normalized2.hfov, epsilon)
  );
}

export function convertViewerPositionToTarget(
  position: THREE.Vector3,
  viewerPosition: { yaw: number; pitch: number; hfov: number },
  distance: number = 50000 // default distance to the target
): THREE.Vector3 {
  // Convert yaw and pitch to radians
  const yawRadians = viewerPosition.yaw * (Math.PI / 180);
  const pitchRadians = viewerPosition.pitch * (Math.PI / 180);

  // Calculate the forward direction vector based on yaw and pitch
  const target = new THREE.Vector3();

  // X and Y direction varies with yaw and pitch
  target.x = position.x + distance * Math.cos(pitchRadians) * Math.sin(yawRadians);
  target.y = position.y + distance * Math.cos(pitchRadians) * Math.cos(yawRadians);

  // Z direction varies with pitch (up and down movement)
  target.z = position.z + distance * Math.sin(pitchRadians);

  return target;
}

export function convertTargetToViewerPosition(
  position: THREE.Vector3,
  target: THREE.Vector3,
  hfov: number = 60 // default hfov
): { yaw: number; pitch: number; hfov: number } {
  // Calculate the direction vector from position to target
  const direction = new THREE.Vector3().subVectors(target, position);

  // Calculate yaw (rotation around the Y-axis, within the XZ plane)
  // Negate the result to fix the yaw sign
  const yaw = -Math.atan2(-direction.x, direction.y) * (180 / Math.PI);

  // Calculate pitch (rotation around the X-axis, up/down)
  const distanceXY = Math.sqrt(direction.x * direction.x + direction.y * direction.y);
  const pitch = Math.atan2(direction.z, distanceXY) * (180 / Math.PI);

  return { yaw, pitch, hfov };
}
