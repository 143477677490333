import { useSafetyContext } from "../../../../contexts/safetyContext";
import { monthAbbreviations } from "../../progress/ProgressOverview/ProgressOverview";
import { CustomTooltip, CustomTooltipLabel, CustomTooltipValue } from "../../progress/components/ProgressCharts/CustomTooltip";

interface ISafetyGraphTooltipProps {
  active?: boolean;
  label?: string;
  payload?: any[];
}

export const SafetyGraphTooltip = ({
  active,
  label,
  payload,
}: ISafetyGraphTooltipProps) => {
  const {state: safetyState} = useSafetyContext();

  const generateWeeklyTooltipLabel = (label: number) => {
    const startDate = new Date(label);
    const endDate = new Date(new Date(startDate).setDate(startDate.getDate() + 6));

    const startDateFormatted = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
    const endDateFormatted = `${endDate.getMonth() + 1}/${endDate.getDate()}`;
  
    return `${startDateFormatted} - ${endDateFormatted}`;
  }

  const generateTooltipLabel = (label: number) => {
    if (safetyState.dateSubdivision === 'monthly') {
      return safetyGraphXAxisTickFormatter(label);
    } else {
      return generateWeeklyTooltipLabel(label);
    }
  }

  if (active && payload && payload.length && label) {
    const tooltipLabel = generateTooltipLabel(parseInt(label));
    const payloadEntry = payload[0].payload;

    return (
      <CustomTooltip>
        <CustomTooltipLabel>{tooltipLabel}</CustomTooltipLabel>
        <CustomTooltipValue>
          {`${(payloadEntry.value * 100).toFixed(1)}%`}
        </CustomTooltipValue>
      </CustomTooltip>
    );
  }

  return <></>;
}

export const safetyGraphXAxisTickFormatter = (tick: number) => {
  const tickAsDate = new Date(tick);

  const tickFormatted = `${monthAbbreviations[tickAsDate.getMonth()]} ${tickAsDate.getFullYear()}`;

  return tickFormatted;
};