import moment from "moment";
import React, { useReducer, useState } from "react";
import styled from "styled-components";
import { createNewSchedule, createSchedulePointMapping, saveSchedule } from "../../../../../../api/adminBuildingFetches";
import { useBuildingContext } from "../../../../../../contexts/buildingContext";
import { useNotifications } from "../../../../../../contexts/notificationProvider";
import { useNavigation } from "../../../../../../hooks/useNavigation";
import { ConfirmationModal } from "../../../../../common/Confirmation/Confirmation";
import { commonInputStyles, invalidInputStyle, ScheduleControlsDatePicker } from './components/ScheduleControlsDatePicker';
import iconDown from '../../../../../../assets/images/icon_down_gray.svg';
import { ScheduleControlsStatistics } from "./components/ScheduleControlsStatistics";
import { ScheduleControlsScanInformation } from "./components/ScheduleControlsScanInformation";
import { getNextScan, getTimePieces } from "../ScheduleHelperFunctions";
import { useManageSchedulesContext } from "../ManageSchedulesContext";

export enum Frequency {
  never = 'Frequency',
  once = 'once',
  weekly = 'weekly',
  monthly = 'monthly'
}

const formatDateForSubmit = (date: Date | undefined): string => {
  return moment(date).format('YYYY-MM-DD');
}

interface FieldValidations {
  name: boolean;
  start_date: boolean;
  till_date: boolean;
  time: boolean;
  frequency: boolean;
}

const initialFieldValidations = {
  name: true,
  start_date: true,
  till_date: true,
  time: true,
  frequency: true,
}

const fieldValidationsReducer = (state: FieldValidations, stateUpdates: Partial<FieldValidations>) => {
  return {...state, ...stateUpdates};
}

interface IScheduleControlsProps {
  setScheduleSaving: (newValue: boolean) => void;
}

export const ScheduleControls = ({
  setScheduleSaving,
}: IScheduleControlsProps) => {
  const {
    updateSchedule,
    state: buildingState
  } = useBuildingContext();

  const {
    canCreateSchedules,
    canEditSchedules,
  } = useManageSchedulesContext();

  const { navigateToManageSchedules } = useNavigation();

  const { addNotification } = useNotifications();

  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [confirmationMessage, setConfirmationMessage] = useState<string>('');
  const [fieldValidations, updateFieldValidations] = useReducer(fieldValidationsReducer, initialFieldValidations);

  const { scheduleData } = buildingState;
  const { name, start_date, till_date, time, frequency, id } = scheduleData;
  const { lessThan24HoursAway: nextScanLessThan24HoursAway, isExpired: scanExpired } = getNextScan(frequency, start_date, till_date, time);

  const isNewSchedule = id === 'new';

  const canEditScheduleFields = isNewSchedule ? canCreateSchedules : canEditSchedules;

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSchedule({
      name: e.target.value
    });

    updateFieldValidations({
      name: true,
    });
  }

  const onChangeFrequency = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSchedule({
      frequency: e.target.value
    });

    updateFieldValidations({
      frequency: true,
    });
  }

  const onChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedStartDate = start_date;
    const updatedTillDate = till_date;
    let updatedTime = e.target.value;

    if (updatedTime) {
      updateFieldValidations({
        time: true,
      });

      const timePieces = getTimePieces(updatedTime);

      updatedStartDate.setHours(timePieces[0], timePieces[1]);
      updatedTillDate.setHours(timePieces[0], timePieces[1]);
    }

    updateSchedule({
      time: updatedTime,
      start_date: updatedStartDate,
      till_date: updatedTillDate
    });
  }

  const onSaveSchedule = async () => {
    const saveDisabled = !name || !start_date || !till_date || !frequency || frequency === Frequency.never || !time || nextScanLessThan24HoursAway;

    if (saveDisabled) {
      if (scanExpired) {
        setConfirmationMessage(`Cannot ${isNewSchedule ? 'create' : 'save'} a schedule that is expired`);
      } else if (nextScanLessThan24HoursAway) {
        setConfirmationMessage(`Cannot ${isNewSchedule ? 'create' : 'save'} a schedule for less than 24 hours from now`);
      } else {
        setConfirmationMessage(`Please fill all fields to ${isNewSchedule ? 'create new' : 'save'} schedule`);
      }
      
      setConfirmation(true);

      updateFieldValidations({
        name: !!name,
        start_date: !!start_date,
        till_date: !!till_date,
        frequency: !!frequency && frequency !== Frequency.never,
        time: !!time,
      });
      
      return;
    }

    const formData = {
      id,
      name,
      frequency,
      start_date: formatDateForSubmit(start_date),
      till_date: formatDateForSubmit(till_date),
      time
    }

    if (isNewSchedule) {
      try {
        setScheduleSaving(true);

        const createdSchedule = await createNewSchedule(buildingState.projectId, formData);
        
        const selectedPoints: Set<number> = buildingState.floorData.selectedPoints;

        const pointMappingsPromises = Array.from(selectedPoints).map((pointId: number) => {
          return createSchedulePointMapping(buildingState.projectId, createdSchedule.id, pointId);
        });

        Promise.all(pointMappingsPromises).then(() => {
          setScheduleSaving(false);
          navigateToManageSchedules(buildingState.floorId, createdSchedule.id);
          addNotification('Schedule created successfully', 'success');
        });
      } catch (err) {
        setScheduleSaving(false);
        console.log('createNewSchedule==>>', err);
        addNotification('Error creating new schedule', 'error');
      }
    } else {
      try {
        const savedSchedule = await saveSchedule(buildingState.projectId, formData);
        
        updateSchedule(savedSchedule);
        addNotification('Schedule saved successfully', 'success');
      } catch (err) {
        console.log('saveSchedule==>>', err);
        addNotification('Error saving schedule', 'error');
      }
    }    
  }

  return (
    <ScheduleControlsContainer>
      <InputContainer>
        <NameInput
          placeholder="Name"
          value={name}
          onChange={onChangeName}
          valid={fieldValidations.name}
          disabled={!canEditScheduleFields}
        />
      </InputContainer>
      <InputContainer
        style={{position: 'relative'}}
      >
        <CustomDropdownArrow/>
        <FrequencyDropdown
          value={frequency}
          onChange={onChangeFrequency}
          valid={fieldValidations.frequency}
          disabledStyle={!canEditScheduleFields}
        >
          <option disabled hidden value={Frequency.never}>{Frequency.never}</option>
          <EnabledFrequencyOption value={Frequency.once}>{Frequency.once}</EnabledFrequencyOption>
          <EnabledFrequencyOption value={Frequency.weekly}>{Frequency.weekly}</EnabledFrequencyOption>
          <EnabledFrequencyOption value={Frequency.monthly}>{Frequency.monthly}</EnabledFrequencyOption>
        </FrequencyDropdown>
      </InputContainer>
      
      <InputContainer>
        <ScheduleControlsDatePicker
          valid={fieldValidations.start_date && fieldValidations.till_date}
          disabled={!canEditScheduleFields}
        />
      </InputContainer>
      
      <InputContainer>
        <TimeInput
          value={time}
          onChange={onChangeTime}
          valid={fieldValidations.time}
          disabled={!canEditScheduleFields}
        />
      </InputContainer>

      { canEditScheduleFields &&      
        <SaveButton
          onClick={onSaveSchedule}
        >
          { isNewSchedule ? 'Create Schedule' : 'Save' }
        </SaveButton>
      }

      <ScheduleControlsStatistics/>

      <ScheduleControlsScanInformation/>

      <ConfirmationModal
        hideCancelButton
        confirmButtonAltText='Ok'
        isOpen={confirmation}
        setIsOpen={setConfirmation}
        message={confirmationMessage}
      />
    </ScheduleControlsContainer>
  )
}

interface IScheduleControlsInputProps {
  valid: boolean;
  disabledStyle?: boolean;
}

const ScheduleControlsContainer = styled.div`
 padding: 16px 16px 16px 12px;
`

const InputContainer = styled.div`
  margin-bottom: 12px;
`

const NameInput = styled.input<IScheduleControlsInputProps>`
  ${commonInputStyles}
  ${props => !props.valid ? invalidInputStyle : ''}

  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  }
`;

const TimeInput = styled.input.attrs({ type: 'time' })<IScheduleControlsInputProps>`
  ${commonInputStyles}
  padding: 5px 10px;
  ${props => !props.valid ? invalidInputStyle : ''}

  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  }
`;

export const StyledSelect = styled.select`
  ${commonInputStyles}
  &:disabled {
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  }
`;

const FrequencyDropdown = styled.select<IScheduleControlsInputProps>`
  ${commonInputStyles}
  background: transparent;
  appearance: none;
  ${props => !props.valid ? invalidInputStyle : ''}
  ${props => props.value === Frequency.never ? 'color: gray;' : ''}

  ${props => props.disabledStyle && `
    background-color: rgba(239, 239, 239, 0.3);
    color: rgb(84, 84, 84);
  `}
`;

const EnabledFrequencyOption = styled.option`
  color: black;
`;

const SaveButton = styled.div`
  border: 1px solid #073C7A;
  border-radius: 2px;
  font-size: 12px;
  line-height: 15.3px;
  font-weight: 700;
  color: #073C7A;
  padding: 8px 10px;
  text-align: center;
  cursor: pointer;
`;

export const CustomDropdownArrow = () => {
  return (
    <img
      src={iconDown} 
      style={{position: 'absolute', right: '14px', top: '10px', pointerEvents: 'none'}}
      alt='expand date picker'
    />
  )
}