import { useCallback, useMemo, useState } from "react";
import { Item } from "../../../../../api/items";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { ViewerPosition } from "../../../image_viewer/types";
import { SafetyFloorPlanView } from "../SafetyFloorPlanView";
import { ProjectTrackerObservation } from "../../../../../api/trackers";
import { CustomTooltipValue } from "../../../progress/components/ProgressCharts/CustomTooltip";
import { Job } from "../../../../../api/jobs";
import { useProjectFloorItemsQuery, useProjectFloorObservationsQuery, useProjectFloorProgressRegionsQuery, useProjectJobTypesQuery } from "../../../progress/hooks/progressTrackingQueries";
import { generateGradientFunction, interpolateColor } from "../../../../../utils";

interface SafetyWetSurfaceFloorPlanViewProps {
  viewerPosition?: ViewerPosition;
  minimapMode?: boolean;
}

const trackerName = "Wet Surface";

export const wetSurfaceGradient = (signal: number) => {
  return interpolateColor('#ABC0D9', '#073C7A', signal);
}

export const SafetyWetSurfaceFloorPlanView = ({
  viewerPosition,
  minimapMode=false,
}: SafetyWetSurfaceFloorPlanViewProps) => {
  const {state: safetyState} = useSafetyContext();

  const {observationDatePointImageMap} = safetyState;

  const {data: jobTypes, isLoading: jobTypesLoading} = useProjectJobTypesQuery(trackerName);
  const {data: items} = useProjectFloorItemsQuery(trackerName);
  const {data: progressRegions, isLoading: progressRegionsLoading} = useProjectFloorProgressRegionsQuery();
  const {data: observations, isLoading: observationsLoading} = useProjectFloorObservationsQuery(trackerName);

  const [tooltipItem, setTooltipItem] = useState<Item | null>(null);

  const dataLoaded = !!jobTypes && !jobTypesLoading && !!progressRegions && !progressRegionsLoading && !!observations && !observationsLoading;

  const jobTypeIds = useMemo(() => {
    if (jobTypes) {
      return jobTypes.map(type => type.job_type.id);
    }
    
    return [];
  }, [jobTypes]);

  const viewpointIdToItemMap = useMemo(() => {
    const map = new Map<number, Item>();

    if (items) {
      items.forEach(item => {
        map.set(item.viewpoint, item);
      });
    }

    return map;
  }, [items]);

  const itemObservationMap = useMemo(() => {
    const map = new Map<number, ProjectTrackerObservation>();

    if (observations) {
      observations.forEach(observation => {
        if (observationDatePointImageMap.has(observation.image) && !map.has(observation.item)) {
          map.set(observation.item, observation);
        }
      });
    }

    return map;
  }, [observationDatePointImageMap, observations]);

  const getRegionColor = useCallback((signal: number) => {
    const wetGradient = generateGradientFunction("#057AFB", 0, "#BADAFE", 0.85);
    const dryGradient = generateGradientFunction("#BADAFE", 0.85, "#FFFFFF", 1);

    if (signal < 0.85) {
      return wetGradient(signal);
    } else {
      return dryGradient(signal);
    }
  }, []);

  const jobs = useMemo(() => {
    if (progressRegions) {
      return progressRegions.map(region => {
        const item = viewpointIdToItemMap.get(region.viewpoint);
        const itemObservation = item ? itemObservationMap.get(item.id) : null;

        const displayColor = itemObservation ? getRegionColor(1 - itemObservation.signal) : "#808080";

        return {
          id: itemObservation ? itemObservation.image : -1,
          completed_units: itemObservation ? 1 - itemObservation.signal : 1,
          initial_observation_date: itemObservation ? itemObservation.registered_on : null,
          item: region as any,
          total_units: 1,
          type: {
            project: 0,
            job_type: {
              display_color: displayColor,
              display_shape: "area",
              display_order: 0,
              id: jobTypeIds[0],
              item_type: 1,
              name: "Wet Surface",
              secondary_display_color: null,
              slug: "wet_surface",
              tertiary_display_color: null,
              units: "SF"
            }
          },
          registered_on: "",
          last_modified_on: "",
          status: "",
          rate: 0,
        }
      });
    }

    return [];
  }, [getRegionColor, itemObservationMap, jobTypeIds, progressRegions, viewpointIdToItemMap]);

  const itemJobMap = useMemo(() => {
    const map = new Map<number, Job>();

    jobs.forEach(job => {
      map.set(job.item.id, job);
    });

    return map;
  }, [jobs]);

  const tooltipJob = useMemo(() => {
    if (tooltipItem) {
      return itemJobMap.get(tooltipItem.id);
    }

    return null;
  }, [itemJobMap, tooltipItem]);

  const TooltipValue = useMemo(() => {
    const color = tooltipJob ? "#073C7A" : "#808080";
    let message = "";

    if (tooltipJob && tooltipJob.id >= 0) {
      message = `${(tooltipJob.completed_units * 100).toFixed(1)}% Dry`
    } else {
      message = "No Measurement"
    }

    return (
      <CustomTooltipValue
        style={{
          color: color,
        }}
      >
        {message}
      </CustomTooltipValue>
    )
  }, [tooltipJob]);

  return (
    <SafetyFloorPlanView
      viewerPosition={viewerPosition}
      jobs={jobs}
      dataLoaded={dataLoaded}
      tooltipItem={tooltipItem}
      setTooltipItem={setTooltipItem}
      selectedJobTypeIds={jobTypeIds}
      TooltipValue={TooltipValue}
      minimapMode={minimapMode}
    />
  );
}