import { IconButton } from '../../../../../../common/IconButton';
import iconZoomIn from '../../../../../../../assets/images/icon_zoom_in.svg';
import iconZoomOut from '../../../../../../../assets/images/icon_zoom_out.svg';
import iconDownload from '../../../../../../../assets/images/icon_download.svg';
import iconShare from '../../../../../../../assets/images/icon_share.svg';
import iconSplit from '../../../../../../../assets/images/icon_vertical_split.svg';
import iconSplitDark from '../../../../../../../assets/images/icon_split_dark.svg';
import icon3DDark from '../../../../../../../assets/images/icon_3d_dark.svg';
import icon3DLight from '../../../../../../../assets/images/icon_3d_light.svg';
import { useNotifications } from '../../../../../../../contexts/notificationProvider';
import { saveAs } from 'file-saver';
import { ViewerPosition } from '../../../../types';
import { useImageViewerContext } from '../../../../imageViewerContext';
import ReactTooltip from 'react-tooltip';
import mixpanel from 'mixpanel-browser';
import { useBuildingContext } from '../../../../../../../contexts/buildingContext';
import { useCallback } from 'react';

interface ImageViewerSettingsProps {
  viewerPosition: ViewerPosition;
  zoomLevel: number;
  zoomMax: number;
  zoomMin: number;
  zoomStep: number;
  onZoomChanged(level: number): void;
  onTakeScreenshot(callback: (blob: Blob) => void): void;
}

export const ImageViewerSettings = ({
  viewerPosition,
  zoomLevel,
  zoomMax,
  zoomMin,
  zoomStep,
  onZoomChanged,
  onTakeScreenshot,
}: ImageViewerSettingsProps) => {
  const { addNotification } = useNotifications();
  const { state: ImageViewerState, updateImageViewer } = useImageViewerContext();
  const { state: BuildingState } = useBuildingContext();

  const handleDownloadClick = useCallback(() => {
    onTakeScreenshot((blob: Blob) => {
      saveAs(blob, 'screen.png');
    });
  }, [onTakeScreenshot]);

  const copyUrl = () => {
    // navigator clipboard api needs a secure context (https)
    let qpObj = {
      yaw: viewerPosition.yaw.toFixed(3),
      pitch: viewerPosition.pitch.toFixed(3),
      hfov: viewerPosition.hfov.toFixed(3),
    };
    let qp = new URLSearchParams(qpObj);
    mixpanel.track('Share Url');
    if (navigator.clipboard && window.isSecureContext) {
      // navigator clipboard api method'
      return navigator.clipboard.writeText(window.location.href.split('?')[0] + '?' + qp);
    } else {
      // text area method
      let textArea = document.createElement('textarea');
      textArea.value = window.location.href.split('?')[0] + '?' + qp;
      // make the textarea out of viewport
      textArea.style.position = 'fixed';
      textArea.style.left = '-999999px';
      textArea.style.top = '-999999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      return new Promise<void>((res, rej) => {
        // here the magic happens
        document.execCommand('copy') ? res() : rej();
        textArea.remove();
      });
    }
  };

  const handleZoomIn = () => {
    if (zoomLevel > zoomMin) {
      onZoomChanged(Math.max(zoomLevel - zoomStep, zoomMin));
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel < zoomMax) {
      onZoomChanged(Math.min(zoomLevel + zoomStep, zoomMax));
    }
  };

  return (
    <div style={{ position: 'fixed', zIndex: 3 }}>
      <IconButton
        icon={iconZoomIn}
        size={40}
        primary={true}
        disabled={zoomLevel == zoomMin}
        onClick={handleZoomIn}
        style={{ position: 'fixed', right: '40px', top: 'calc(50% - 22px)' }}
      />
      <IconButton
        icon={iconZoomOut}
        size={40}
        primary={true}
        disabled={zoomLevel == zoomMax}
        onClick={handleZoomOut}
        style={{ position: 'fixed', right: '40px', top: 'calc(50% + 22px)' }}
      />
      {!ImageViewerState.splitScreen ? (
        <>
          <IconButton
            icon={iconDownload}
            size={60}
            primary={false}
            onClick={handleDownloadClick}
            style={{ position: 'fixed', right: '40px', bottom: '100px' }}
            tooltip="Screenshot"
          />
          <IconButton
            onClick={() => {
              copyUrl().then(() => addNotification('URL copied to clipboard', 'success'));
            }}
            icon={iconShare}
            size={60}
            primary={false}
            style={{ position: 'fixed', right: '108px', bottom: '100px' }}
            tooltip="Share Url"
          />
          <IconButton
            onClick={() => {
              updateImageViewer({ splitScreen: !ImageViewerState.splitScreen });
            }}
            icon={ImageViewerState.splitScreen ? iconSplit : iconSplitDark}
            size={60}
            primary={ImageViewerState.splitScreen}
            style={{ position: 'fixed', right: '176px', bottom: '100px' }}
            tooltip="Split Viewer"
          />
        </>
      ) : (
        <>
          <IconButton
            onClick={() => {
              updateImageViewer({ splitScreen: !ImageViewerState.splitScreen });
            }}
            icon={ImageViewerState.splitScreen ? iconSplit : iconSplitDark}
            size={60}
            primary={ImageViewerState.splitScreen}
            style={{ position: 'fixed', right: '40px', bottom: '100px' }}
            tooltip="Split Viewer"
          />
          {BuildingState.projectData.forge_model_urn && (
            <IconButton
              onClick={() => {
                updateImageViewer({
                  pane2Type: ImageViewerState.pane2Type === 'forge' ? 'image' : 'forge',
                });
              }}
              icon={ImageViewerState.pane2Type === 'forge' ? icon3DLight : icon3DDark}
              size={60}
              primary={ImageViewerState.pane2Type === 'forge'}
              style={{ position: 'fixed', right: '108px', bottom: '100px' }}
              tooltip="3D Model"
            />
          )}
        </>
      )}

      <ReactTooltip effect="solid" delayShow={300} />
    </div>
  );
};
