import styled from 'styled-components';

export const AnnotationContainer = styled.div`
  background: #fff;
  border-radius: 2px;
  z-index: 100;
  position: relative;
  width: 325px;
  max-height: 150px;
  position: fixed;
  padding: 5px;
  display: flex;
  flex-direction: column;
`;

export const AnnotationForm = styled.form`
  z-index: 100;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  label {
    font-size: 0.8em;
  }
`;

export const SaveButton = styled.input`
  font-size: 0.9em;
  border-radius: 2px;
  background: #073c7a;
  color: #fff;
  border: none;
  float: right;
  cursor: pointer;
`;

export const AnnotationMessage = styled.div`
  padding: 1em;
  cursor: pointer;
  overflow-y: auto;
  max-height: 75px;
  font-size: 0.9em;
  line-height: 1.1em;
`;

export const LastUpdateMessage = styled.div`
  font-size: 0.8em;
  color: #666;
  text-align: right;
  font-family: 'Roboto';
`;
