import { useCallback, useMemo } from 'react';
import Select from 'react-select';

const customStyles = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderColor: state.isFocused ? '#073c7a' : '#F4F5F9',
    boxShadow: 'none',
    minHeight: '50px',
    minWidth: 100,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: state.isSelected ? '#fff' : '#073c7a',
  }),
  singleValue: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  input: (provided: any, state: any) => ({
    ...provided,
    fontSize: '14px',
    color: '#073c7a',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: '6px',
  }),
};

interface SeverityFilterProps {
  onChange?: (severity: string) => void;
}

export const SeverityFilter = ({ onChange }: SeverityFilterProps) => {
  const handleChange = useCallback(
    newValue => {
      onChange && onChange(newValue.value);
    },
    [onChange]
  );

  const options = useMemo(
    () => [
      { value: '', label: 'Any Severity' },
      { value: 'SEVERITY_SAFE', label: 'Safe' },
      { value: 'SEVERITY_LOW', label: 'Low' },
      { value: 'SEVERITY_MEDIUM', label: 'Medium' },
      { value: 'SEVERITY_HIGH', label: 'High' },
      { value: 'SEVERITY_LIFE_THREAT', label: 'Life Threat' },
    ],
    []
  );

  return (
    <Select
      onChange={handleChange}
      options={options}
      styles={customStyles}
      defaultValue={{ value: 'SEVERITY_ANY', label: 'Any Severity' }}
      theme={theme => ({
        ...theme,
        borderRadius: 0,
        colors: {
          ...theme.colors,
          primary: '#073c7a',
        },
      })}
    />
  );
};
