import { CSSProperties, useEffect, useState } from 'react';
import styled from 'styled-components';
import { addPointToGroup, deletePoint, updatePoint, updatePointActiveness } from '../../../../api/adminBuildingFetches';
import { useBuildingContext } from '../../../../contexts/buildingContext';
import { MapPointMainCircle } from '../../MapViewer';
import { IGroup, IMapping } from './PointGroupSelector/PointGroupSelector';
import classNames from 'classnames';
import iconTrash from '../../../../assets/images/icon_trash.svg'
import { IMapPoint } from '../../../views/admin_building_page/components/ManagePoints/ManagedMapPoint';
import { useNotifications } from '../../../../contexts/notificationProvider';
import { ContentContainer } from '../ViewSelector';
import { SelectItem } from './SelectItem';
import { useManagePointsContext } from '../../../views/admin_building_page/components/ManagePoints/ManagePointsContext';
import { ProjectFloorSection } from '../../../../api/types';

export const PointControls = () => {
  const [addToGroupExpanded, setAddToGroupExpanded] = useState<boolean>(false);
  const [addToSectionExpanded, setAddToSectionExpanded] = useState<boolean>(false);

  const {
    updateFloor,
    state: buildingState
  } = useBuildingContext();

  const {
    canEditPoints,
    canDeletePoints,
    canCreatePointMappings,
  } = useManagePointsContext();

  const { addNotification } = useNotifications();

  const clearSelectedPoints = () => updateFloor({selectedPoints: new Set()});
  const noPointsSelected: boolean = !buildingState.floorData.selectedPoints.size;

  const selectedPointsHaveImages = buildingState.floorData.images.some((image: any) => {
    return buildingState.floorData.selectedPoints.has(image.sub_point_id);
  });

  const mapSelectedPoints = (callback: (pointId: number) => Promise<any>) => {
    const selectedPointsArr: number[] = Array.from(buildingState.floorData.selectedPoints);

    const promiseArr = selectedPointsArr.map((pointId) => {
      return callback(pointId);
    });

    Promise.all(promiseArr).then((returnedPoints: IMapPoint[]) => {
      const returnedPointsSet = new Set(returnedPoints.map(point => point.point_id));
      let points = [...buildingState.floorData.points];

      points = points.filter(point => !returnedPointsSet.has(point.point_id));

      points = [...points, ...returnedPoints];

      updateFloor({
        points
      });
    });
  }

  const activateSelected = () => {
    mapSelectedPoints(
      (pointId: number) => updatePointActiveness(buildingState.projectId, buildingState.floorId, pointId.toString(), true)
    )
  }

  const deactivateSelected = () => {
    mapSelectedPoints(
      (pointId: number) => updatePointActiveness(buildingState.projectId, buildingState.floorId, pointId.toString(), false)
    )
  }

  const addSelectedToGroup = (groupId: number) => {
    const selectedPointsArr: number[] = Array.from(buildingState.floorData.selectedPoints);

    const promiseArr = selectedPointsArr.map((pointId) => {
      return addPointToGroup(buildingState.projectId, buildingState.floorId, pointId.toString(), groupId.toString());
    });

    Promise.allSettled(promiseArr).then(results => {
      const pointMappings = new Map(buildingState.floorData.pointMappings);

      results.forEach(result => {
        if (result.status === 'fulfilled') {
          const mapping: IMapping = result.value;

          const currentPointId: number = mapping.point_id;
          const pointGroups: any = pointMappings.has(currentPointId) ? pointMappings.get(currentPointId) : new Set();
          pointGroups.add(mapping.group_id);
  
          pointMappings.set(currentPointId, pointGroups);
        } else {
          const responseStatusCode = result.reason?.response?.status;

          if (!!responseStatusCode && responseStatusCode !== 409) {
            addNotification('Error adding point to group', 'error');
          }
        }
      });

      updateFloor({
        pointMappings
      });
      
      setAddToGroupExpanded(false);
      addNotification('Points added to group successfully', 'success');
    })
    .catch((err) => {
      console.log('addPointToGroup==>>', err);
      addNotification('Error adding points to group', 'error');
    });
  }

  const addSelectedToSection = async (sectionId: number) => {
    const selectedPointsArr: number[] = Array.from(buildingState.floorData.selectedPoints);

    const promiseArr = selectedPointsArr.map((pointId) => {
      return updatePoint(buildingState.projectId, buildingState.floorId, pointId.toString(), {project_floor_section: sectionId});
    });

    try {
      await Promise.all(promiseArr);

      addNotification('Points added to section successfully', 'success');
      updateFloor({
        selectedPoints: new Set(),
      });
      setAddToSectionExpanded(false);
    } catch (err) {
      console.log('addSelectedToSection==>>', err);
      addNotification('Error adding points to section', 'error');
    }
  }

  const deleteSelected = () => {
    const selectedPointsArr: number[] = Array.from(buildingState.floorData.selectedPoints);
    const deletedPointIds: Set<number> = new Set();

    const promiseArr = selectedPointsArr.map(async (pointId) => {
      try {
        const deletedPoint: IMapPoint = await deletePoint(buildingState.projectId, buildingState.floorId, pointId.toString());

        deletedPointIds.add(pointId);
        
        return deletedPoint
      } catch (err) {
        console.log('deletePoint==>>', pointId);
      }
    });

    Promise.allSettled(promiseArr).then(() => {
      if (deletedPointIds.size > 0) {
        updateFloor({
          points: buildingState.floorData.points.filter((point: any) => !deletedPointIds.has(point.point_id))
        });

        const pointsWording: string = deletedPointIds.size !== 1 ? 'Points' : 'Point';
        addNotification(`${deletedPointIds.size} ${pointsWording} Deleted Successfully.`, 'success');
      }

      if (deletedPointIds.size < selectedPointsArr.length) {
        const numPointsErrored: number = selectedPointsArr.length - deletedPointIds.size;
        const pointsWording: string = numPointsErrored !== 1 ? 'Points' : 'Point';

        addNotification(`Error Deleting ${numPointsErrored} ${pointsWording}.`, 'error');
      }

      clearSelectedPoints();
    });
  }

  useEffect(() => {
    if (noPointsSelected) {
      setAddToGroupExpanded(false);
    }
  }, [noPointsSelected]);

  return (
    <PointControlContainer>
      { canEditPoints &&
        <>
          <PointControlButton
            onClick={() => {
              if (!noPointsSelected) {
                activateSelected();
              }
            }}
            disabled={noPointsSelected}
          >
            <MapPointMainCircle
              style={mapCircleIconStyle}
              className={classNames({
                back_pink: true
              })}
            />
            <PointControlSpan>Activate Selected</PointControlSpan>
          </PointControlButton>

          <PointControlButton
            onClick={() => {
              if (!noPointsSelected) {
                deactivateSelected();
              }
            }}
            disabled={noPointsSelected}
          >
            <MapPointMainCircle
              style={mapCircleIconStyle}
              className={classNames({
                back_gray: true
              })}
            />
            <PointControlSpan>Deactivate Selected</PointControlSpan>
          </PointControlButton>
        </>
      }

      { canCreatePointMappings &&
        <PointControlButton
          onClick={() => {
            if (!noPointsSelected) {
              setAddToGroupExpanded(prevState => !prevState)
            }
          }}
          disabled={noPointsSelected}
        >
          <MapPointMainCircle
            style={mapCircleIconStyle}
            className={classNames({
              back_blue: true
            })}
          />
          <PointControlSpan>Add to Group</PointControlSpan>

          <ContentContainer maxHeight={140} aria-expanded={addToGroupExpanded}>
            <GroupSelectorWrapper>
              { buildingState.floorData.groups.map((group: IGroup) => (
                  <SelectItem
                    content={group.name}
                    key={group.group_id}
                    style={{color: '#212121', padding: '0px 8px 12px 8px'}}
                    onClick={() => addSelectedToGroup(group.group_id)}
                  />
              ))
              }
            </GroupSelectorWrapper>
          </ContentContainer>
        </PointControlButton>
      }

      {canCreatePointMappings &&
        <PointControlButton
          onClick={() => {
            if (!noPointsSelected) {
              setAddToSectionExpanded(prevState => !prevState)
            }
          }}
          disabled={noPointsSelected}
        >
          <span
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              margin: '0 18px 0 14px'
            }}
          >
            &#9633;
          </span>
          <PointControlSpan>Add to Section</PointControlSpan>

          <ContentContainer maxHeight={140} aria-expanded={addToSectionExpanded}>
            <GroupSelectorWrapper>
              { buildingState.floorData.sections.map((section: ProjectFloorSection) => (
                <SelectItem
                  content={section.name}
                  key={section.id}
                  style={{color: '#212121', padding: '0px 8px 12px 8px'}}
                  onClick={() => addSelectedToSection(section.id)}
                />
              ))}
            </GroupSelectorWrapper>
          </ContentContainer>
        </PointControlButton>
      }
      
      { canDeletePoints &&
        <PointControlButton
          disabled={noPointsSelected || selectedPointsHaveImages}
          onClick={() => {
            if (!noPointsSelected && !selectedPointsHaveImages) {
              deleteSelected();
            }
          }}
        >
          <TrashIconButton 
            src={iconTrash}
            disabled={noPointsSelected || selectedPointsHaveImages}
          />
          <PointControlSpan>Delete Selected</PointControlSpan>
        </PointControlButton>
      }
    </PointControlContainer>
  )
}

export const PointControlContainer = styled.div`
  padding: 16px 27px 16px 16px;
`

export const PointControlButton = styled.div<{disabled?: boolean}>`
  border: 1px solid ${props => props.disabled ? '#7e858e' : '#073C7A'};
  border-radius: 2px;
  margin: 10px 0px;
  color: ${props => props.disabled ? '#7e858e' : '#073C7A'};
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
`;

export const PointControlSpan = styled.span`
  display: inline-block;
  font-size: 14px;
  margin: 7.5px 0;
`;

export const TrashIconButton = styled.img<{disabled?: boolean}>`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  vertical-align: middle;
  margin: 0 14px 0 9px;
`;

const GroupSelectorWrapper = styled.div`
 & > div {
  &:last-child {
    margin-bottom: 0px;
  }

  &:not(&:last-child) {
    border-bottom: 1px solid #073C7A;
  }  
 }
`

export const mapCircleIconStyle: CSSProperties = {
  display: 'inline-block', 
  position: 'static', 
  verticalAlign: 'middle',
  width: '8px', 
  height: '8px', 
  transform: 'none', 
  margin: '0 18px 0 12px'
}