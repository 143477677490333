import axios from 'axios';
import { captureException } from '../analytics';
import { Company } from './types';

const axiosAuthInstance = axios.create();
delete axiosAuthInstance.defaults.headers.common['Authorization'];

const API_BASE = process.env.REACT_APP_NEXTERA_API;

export interface TokenResponseData {
  user: {
    email: string;
    first_name: string;
    last_name: string;
    public_id: string;
    employer: Company;
    account_owner: Company;
    is_demo_account: boolean;
  }
  token: string;
}

export const formatTokenResponseData = (tokenResponseData: TokenResponseData) => {
  return {
    email: tokenResponseData.user.email,
    token: tokenResponseData.token,
    first_name: tokenResponseData.user.first_name,
    last_name: tokenResponseData.user.last_name,
    public_id: tokenResponseData.user.public_id,
    employer: tokenResponseData.user.employer,
    account_owner: tokenResponseData.user.account_owner,
    is_demo_account: tokenResponseData.user.is_demo_account,
  };

}

export const login = (email: string, password: string) => {
  return axiosAuthInstance
    .post(`${API_BASE}/token/generate`, {
      email: email,
      password: password,
    })
    .then(r => r.data)
    .then(resp => formatTokenResponseData(resp.data))
    .catch(e => {
      captureException(e);
      if (e === 500) throw Error('Error connecting to server. Try Again.');
      else throw Error('Email or password is incorrect');
    });
};

export const msTokenGenerate = async (msToken: string) => {
  const axiosLoginInstance = axios.create();
  delete axiosLoginInstance.defaults.headers.common['Authorization'];

  const response = await axiosLoginInstance.post(`${API_BASE}/token/ms-generate`, {
    token: msToken,
  });
  const res = await response.data;
  return res.data;
}

export const validate = (token: string) => {
  return axiosAuthInstance
    .post(`${API_BASE}/token/validate`, {
      token: token,
    })
    .then(r => r.data)
    .then(resp => {
      return resp.data.token_status === 'valid';
    })
    .catch(e => {
      captureException(e);
      throw Error('Error validating token');
    });
};

export const introspect = (token: string) => {
  return axiosAuthInstance
    .post(`${API_BASE}/token/introspect`, {
      token: token,
    })
    .then(r => r.data)
    .then(resp => {
      let data = resp.data.payload;
      return {
        email: data.user.email,
        token: token,
        first_name: data.user.first_name,
        last_name: data.user.last_name,
        public_id: data.user.public_id,
        employer: data.user.employer,
        account_owner: data.user.account_owner,
        is_demo_account: data.user.is_demo_account,
      };
    })
    .catch(e => {
      captureException(e);
      if (e.response.status === 500) throw Error('Error connecting to server. Try Again.');
      else throw Error('token invalid');
    });
};

export const permissions = (user_id: string) => {
  return axios
    .get(`${API_BASE}/user/${user_id}/permissions`)
    .then(r => r.data)
    .catch(e => {
      captureException(e);
    });
};

export const logout = () => {};

export const changePassword = (email: string, oldPassword: string, newPassword: string) => {
  return axios.post(`${API_BASE}/token/change-password`, {
    email: email,
    old_password: oldPassword,
    new_password: newPassword
  })
  .then(r => r.data)
  .then(resp => {
    const data = resp.data;

    return {
      email: data.user.email,
      token: data.token,
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      public_id: data.user.public_id,
      employer: data.user.employer,
      account_owner: data.user.account_owner,
      is_demo_account: data.user.is_demo_account,
    };
  })
}

export const TEMP_LOG_FUNCTION = async (message: any) => {
  // try {
  //   await axiosAuthInstance
  //   .post(`${API_BASE}/pt/test/log`, {
  //     message: message,
  //   })
  // } catch (e) {
  //   //ignore
  // }
};