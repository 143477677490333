import { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import 'pannellum/src/js/libpannellum';
import 'pannellum/src/js/pannellum';
import 'pannellum/src/css/pannellum.css';

import { ViewerPosition } from '../views/image_viewer/types';
import { usePannellumViewer } from '../../hooks/usePannellum';
import { applyViewerPositionOffset, normalizeViewerPosition } from '../../utils';
import { useKnownPosition } from '../../hooks/useKnownPosition';

export const pannellum = (window as any).pannellum;

interface PannellumProps {
  image: string;
  viewerId: string;
  viewerPosition: ViewerPosition;
  viewerPositionDelta?: ViewerPosition;
  isSolo?: boolean;
  angleOffset: number;
  followRealtime?: boolean;
  syncedTo?: string;
  onDragging?: (isActive: boolean) => void;
  onUpdate?: (newValues: ViewerPosition) => void;
  onError?: (err: any) => void;
}

export const Pannellum = memo(
  ({
    image,
    viewerId,
    isSolo,
    viewerPosition,
    angleOffset,
    followRealtime = false,
    syncedTo,
    onUpdate,
    onError,
  }: PannellumProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const { viewer, createViewer } = usePannellumViewer(viewerId);
    const isMouseDown = useRef<boolean>(false);

    const injectOffset = useCallback(
      (clean: ViewerPosition) => {
        return applyViewerPositionOffset(clean, -angleOffset);
      },
      [angleOffset]
    );

    const removeOffset = useCallback(
      (tampered: ViewerPosition) => {
        return applyViewerPositionOffset(tampered, angleOffset);
      },
      [angleOffset]
    );

    const handleLookAt = useCallback(
      (positionToLookAt: ViewerPosition) => {
        if (viewer && !isMouseDown.current) {
          const withOffset = injectOffset(positionToLookAt);
          const normalized = normalizeViewerPosition(withOffset);
          viewer.lookAt(normalized.pitch, normalized.yaw, normalized.hfov, false);
        }
      },
      [viewer, injectOffset]
    );

    const handleUpdate = useCallback(
      (positionToPropagate: ViewerPosition) => {
        if (!followRealtime) {
          onUpdate && onUpdate(positionToPropagate);
        }
      },
      [followRealtime, onUpdate]
    );

    const { knownPosition, updateKnownPosition } = useKnownPosition(handleLookAt, handleUpdate);

    // Creates the viewer as soon as possible
    useEffect(() => {
      if (ref.current) {
        createViewer(ref.current);
      }
    }, [createViewer]);

    // Adjusts how native Pannellum listens for mouse events
    useEffect(() => {
      if (viewer) {
        viewer.syncTo(syncedTo);
      }
    }, [syncedTo, viewer]);

    // Updates internal position when property value from parent changes
    useEffect(() => {
      if (!isMouseDown.current && !followRealtime) {
        updateKnownPosition(viewerPosition, false);
      }
    }, [followRealtime, updateKnownPosition, viewerPosition]);

    // Keeps track of native Pannellum animate finished events
    useEffect(() => {
      if (viewer) {
        const handleAnimateFinished = (data: ViewerPosition) => {
          if (!followRealtime) {
            const withoutOffset = removeOffset(data);
            updateKnownPosition(withoutOffset, true, false);
          }
        };
        viewer.on('animatefinished', handleAnimateFinished);
        return () => {
          viewer.off('animatefinished', handleAnimateFinished);
        };
      }
    }, [viewer, updateKnownPosition, removeOffset, followRealtime]);

    // Notify the parent component about errors if any
    useEffect(() => {
      viewer?.on('error', (err: any) => {
        onError && onError(err);
      });
    }, [viewer, onError]);

    // Adjusts the size of native Pannellum viewer when splitting screen
    useEffect(() => {
      viewer?.resize();
    }, [viewer, isSolo]);

    // Loads new image adding to a scene when a new one is set
    useEffect(() => {
      if (viewer && knownPosition.current) {
        const withOffset = injectOffset(knownPosition.current);
        viewer.addScene('home', {
          panorama: image,
          type: 'equirectangular',
          pitch: withOffset.pitch,
          yaw: withOffset.yaw,
          hfov: withOffset.hfov,
        });
        viewer.loadScene('home');
      }
    }, [viewer, image, injectOffset, knownPosition]);

    const divStyle = useMemo(
      () => ({
        width: '100%',
        height: '100%',
      }),
      []
    );

    return <div style={divStyle} ref={ref} />;
  }
);

Pannellum.displayName = 'Pannellum';
