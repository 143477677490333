import styled from "styled-components";
import { useSafetyContext } from "../../../../../contexts/safetyContext";
import { useEffect, useMemo } from "react";
import { useCompositeSafetyIndexContext } from "../../../../../contexts/compositeSafetyIndexContext";

const formatIndex = (index: number) => {
  const percentage = (index * 100).toFixed(1);

  return `${percentage}%`;
};

export const CompositeSafetyIndexPrintout = () => {
  const {state: safetyState} = useSafetyContext();
  const {state: compositeSafetyIndexState} = useCompositeSafetyIndexContext();
  const {metricSummaryLoadingStatus} = safetyState;
  const {graphData} = compositeSafetyIndexState;

  const summary = useMemo(() => {
    if (graphData.length > 0) {
      const lastItem = graphData[graphData.length - 1];
      const secondToLastItem = graphData[Math.max(graphData.length - 2, 0)];
      const change = (lastItem.value - secondToLastItem.value);
      const roundedChange = Math.round(change * 100);
      const slopeFormatted = formatIndex(change);

      return `${roundedChange > 0 ? '+' : roundedChange === 0 ? '±' : ''}${slopeFormatted}`;
    }
  }, [graphData]);

  const index = useMemo(() => {
    const metricsLoaded = Object.values(metricSummaryLoadingStatus).every(status => !status);

    if (metricsLoaded && graphData && graphData.length > 0) {
      return formatIndex(graphData[graphData.length - 1].value);
    }

    return 0;
  }, [graphData, metricSummaryLoadingStatus]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <CompositeSafetyIndexPrintoutContainer>
        <IndexPrintout>
          {index}
        </IndexPrintout>
        <Title>
          Composite Safety Index
        </Title>
        <Summary>
          {summary}
        </Summary>
      </CompositeSafetyIndexPrintoutContainer>
      <DateSubdivisionDropdown/>
    </div>
  );
}

const DateSubdivisionDropdown = () => {
  const {state: safetyState, updateSafety} = useSafetyContext();
  const {state: compositeSafetyIndexState} = useCompositeSafetyIndexContext();

  const {metricSummaryLoadingStatus} = safetyState;
  // const {monthlyGraphData} = compositeSafetyIndexState;

  // useEffect(() => {
  //   const metricsLoaded = Object.values(metricSummaryLoadingStatus).every(status => !status);

  //   if (metricsLoaded && monthlyGraphData.length === 1) {
  //     updateSafety({
  //       dateSubdivision: 'weekly',
  //     });
  //   }
  // }, [metricSummaryLoadingStatus, monthlyGraphData.length, updateSafety]);

  const onChangeDateSubdivision = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSafety({
      dateSubdivision: e.target.value,
    });
  }

  return (
    <DateSubdivisionSelect
      onChange={onChangeDateSubdivision}
      value={safetyState.dateSubdivision}
    >
      <option value="weekly">Weekly</option>
      {/* {monthlyGraphData.length > 1 && */}
        <option value="monthly">Monthly</option>
      {/* }       */}
    </DateSubdivisionSelect>
  );
}

const DateSubdivisionSelect = styled.select`
  border: none;
  outline: none;
  height: 40px;
`;

const IndexPrintout = styled.div`
  font-family: sans-serif;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
`;

const Title = styled.div`
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: left;
`;

const Summary = styled.div`
  color: #999;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
`;

const CompositeSafetyIndexPrintoutContainer = styled.div`
  display: flex;
  flex-direction: column;
`;