import { useQuery } from "react-query";
import { getProcoreCompanyProjects, getUserProcoreCompanies, useProcoreCalls } from "../../../../api/procore";

export const useListRFIsQuery = () => {
  const {listRFIs} = useProcoreCalls();

  return useQuery(
    ['list-rfis'],
    async () => {
      return listRFIs();
    }
  );
}

export const useFetchRFIQuery = (rfiId: number) => {
  const {fetchRFI} = useProcoreCalls();

  return useQuery(
    ['fetch-rfi', rfiId],
    async () => {
      return fetchRFI(rfiId);
    },
    { enabled: !!rfiId }
  )
}

export const useGetPotentialRFIManagersQuery = () => {
  const {getPotentialRFIManagers} = useProcoreCalls();

  return useQuery(
    ['potential-rfi-managers'],
    async () => {
      return await getPotentialRFIManagers();
    }
  );
}

export const useGetPotentialRFIAssigneesQuery = () => {
  const {getPotentialRFIAssignees} = useProcoreCalls();

  return useQuery(
    ['potential-rfi-assignees'],
    async () => {
      return await getPotentialRFIAssignees();
    }
  );
}

export const useGetPotentialRFIDistributionMembersQuery = () => {
  const {getPotentialRFIDistributionMembers} = useProcoreCalls();

  return useQuery(
    ['potential-rfi-distribution-members'],
    async () => {
      return await getPotentialRFIDistributionMembers();
    }
  );
}

export const useGetPotentialRFIReceivedFromsQuery = () => {
  const {getPotentialRFIReceivedFroms} = useProcoreCalls();

  return useQuery(
    ['potential-rfi-received-froms'],
    async () => {
      return await getPotentialRFIReceivedFroms();
    }
  );
}

export const useGetProcoreSpecSectionsQuery = () => {
  const {getProcoreSpecSections} = useProcoreCalls();

  return useQuery(
    ['rfi-spec-sections'],
    async () => {
      return await getProcoreSpecSections();
    }
  );
}

export const useGetProcoreProjectLocationsQuery = () => {
  const {getProcoreProjectLocations} = useProcoreCalls();

  return useQuery(
    ['rfi-project-locations'],
    async () => {
      return await getProcoreProjectLocations();
    }
  );
}

export const useGetProcoreProjectVendorsQuery = () => {
  const {getProcoreProjectVendors} = useProcoreCalls();

  return useQuery(
    ['rfi-project-vendors'],
    async () => {
      return await getProcoreProjectVendors();
    }
  );
}

export const useGetImageCategoriesQuery = () => {
  const {getImageCategories} = useProcoreCalls();

  return useQuery(
    ['rfi-image-categories'],
    async () => {
      return getImageCategories();
    }
  );
}

export const useGetUserProcoreCompaniesQuery = () => {
  return useQuery(
    ['user-procore-companies'],
    async () => {
      return await getUserProcoreCompanies();
    }
  );
}

export const useGetProcoreCompanyProjects = (companyId: string | number | undefined | null) => {
  return useQuery(
    ['user-procore-company-projects', companyId],
    async () => {
      if (companyId) {
        return await getProcoreCompanyProjects(companyId);
      } else {
        return [];
      }
    },
    { enabled: !!companyId }
  );
}
