import styled from 'styled-components';

export const ConfirmationMessage = styled.p`
  color: #073c7a;
  font-size: 1.1em;
  margin-bottom: 10px;
  line-height: 1.1em;
`;

export const ConfirmationContent = styled.div`
  margin-bottom: 10px;
`

export const ConfirmationButton = styled.div`
  cursor: pointer;
  padding: 7px 10px;
  color: #fff;
  background: #073c7a;
  border-radius: 2px;
  margin: 0 5px;
`;

export const CancelButton = styled.div`
  cursor: pointer;
  padding: 7px 10px;
  color: #073c7a;
  background: #fff;
  border-radius: 2px;
  border: 1px solid #073c7a;
  margin: 0 5px;
`;
