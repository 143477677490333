import React, { createContext, useCallback, useContext, useReducer } from 'react';

const initialState = {
  splitScreen: false,
  sync: true,
  focused: 'master',
  pane2Type: 'image', // image or 'forge'
  master: {},
  subordinate: {},
};

const ImageViewerContext = createContext<any>(null);

const UPDATE_IMAGE_VIEWER = 'UPDATE_IMAGE_VIEWER';
const UPDATE_MASTER = 'UPDATE_MASTER';
const UPDATE_PANE2 = 'UPDATE_PANE2';
const RESET_IMAGE_VIEWER = 'RESET_IMAGE_VIEWER';

const imageViewerReducer = (state: any, action: any) => {
  switch (action.type) {
    case UPDATE_IMAGE_VIEWER:
      return {
        ...state,
        ...action.payload.item,
      };
    case UPDATE_MASTER:
      return {
        ...state,
        master: {
          ...state.master,
          ...action.payload.item,
        },
      };
    case UPDATE_PANE2:
      return {
        ...state,
        subordinate: {
          ...state.subordinate,
          ...action.payload.item,
        },
      };
    case RESET_IMAGE_VIEWER:
      return {
        ...initialState
      }
    default:
      return state;
  }
};

export const ImageViewerProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(imageViewerReducer, initialState);
  return (
    <ImageViewerContext.Provider value={[state, dispatch]}>{children}</ImageViewerContext.Provider>
  );
};

export const useImageViewerContext = () => {
  const [state, dispatch] = useContext(ImageViewerContext);

  const updateImageViewer = useCallback((item: any) => {
    dispatch({
      type: UPDATE_IMAGE_VIEWER,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updateMaster = useCallback((item: any) => {
    dispatch({
      type: UPDATE_MASTER,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const updatePane2 = useCallback((item: any) => {
    dispatch({
      type: UPDATE_PANE2,
      payload: {
        item,
      },
    });
  }, [dispatch]);

  const resetImageViewer = useCallback(() => {
    dispatch({
      type: RESET_IMAGE_VIEWER,
    });
  }, [dispatch]);

  return {
    updateImageViewer,
    updateMaster,
    updatePane2,
    resetImageViewer,
    state,
  };
};
